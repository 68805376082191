import React from 'react'
import { Location } from 'history'
import { useKeycloak } from '@react-keycloak/web'
import { useLocation, Redirect } from 'react-router-dom'

interface LoginProps {
  from: Location
}

const Login: React.FC<LoginProps> = () => {
  const { keycloak } = useKeycloak()
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const pathname = params.get('redirect') || '/'
  const redirectUri = `${window.location.origin}${pathname}`
  const { from } = location.state || { from: { pathname } }

  if (!keycloak?.authenticated) {
    keycloak?.login({ redirectUri })
  } else {
    return <Redirect to={from} />
  }

  return <div>Carregando</div>
}

export default Login
