import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  CalendarToday,
  LocalActivity,
  Loyalty,
  Block,
} from '@material-ui/icons'
import {
  CardGiftcardIcon,
  CurrencyExchangeIcon,
  InputIcon,
  OutputIcon,
  ShieldIcon,
} from '../../icons'
import {
  PointTransactionOperationEnum,
  TransactionTypeEnum,
} from '../../generated/graphql'
import { VOUCHER_STYLE } from '../../constants'

interface IProps {
  type: TransactionTypeEnum | PointTransactionOperationEnum
}

const useStyles = makeStyles(() => ({
  iconBox: {
    backgroundColor: '#cfd8dc',
    width: '32px',
    height: '32px',
    borderRadius: '50%',
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    '&.Deposit': {
      backgroundColor: '#b3e5fc',
    },
    '&.Withdraw': {
      backgroundColor: '#ffcdd2',
    },
    '&.Gift': {
      backgroundColor: '#f8bbd0',
    },
    '&.Cashback': {
      backgroundColor: '#c8e6c9',
    },
    '&.VoucherGerCode': {
      backgroundColor: '#b0dffb',
    },
    '&.VoucherExpire': {
      backgroundColor: 'orange',
    },
  },
  icon: {
    height: '24px',
    width: '24px',
    color: '#000',

    '&.Deposit': {
      color: '#0277bd',
    },
    '&.Withdraw': {
      color: '#c62828',
    },
    '&.Gift': {
      color: '#e91e63',
    },
    '&.Cashback': {
      color: '#2e7d32',
    },
    '&.VoucherGerCode': {
      color: VOUCHER_STYLE.color,
    },
    '&.VoucherExpire': {
      color: '#397daf',
    },
  },
}))

const TransactionTypeIcon: React.FC<IProps> = ({ type }) => {
  const classes = useStyles()

  switch (type) {
    case TransactionTypeEnum.Deposit:
      return (
        <div className={`${classes.iconBox} ${TransactionTypeEnum.Deposit}`}>
          <InputIcon
            className={`${classes.icon} ${TransactionTypeEnum.Deposit}`}
          />
        </div>
      )
    case TransactionTypeEnum.Withdraw:
      return (
        <div className={`${classes.iconBox} ${TransactionTypeEnum.Withdraw}`}>
          <OutputIcon
            className={`${classes.icon} ${TransactionTypeEnum.Withdraw}`}
          />
        </div>
      )

    case TransactionTypeEnum.Gift:
      return (
        <div className={`${classes.iconBox} ${TransactionTypeEnum.Gift}`}>
          <CardGiftcardIcon
            className={`${classes.icon} ${TransactionTypeEnum.Gift}`}
          />
        </div>
      )
    case TransactionTypeEnum.Cashback:
      return (
        <div className={`${classes.iconBox} ${TransactionTypeEnum.Cashback}`}>
          <CurrencyExchangeIcon
            className={`${classes.icon} ${TransactionTypeEnum.Cashback}`}
          />
        </div>
      )
    case TransactionTypeEnum.VoucherGerCode:
      return (
        <div
          className={`${classes.iconBox} ${TransactionTypeEnum.VoucherGerCode}`}
        >
          <Loyalty
            className={`${classes.icon} ${TransactionTypeEnum.VoucherGerCode}`}
          />
        </div>
      )
    case TransactionTypeEnum.VoucherExpire:
      return (
        <div
          className={`${classes.iconBox} ${TransactionTypeEnum.VoucherExpire}`}
        >
          <Loyalty
            className={`${classes.icon} ${TransactionTypeEnum.VoucherExpire}`}
          />
        </div>
      )
    case TransactionTypeEnum.CouponGerCode:
      return (
        <div
          className={`${classes.iconBox} ${TransactionTypeEnum.VoucherGerCode}`}
        >
          <Loyalty
            className={`${classes.icon} ${TransactionTypeEnum.VoucherGerCode}`}
          />
        </div>
      )
    case TransactionTypeEnum.CouponCancel:
      return (
        <div
          className={`${classes.iconBox} ${TransactionTypeEnum.VoucherExpire}`}
        >
          <Block
            className={`${classes.icon} ${TransactionTypeEnum.VoucherExpire}`}
          />
        </div>
      )
    case TransactionTypeEnum.CouponExpire:
      return (
        <div
          className={`${classes.iconBox} ${TransactionTypeEnum.VoucherExpire}`}
        >
          <CalendarToday
            className={`${classes.icon} ${TransactionTypeEnum.VoucherExpire}`}
          />
        </div>
      )
    case TransactionTypeEnum.CouponConfirm:
      return (
        <div
          className={`${classes.iconBox} ${TransactionTypeEnum.VoucherGerCode}`}
        >
          <LocalActivity
            className={`${classes.icon} ${TransactionTypeEnum.VoucherGerCode}`}
          />
        </div>
      )
    default:
      return (
        <div className={`${classes.iconBox} any`}>
          <ShieldIcon className={classes.icon} />
          {type}
        </div>
      )
  }
}

export default TransactionTypeIcon
