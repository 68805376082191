import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Field, FieldArray, Form, Formik } from 'formik'
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core'
import * as yup from 'yup'
import { AppQrCodeSimulateResponse } from '../../generated/graphql'
import { ScanCodeQuestionType } from './types'

const useStyles = makeStyles(() => ({
  questionsBox: {
    paddingTop: 20,
  },
  optionsGroup: {
    display: 'flex',
    flexDirection: 'column',
  },
}))

type Props = {
  scanResponse: AppQrCodeSimulateResponse | null
  code: string
  handleClose: any
  rescueCode: any
  questions: ScanCodeQuestionType[] | null
}
const RescueWithQuestionsConfirmDialog: React.FC<Props> = ({
  scanResponse,
  code,
  handleClose,
  rescueCode,
  questions,
}) => {
  const classes = useStyles()

  const getField = (index: number) => {
    const currentQuestion = questions ? questions[index] : null
    if (!currentQuestion) {
      return ''
    }

    if (currentQuestion.type === 'checkbox') {
      return (
        <div className={classes.questionsBox}>
          <div id={currentQuestion.id}>
            <b>{currentQuestion.title}</b>
          </div>
          <div
            role="group"
            aria-labelledby="checkbox-group"
            className={classes.optionsGroup}
          >
            {currentQuestion.options.map((option, i) => (
              <label
                key={`${currentQuestion.id}-${i.toString()}`}
                htmlFor={`${currentQuestion.id}-${i.toString()}`}
              >
                <Field
                  type="checkbox"
                  id={`${currentQuestion.id}-${i.toString()}`}
                  name={currentQuestion.id}
                  value={option}
                />
                {option}
              </label>
            ))}
          </div>
        </div>
      )
    }
    return (
      <div className={classes.questionsBox}>
        <div id={currentQuestion.id}>
          <b>{currentQuestion.title}</b>
        </div>
        <div
          role="group"
          aria-labelledby="radio-group"
          className={classes.optionsGroup}
        >
          {currentQuestion.options.map((option, i) => (
            <label
              key={`${currentQuestion.id}-${i.toString()}`}
              htmlFor={`${currentQuestion.id}-${i.toString()}`}
            >
              <Field
                type="radio"
                id={`${currentQuestion.id}-${i.toString()}`}
                name={currentQuestion.id}
                value={option}
              />
              {option}
            </label>
          ))}
        </div>
      </div>
    )
  }

  if (!scanResponse) {
    return <span>Erro na resposta</span>
  }

  const getValidationSchema = () => {
    const obj = questions?.reduce((a, v: ScanCodeQuestionType) => {
      return {
        ...a,
        [v.id]:
          v.type === 'checkbox'
            ? yup.array().min(1).of(yup.string().required()).required()
            : yup.string().required('Respoda esta pergunta.'),
      }
    }, {})
    return yup.object(obj)
  }
  return (
    <>
      <Formik
        initialValues={{ questions: questions ? questions.map(q => q.id) : [] }}
        validationSchema={getValidationSchema()}
        onSubmit={values => {
          const submitData = ({ ...values } as unknown) as any
          delete submitData.questions
          console.log('submitData ', submitData)
        }}
        render={({ values }) => (
          <Form>
            <DialogTitle id="alert-dialog-slide-title">
              Resgatar código promocional
            </DialogTitle>
            <DialogContent>
              {scanResponse && (
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <p>{scanResponse.message}</p>
                  <span>
                    Local: <b>{scanResponse.local?.name}</b>
                  </span>
                  <span>
                    Promoção: <b>{scanResponse.promotion?._id}</b>
                  </span>
                  <div
                    style={{
                      padding: 12,
                      border: '1px dashed #27ae60',
                      textAlign: 'center',
                      marginTop: 16,
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <strong style={{ color: '#27ae60' }}>{code}</strong>
                    <span> Você irá receber</span>
                    <h4 style={{ margin: 0 }}>
                      {scanResponse.passCoin} PassCoins
                    </h4>
                  </div>
                  Responda as perguntas abaixo para resgatar.
                  <FieldArray
                    name="questions"
                    render={arrayHelpers => (
                      <div>
                        {values.questions &&
                          values.questions.length > 0 &&
                          values.questions.map((quest, index) => (
                            <div key={index.toString()}>{getField(index)}</div>
                          ))}
                      </div>
                    )}
                  />
                </div>
              )}
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleClose}
                color="secondary"
                variant="outlined"
              >
                Cancelar
              </Button>
              <Button
                type="submit"
                color="primary"
                variant="outlined"
                style={{ color: '#064326', borderColor: '#064326' }}
              >
                Resgatar
              </Button>
            </DialogActions>
          </Form>
        )}
      />
    </>
  )
}
export default RescueWithQuestionsConfirmDialog
