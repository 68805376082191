import React, { useEffect, useState } from 'react'
import { useKeycloak } from '@react-keycloak/web'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory, useParams } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@material-ui/core'
import {
  CheckCircleOutline,
  ErrorOutline,
  Loyalty,
  CloseRounded,
} from '@material-ui/icons'
import {
  AppQrCodeSimulateResponse,
  SimpleResponse,
} from '../../generated/graphql'
import { APP_QR_CODE_CONFIRM, APP_QR_CODE_SIMULATE } from '../../gql'
import Loading from '../../components/Loading'
import {
  ScanCodeErrorState,
  ScanCodeQuestionType,
} from '../../components/ScanCode/types'
import scanGif from './scan.gif'
import ChangeRefConfirmDialog from '../../components/RefScanCode/ChangeRefConfirmDialog'
import RequireLoginCard from '../../components/RequireLoginCard'

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: 5,
    marginBottom: 5,
    borderRadius: 0,
    borderBottomRightRadius: 10,
    borderBottomLeftRadius: 10,
    [theme.breakpoints.down('sm')]: {
      // marginLeft: 5,
      // marginRight: 5,
      margin: '0 12px',
    },
  },
  cardContentVoucher: {
    display: 'flex',
    flexDirection: 'column',
    padding: 10,
    margin: 0,
  },
  contentTitle: {
    fontWeight: 'bold',
    margin: 0,
    textAlign: 'center',
  },
  cardActionVoucher: {
    display: 'flex',
    justifyContent: 'center',
  },
  cardContentResponse: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  questionsBox: {
    paddingTop: 20,
  },
  optionsGroup: {
    display: 'flex',
    flexDirection: 'column',
  },
}))

const demoQuestions2: ScanCodeQuestionType[] = []
const demoQuestions: ScanCodeQuestionType[] = [
  {
    id: 'question1',
    title: 'Como foi o nosso atendimento?',
    type: 'radio',
    options: ['Insatisfatório', 'Médio', 'Bom', 'Excelente'],
  },
  {
    id: 'question2',
    title: 'Como estava o seu lanche?',
    type: 'radio',
    options: [
      'Insatisfatório',
      'Médio',
      'Bom',
      'Excelente',
      'Lorem ipsum dolor voluptatum?',
    ],
  },
  {
    id: 'question3',
    title: 'Você indicaria nosso estabelecimento?',
    type: 'checkbox',
    options: ['Não', 'Talvez', 'Sim', 'Com certeza'],
  },
]

const RefScanCode: React.FC = () => {
  const classes = useStyles()
  const history = useHistory()
  const { keycloak } = useKeycloak()
  const { code } = useParams<{ code: string }>()

  const [scanState, setScanState] = useState<
    'START' | 'ERROR' | 'SUCCESS' | 'LOADING'
  >('START')

  const [rescueState, setRescueState] = useState<
    'START' | 'ERROR' | 'SUCCESS' | 'LOADING' | 'EMPTY'
  >('EMPTY')

  const [
    scanResponse,
    setScanResponse,
  ] = useState<AppQrCodeSimulateResponse | null>(null)
  const [confirmResponse, setConfirmResponse] = useState<SimpleResponse | null>(
    null,
  )
  const [errorSt, setErrorSt] = useState<ScanCodeErrorState | null>(null)
  const [open, setOpen] = useState(false)
  const [questions, setQuestions] = useState<ScanCodeQuestionType[] | null>(
    null,
  )

  const [simulate] = useMutation<{
    appQrCodeSimulate: AppQrCodeSimulateResponse
  }>(APP_QR_CODE_SIMULATE, {
    variables: {
      code,
    },
  })

  const [confirmRescue] = useMutation<{
    appQrCodeConfirm: SimpleResponse
  }>(APP_QR_CODE_CONFIRM, {
    variables: {
      code,
    },
  })

  const clear = () => {
    setScanState('START')
    setRescueState('EMPTY')
    setOpen(false)
  }

  useEffect(() => {
    setScanState('LOADING')

    if (code) {
      simulate().then(
        response => {
          console.log('response', response)
          setScanState('SUCCESS')
          if (response.data?.appQrCodeSimulate) {
            setScanResponse(response.data?.appQrCodeSimulate)
            setQuestions(demoQuestions)
          }
        },
        error => {
          setScanState('ERROR')
          setErrorSt({
            title: error.message || 'Não foi possível verificar seu código.',
            message:
              'Tente outro código ou entre em contato com o responsável do estabelecimento.',
          })
        },
      )
    } else {
      clear()
    }
  }, [code, simulate])

  const rescueCode = () => {
    setRescueState('LOADING')
    confirmRescue().then(
      response => {
        setRescueState('SUCCESS')
        if (response.data?.appQrCodeConfirm) {
          setConfirmResponse(response.data.appQrCodeConfirm)
        }
      },
      error => {
        setErrorSt({
          title: error.message || 'Não foi possível resgatar este código.',
          message:
            'Tente outro código ou entre em contato com o responsável do estabelecimento.',
        })
        setRescueState('ERROR')
      },
    )
  }

  const handleClose = () => {
    setOpen(false)
    // setRescueState('EMPTY')
    // setScanState('START' )
  }

  const renderByRescueState = () => {
    console.log('testeeee', rescueState)

    switch (rescueState) {
      // case "EMPTY":
      //  return ''
      case 'LOADING':
        return (
          <>
            <DialogTitle id="alert-dialog-slide-title">
              Alterarando representante. Aguarde...
            </DialogTitle>
            <DialogContent>
              <Loading />
            </DialogContent>
          </>
        )
      case 'SUCCESS':
        return (
          <>
            <DialogTitle id="alert-dialog-slide-title">
              Representante alterado
            </DialogTitle>
            <DialogContent className={classes.cardContentResponse}>
              <CheckCircleOutline
                style={{ color: '#27ae60', fontSize: '4rem' }}
              />

              <p>{confirmResponse?.message}</p>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleClose}
                color="secondary"
                variant="outlined"
              >
                Fechar
              </Button>
            </DialogActions>
          </>
        )
      case 'ERROR':
        return (
          <>
            <DialogTitle id="alert-dialog-slide-title">ERRO</DialogTitle>
            <DialogContent className={classes.cardContentResponse}>
              <Typography variant="h6">
                Não conseguimos alterar o representante
              </Typography>

              <ErrorOutline style={{ color: '#e74c3c', fontSize: '4rem' }} />

              <p>{errorSt?.title}</p>
              <span>{errorSt?.message}</span>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleClose}
                color="secondary"
                variant="outlined"
              >
                Fechar
              </Button>
            </DialogActions>
          </>
        )
      default:
        return (
          <ChangeRefConfirmDialog
            code={code}
            rescueCode={rescueCode}
            scanResponse={scanResponse}
            handleClose={handleClose}
          />
        )
    }
  }

  const openDialog = () => {
    setOpen(true)
  }

  const renderBuScanState = () => {
    switch (scanState) {
      case 'LOADING':
        return <Loading />

      case 'ERROR':
        return (
          <>
            <Card className={classes.root}>
              <CardContent className={classes.cardContentResponse}>
                <Typography variant="h6" className={classes.contentTitle}>
                  {errorSt?.title}
                </Typography>

                <ErrorOutline style={{ color: '#e74c3c', fontSize: '4rem' }} />

                <Typography variant="subtitle2" style={{ margin: 0 }}>
                  {errorSt?.message}
                </Typography>
              </CardContent>
            </Card>
          </>
        )
      case 'SUCCESS':
        return (
          <>
            <Card className={classes.root}>
              <CardContent className={classes.cardContentVoucher}>
                <Typography variant="h6" className={classes.contentTitle}>
                  Bloco de códigos promocionais
                </Typography>

                {scanResponse && (
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <p>{scanResponse.message}</p>

                    <span>
                      Local: <b>{scanResponse.local?.name}</b>
                    </span>
                    <span>
                      Promoção: <b>{scanResponse.promotion?._id}</b>
                    </span>
                    <div
                      style={{
                        padding: 12,
                        border: '1px dashed #27ae60',
                        textAlign: 'center',
                        marginTop: 16,
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                    >
                      <strong style={{ color: '#27ae60' }}>{code}</strong>
                      <span>Cada código resgatado irá receber</span>
                      <h4 style={{ margin: 0 }}>
                        {scanResponse.passCoin} PassCoins
                      </h4>

                      <span>Este bloco contém:</span>
                      <h4 style={{ margin: 0 }}>10 códigos promocionais</h4>

                      <span>Este bloco é de:</span>
                      <h4 style={{ margin: 0 }}>Maiara Sassi</h4>
                    </div>
                  </div>
                )}
              </CardContent>

              <CardActions className={classes.cardActionVoucher}>
                <Button
                  variant="outlined"
                  endIcon={<CloseRounded />}
                  onClick={clear}
                >
                  Fechar
                </Button>
                <Button
                  variant="contained"
                  endIcon={<Loyalty />}
                  onClick={openDialog}
                  style={{
                    background: '#064326',
                    borderColor: '#064326',
                    color: '#fff',
                  }}
                >
                  Mudar representante
                </Button>
              </CardActions>
            </Card>

            <Dialog
              open={open}
              keepMounted
              onClose={handleClose}
              aria-labelledby="alert-dialog-slide-title"
              aria-describedby="alert-dialog-slide-description"
            >
              {renderByRescueState()}
            </Dialog>
          </>
        )

      default:
      case 'START':
        return (
          <>
            <Card className={classes.root}>
              <CardContent className={classes.cardContentResponse}>
                <Typography variant="h6" className={classes.contentTitle}>
                  Bloco de códigos promocionais
                </Typography>

                <img
                  src={scanGif}
                  alt="Bloco de códigos promocionais"
                  style={{ width: '100%' }}
                />

                <span>
                  Escaneie o QrCode na capa do bloco de promoções para adicionar
                  sua indicação.
                </span>
              </CardContent>
            </Card>
          </>
        )
    }
  }

  if (!keycloak.authenticated) {
    return (
      <RequireLoginCard
        message="Acesse ou crie sua conta na PASSFREE para resgatar este código
              promocional."
      />
    )
  }
  return renderBuScanState()
}
export default RefScanCode
