import React from 'react'
import { useHistory } from 'react-router-dom'

import { Button } from '@material-ui/core'
import { Home } from '@material-ui/icons'

const PageNotFound: React.FC = () => {
  const history = useHistory()

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <h2>Página não encontrada</h2>
      <p>Desculpe, não encontramos a página que está procurando.</p>

      <Button
        color="secondary"
        variant="outlined"
        style={{ maxWidth: 400 }}
        onClick={() => history.push('/')}
        startIcon={<Home />}
      >
        Voltar para o início
      </Button>
    </div>
  )
}
export default PageNotFound
