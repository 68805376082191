import React from 'react'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import { Button, Card, Typography } from '@material-ui/core'
import { HelpOutline } from '@material-ui/icons'

const useStyles = makeStyles(() => ({
  helpCard: {
    color: '#064326',
    textAlign: 'initial',
    padding: '8px 12px',
    borderRadius: 10,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#fff380',
  },
}))

const HelpBanner: React.FC = () => {
  const classes = useStyles()
  const history = useHistory()

  return (
    <Card className={classes.helpCard}>
      <div>
        <Typography variant="subtitle2" style={{ lineHeight: 1.2 }}>
          Precisa de ajuda? <br /> Fale com a nossa equipe!
        </Typography>
      </div>
      <Button
        variant="contained"
        size="small"
        endIcon={<HelpOutline />}
        onClick={() => history.push('/ajuda')}
        style={{
          backgroundColor: '#f4b400',
          borderColor: '#064326',
          color: '#000',
        }}
      >
        Ajuda
      </Button>
    </Card>
  )
}
export default HelpBanner
