import React from 'react'
import { useHistory } from 'react-router-dom'

import { useQuery } from '@apollo/client'

import {
  makeStyles,
  Card,
  Typography,
  CardHeader,
  useTheme,
  useMediaQuery,
} from '@material-ui/core'
import { Bookmark } from '@material-ui/icons'

import {
  AppPromotionResponse,
  CategorySlugEnum,
  PromotionSearchInput,
  SortOrderEnum,
} from '../../generated/graphql'
import { APP_PROMOTIONS } from '../../gql'

import { PromotionCard } from '../../components'

import CategoryList from '../../components/Category/CategoryList'

const useStyles = makeStyles(theme => ({
  root: {
    margin: 5,
    padding: 5,
  },
  formControl: {
    padding: 10,
    paddingTop: 24,
    minWidth: 250,
  },
  cardFilter: {
    marginBottom: 10,
    [theme.breakpoints.up('sm')]: {
      width: '100%',
      height: 215,
      marginRight: 10,
    },
  },
  select: {
    margin: theme.spacing(1),
    width: '100%',
    maxWidth: 250,
    marginBottom: 35,
  },
}))

const Promotions: React.FC = () => {
  const theme = useTheme()
  const classes = useStyles()
  const history = useHistory()
  const queryParams = new URLSearchParams(window.location.search)
  const category = queryParams.get('category') as CategorySlugEnum

  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'))

  const params: PromotionSearchInput = {
    perPage: 1000,
    sort: {
      updatedAt: SortOrderEnum.Desc,
    },
    and: {
      categories: category ? [category] : [],
    },
  }

  const getParams = (): PromotionSearchInput => {
    if (!category) {
      delete params.and
    }
    return params
  }

  const { data: dataPromotions } = useQuery<{
    appPromotions: AppPromotionResponse
  }>(APP_PROMOTIONS, {
    variables: {
      params: getParams(),
    },
  })

  return (
    <div className={classes.root}>
      <CategoryList selectedCategory={category} />

      {category && (
        <Card style={{ width: '100%', marginBottom: 10, marginTop: 10 }}>
          <CardHeader
            avatar={<Bookmark />}
            title={
              <Typography
                variant="body1"
                style={{
                  fontWeight: 'bold',
                  textAlign: 'center',
                  fontSize: '1rem',
                }}
              >
                Categoria: {category}
              </Typography>
            }
          />
        </Card>
      )}

      <div>
        <div
          style={
            isDesktop
              ? {
                  display: 'flex',
                  flexDirection: 'column',
                  height: '80vh',
                }
              : {}
          }
        >
          {dataPromotions &&
            dataPromotions.appPromotions.edges.map(item => (
              <div style={{ marginBottom: 10 }} key={item.slug}>
                <PromotionCard
                  promotion={item}
                  onClick={() => history.push(`/promocoes/${item.slug}`)}
                />
              </div>
            ))}
        </div>
      </div>
    </div>
  )
}

export default Promotions
