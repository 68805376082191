/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, Fragment } from 'react'
import { withRouter } from 'react-router-dom'

interface ScrollProps {
  history: any
}

const ScrollToTop: React.FC<ScrollProps> = ({ history, children }) => {
  useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0)
    })
    return () => {
      unlisten()
    }
  }, [history])

  return <>{children}</>
}

export default withRouter(ScrollToTop)
