import { gql } from '@apollo/client'

export const GET_USER_ME = gql`
  query userMe {
    userMe {
      name
      username
      email
      sub
      email_verified
    }
  }
`
export const MY_CLIENT = gql`
  query MyClient {
    myClient {
      _id
      name
      phone
      balance
    }
  }
`
export const APP_PROMOTIONS = gql`
  query AppPromotions($params: PromotionSearchInput) {
    appPromotions(params: $params) {
      edges {
        _id
        title
        slug
        resume
        thumb
        type
        config {
          passCoin
          brl
          cashbackInPassCoin
        }
        myInfo {
          promoCode {
            code
          }
          coinMissing
          status
        }
        startDate {
          format(format: "DD/MM/yy")
        }
        endDate {
          format(format: "DD/MM/yy")
        }
        local {
          name
          thumb
          slug
          address {
            city
            stateCode
          }
        }
      }
    }
  }
`

export const APP_CATEGORIES_WITH_PROMO = gql`
  query AppCategoriesWithPromo {
    appCategoriesWithPromo {
      slug
      name
      icon
    }
  }
`

export const APP_MY_PROMO_CODES = gql`
  query AppMyPromoCodes {
    appMyPromoCodes {
      openedCodes {
        ...PartialPromoCode
      }
      closedCodes {
        ...PartialPromoCode
        usedAt {
          format(format: "DD/MM/yy")
        }
      }
    }
  }

  fragment PartialPromoCode on AppPromoCode {
    code
    description
    amountLabel
    status
    promotionType
    expireIn {
      format(format: "DD/MM/yy hh:mm")
      timestamp
    }
    promotion {
      _id
      title
      slug
    }
    local {
      _id
      name
      slug
      thumb
    }
  }
`
export const APP_QR_CODE_SIMULATE = gql`
  mutation AppQrCodeSimulate($code: String!) {
    appQrCodeSimulate(code: $code) {
      message
      passCoin
      local {
        name
      }
      promotion {
        _id
      }
    }
  }
`

export const APP_LINK_REFERRER = gql`
  query AppLinkReferrer($slug: String!) {
    appLinkReferrer(slug: $slug) {
      slug
      redirectUrl
      tableId
      tableType
      linkId
    }
  }
`

export const APP_QR_CODE_CONFIRM = gql`
  mutation AppQrCodeConfirm($code: String!) {
    appQrCodeConfirm(code: $code) {
      message
    }
  }
`

export const UPDATE_MY_CLIENT = gql`
  mutation UpdateMyClient($input: ClientUpdateInput!) {
    updateMyClient(input: $input) {
      message
      data {
        name
        phone
        _id
      }
    }
  }
`

export const MY_CLIENT_REFERRER = gql`
  query MyClientReferrer {
    myClientReferrer {
      slug
      link
    }
  }
`

export const UPDATE_MY_CLIENT_REFERRER = gql`
  mutation UpdateMyClientReferrer($slug: String!) {
    updateMyClientReferrer(slug: $slug) {
      message
      data {
        slug
        link
      }
    }
  }
`

export const APP_WALLET = gql`
  query AppWallet {
    appWallet {
      balance
      transactions {
        _id
        title
        operation
        deposit
        withdraw
        type
        promotionCode

        createdAt {
          format(format: "DD/MM HH:mm")
        }
        local {
          name
          slug
          thumb
        }
      }
    }
  }
`
export const APP_MY_POINTS = gql`
  query AppMyPoints {
    appMyPoints {
      inPSC
      minPSC
      PTSxPSC
      balance
      transactions {
        _id
        type
        operation
        title
        deposit
        withdraw
        balance
        description
        ref
        createdAt {
          format(format: "DD/MM/yy")
        }
      }
    }
  }
`
