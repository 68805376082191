import { toast } from 'react-toastify'

const slugify = (text: string, separator = '-') => {
  return text
    .toString()
    .normalize('NFD') // split an accented letter in the base letter and the acent
    .replace(/[\u0300-\u036f]/g, '') // remove all previously split accents
    .toLowerCase()
    .trim()
    .replace(/[^a-z0-9 ]/g, '') // remove all chars not letters, numbers and spaces (to be replaced)
    .replace(/\s+/g, separator)
}

const shareOrCoppy = (url: string, title: string, text: string) => {
  if (!url) {
    return
  }

  if (navigator.share) {
    navigator
      .share({
        url,
        title,
        text,
      })
      .then()
      // eslint-disable-next-line no-console
      .catch(console.error)
  } else {
    navigator.clipboard.writeText(url)
    toast.success('Seu link foi copiado.', { position: 'bottom-center' })
  }
}

export { slugify, shareOrCoppy }
