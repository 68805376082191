import React from 'react'

import {
  makeStyles,
  CardContent,
  Typography,
  CardActions,
  Button,
  Card,
} from '@material-ui/core'
import BlockIcon from '@material-ui/icons/Block'

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: 5,
    marginBottom: 5,
    borderRadius: 0,
    borderBottomRightRadius: 10,
    borderBottomLeftRadius: 10,
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      marginLeft: 5,
      marginRight: 5,
    },
  },
  cardContentVoucher: {
    display: 'flex',
    flexDirection: 'column',
    padding: 10,
    margin: 0,
  },
  contentTitle: {
    fontWeight: 'bold',
    margin: 0,
  },
  cardActionVoucher: {
    display: 'flex',
    justifyContent: 'center',
  },
}))

type Props = {
  needPasscoin: any
}
const InsuficientPasscoin: React.FC<Props> = ({ needPasscoin }) => {
  const classes = useStyles()

  return (
    <Card className={classes.root}>
      <CardContent className={classes.cardContentVoucher}>
        <Typography variant="h6" className={classes.contentTitle}>
          Quase lá!
        </Typography>

        <Typography variant="subtitle2" style={{ margin: 0 }}>
          Você ainda precisa de mais{' '}
          <b style={{ color: '#064326' }}>{needPasscoin}PassCoins</b> para poder
          resgatar essa promoção.
        </Typography>
      </CardContent>

      <CardActions className={classes.cardActionVoucher}>
        <Button variant="outlined" endIcon={<BlockIcon />} disabled>
          Gerar voucher
        </Button>
      </CardActions>
    </Card>
  )
}
export default InsuficientPasscoin
