import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  makeStyles,
  Typography,
} from '@material-ui/core'
import { CalendarToday, Loop } from '@material-ui/icons'

import {
  AppPromotion,
  AppPromotionMyStatusEnum,
  PromotionTypeEnum,
} from '../../generated/graphql'
import PassCoin from '../PassCoin'
import { VOUCHER_STYLE } from '../../constants'
import IconByTypePromotion from './IconByTypePromotion'

const useStyles = makeStyles(theme => ({
  container: {},
  root: {
    marginTop: 5,
    marginBottom: 5,
    borderRadius: 0,
    borderBottomRightRadius: 10,
    borderBottomLeftRadius: 10,
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
    marginBottom: '-6px',
  },

  contentTitle: {
    fontWeight: 'bold',
    margin: 0,
  },

  voucherMissing: {
    fontWeight: 'bold',
    display: 'flex',
    gap: '5px',
    flexDirection: 'row',
    paddingLeft: 5,
  },
  voucherMy: {
    background: '#27ae60',
    width: '100%',
    padding: 6,
    color: 'white',
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
  },
  couponMy: {
    background: '#27ae60',
    width: '100%',
    padding: 6,
    color: '#000',
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
  },
  voucherCount: {
    width: 0,
    height: 0,
    background: 'transparent',
    borderTop: '23px solid transparent',
    borderBottom: '20px solid transparent',
    borderLeft: '23px solid #27ae60',
  },
  voucherContainerCount: {
    // background: '#e74c3c',
    background: '#f4b400',
    width: '50%',
    [theme.breakpoints.up('sm')]: {
      width: '65%',
    },
    padding: 8,
    color: 'white',
    fontWeight: 'bold',
    display: 'flex',
    textAlign: 'center',
  },
  cardContentVoucher: {
    display: 'flex',
    flexDirection: 'column',
    padding: 10,
    margin: 0,
  },
  cashBackStamp: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#fff',
    width: '80px',
    height: '80px',
    backgroundColor: '#3ca653',
    marginTop: '-80px',
    fontSize: '0.65rem',
    lineHeight: '0.65rem',
    justifyItems: 'center',
    borderRadius: '50%',
    fontWeight: 'bold',
  },
  cashBackStamp2: {
    position: 'relative',
    top: '-28px',
    marginBottom: '-23px',
    backgroundColor: '#3ca653',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '4px',
    width: 'min-content',
    left: '5px',
    padding: '2px 8px 2px 5px',
    borderRadius: '20px',
    fontSize: '0.75rem',
    lineHeight: '0.85rem',
    color: '#fff',
  },
  dtValid: {
    fontSize: '0.75rem',
    lineHeight: '0.85rem',
    color: '#888',
    marginTop: '8px',
    display: 'flex',
  },
  priceTagBox: {
    position: 'relative',
    top: '-47px',
    left: '-5px',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  priceTag: {
    backgroundColor: '#3ca653',
    width: 160,
    padding: 4,
  },
  priceTagBordered: {
    border: '1px dashed #fff',
    textAlign: 'center',
    fontWeight: 'bold',
    color: '#fff',
  },
}))

interface OffersCardProps {
  promotion: AppPromotion
  onClick?: () => void
  width?: string | number
  height?: string | number
}

const PromotionCard: React.FC<OffersCardProps> = ({
  onClick,
  width,
  height,
  promotion,
}) => {
  const classes = useStyles()
  const history = useHistory()
  const [parentWidth, setParentWidth] = React.useState<any>()

  useEffect(() => {
    if (document && document.getElementById('promoCard')) {
      setParentWidth(document?.getElementById('promoCard')?.offsetWidth)
    }
  }, [])

  const cashBackStamp = () => {
    if (
      promotion.type === PromotionTypeEnum.Coupon &&
      promotion.config.cashbackInPassCoin
    ) {
      return (
        <div className={classes.cashBackStamp2}>
          <Loop fontSize="small" />
          <small>CASHBACK</small>
          <strong>{promotion.config.cashbackInPassCoin}</strong>
          <small>PassCoins</small>
        </div>
      )
    }
    return <span />
  }

  const renderTargetByStatus = () => {
    switch (promotion.myInfo.status) {
      case AppPromotionMyStatusEnum.SoldOut:
        return (
          <div className={classes.voucherMy} style={{ background: '#afafaf' }}>
            <Typography>PROMOÇÃO ESGOTADA</Typography>
          </div>
        )
      case AppPromotionMyStatusEnum.Guest:
      case AppPromotionMyStatusEnum.ClientIncomplete:
      case AppPromotionMyStatusEnum.Available:
        return (
          <>
            {promotion.type === PromotionTypeEnum.Voucher && (
              <div className={classes.voucherMy}>
                <Typography>Resgate por</Typography>
                <Typography className={classes.voucherMissing}>
                  {promotion.config?.passCoin} <PassCoin />
                </Typography>
              </div>
            )}

            {promotion.type === PromotionTypeEnum.Coupon && (
              <div className={classes.couponMy}>
                <Typography>Compre por</Typography>
                <Typography className={classes.voucherMissing}>
                  R${' '}
                  {promotion.config?.brl
                    ? promotion.config?.brl.toFixed(2)
                    : '---'}
                </Typography>
              </div>
            )}
          </>
        )
      case AppPromotionMyStatusEnum.InsufficientCoin:
        return (
          <div className={classes.voucherMy} style={{ background: '#f4b400' }}>
            <Typography>Faltam</Typography>
            <Typography className={classes.voucherMissing}>
              {promotion.myInfo.coinMissing} <PassCoin />
            </Typography>
          </div>
        )
      case AppPromotionMyStatusEnum.HasTicket:
        if (promotion.type === PromotionTypeEnum.Coupon) {
          return (
            <div
              className={classes.voucherMy}
              style={{ background: VOUCHER_STYLE.color }}
            >
              <Typography>CUPOM RESGATADO</Typography>
            </div>
          )
        }
        return (
          <div
            className={classes.voucherMy}
            style={{ background: VOUCHER_STYLE.color }}
          >
            <Typography>VOUCHER RESGATADO</Typography>
          </div>
        )
      default:
        return (
          <div className={classes.couponMy}>
            <Typography>Sem dados de Resgate</Typography>
          </div>
        )
    }
  }

  return (
    <div
      className={classes.container}
      style={{
        margin: width && 0,
        padding: width && 0,
        marginBottom: '10px',
      }}
    >
      <Card
        className={classes.root}
        style={{
          width,
          margin: width && 0,
          padding: width && 0,
          height,
        }}
        id="promoCard"
      >
        {promotion.local && (
          <CardHeader
            title={promotion.local.name}
            onClick={() => history.push(`/locais/${promotion.local.slug}`)}
            subheader={`${promotion.local.address?.city} - ${promotion.local.address?.stateCode}`}
            avatar={
              promotion.local.thumb ? (
                <Avatar src={promotion.local.thumb} />
              ) : (
                <Avatar>{promotion.local.name.charAt(0)}</Avatar>
              )
            }
          />
        )}

        <IconByTypePromotion type={promotion.type} />

        {promotion.thumb && (
          <CardMedia
            className={classes.media}
            image={promotion.thumb}
            title={promotion.title}
            onClick={onClick}
            style={{ paddingTop: parentWidth > 0 ? `${parentWidth}px` : '56%' }}
          >
            <div className={classes.priceTagBox}>
              <div className={classes.priceTag}>
                <div className={classes.priceTagBordered}>
                  {promotion.type === PromotionTypeEnum.Voucher && (
                    <Typography>{promotion.config?.passCoin} PSC</Typography>
                  )}

                  {promotion.type === PromotionTypeEnum.Coupon && (
                    <Typography>
                      R${' '}
                      {promotion.config?.brl
                        ? promotion.config?.brl.toFixed(2)
                        : '---'}
                    </Typography>
                  )}
                </div>
              </div>
            </div>
          </CardMedia>
        )}
        {cashBackStamp()}

        <div style={{ display: 'flex', marginTop: '0' }}>
          {renderTargetByStatus()}
        </div>

        <CardContent onClick={onClick}>
          <Typography
            gutterBottom
            variant="h6"
            className={classes.contentTitle}
          >
            {promotion.title}
          </Typography>
          <Typography>{promotion.resume}</Typography>
          {promotion.startDate && promotion.endDate && (
            <Typography className={classes.dtValid}>
              <CalendarToday style={{ fontSize: '0.75rem' }} /> Válido até{' '}
              {promotion.endDate.format}
            </Typography>
          )}
        </CardContent>
      </Card>
    </div>
  )
}

export default PromotionCard
