/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useContext, useEffect, useState } from 'react'
import { useKeycloak } from '@react-keycloak/web'
import { useHistory } from 'react-router-dom'
import * as Yup from 'yup'

import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  IconButton,
  makeStyles,
  TextField,
  Typography,
} from '@material-ui/core'

import { Formik, FormikValues } from 'formik'

import {
  BlockRounded,
  ConfirmationNumber,
  EditOutlined,
  Save,
  Share,
} from '@material-ui/icons'
import { useLazyQuery, useMutation } from '@apollo/client'
import { AppLinkReferrer, Client } from '../generated/graphql'
import {
  MY_CLIENT,
  MY_CLIENT_REFERRER,
  UPDATE_MY_CLIENT_REFERRER,
} from '../gql'
import { ClientContext } from '../providers/ClientProvider'
import { Loading } from '../components'
import Message, { MessageTypeEnum } from '../components/Messages'
import { shareOrCoppy, slugify } from '../utils/helpers'
import IncompleteClient from '../components/Promotion/IncompleteClient'

const useStyles = makeStyles(theme => ({
  root: {
    padding: 10,
    margin: 10,
    marginBottom: 20,
  },
  invite: {
    padding: '10px 0px',
    margin: 10,
    marginBottom: 100,
  },
  cardInvite: {
    padding: 0,
    border: '1px dashed #3ca653',
    background: '#3ca6532b',
  },
  inviteHeader: {},
  cardContent: {
    padding: '5px',
  },
  avatar: {
    width: 100,
    height: 100,
    borderRadius: 80,
    fontSize: 35,
  },
  infoUser: {
    display: 'flex',
    justifyContent: 'center',
    padding: 8,
    backgroundColor: '#f1f2f6',
    borderRadius: 5,
  },
  rowAdapt: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingTop: 25,
    gap: '10px',
  },
  textField: {
    width: '100%',
    marginTop: 10,
    [theme.breakpoints.down('sm')]: {
      marginRight: 0,
    },
    margin: 2,
  },
  textFieldSlug: {},
  textFieldPhone: {
    width: '100%',
    marginTop: 10,
    [theme.breakpoints.down('sm')]: {
      marginRight: 0,
    },
    margin: 2,
    padding: '18.5px 14px',
    borderRadius: '4px',
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: '1rem',
    borderColor: 'rgb(118 118 118 / 38%)',
    borderWidth: 'thin',
  },
  labelPhone: {
    transform: 'translate(5px, 22px) scale(0.85)',
    width: '71px',
    padding: '0 10px',
    background: '#fff',
    color: 'rgba(0, 0, 0, 0.54)',
  },
  buttonEdit: {
    margin: theme.spacing(1),
  },
  hasReferrer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  linkBox: {
    wordBreak: 'break-all',
    padding: '8px 0 8px 8px',
    border: '1px dashed #3ca653',
    fontSize: 12,
    margin: '0 0 10px 0',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#fff',
  },
  cancelBtn: {
    maxWidth: 400,
    color: '#F57C00',
    borderColor: '#F57C00',
  },
  saveBtn: {
    maxWidth: 400,
    backgroundColor: '#3ca653',
  },
}))

const schema = Yup.object().shape({
  name: Yup.string().required('É necessário informar o nome'),
  phone: Yup.string().required('É necessário informar seu Telefone'),
})

const EarnPoints: React.FC = () => {
  const classes = useStyles()
  const { keycloak } = useKeycloak()
  const history = useHistory()
  const { setClient } = useContext(ClientContext)
  const [editReferrer, setEditReferrer] = useState(false)
  const [referrer, setReferrer] = useState<AppLinkReferrer | null>(null)
  const [stateReferrer, setStateReferrer] = useState<
    'INITIAL' | 'CREATE_REFERRER' | 'UPDATE_REFERRER'
  >('INITIAL')
  const [referrerErrorMsg, setReferrerErrorMsg] = useState('')

  const [
    loadClient,
    { data: clientData, loading: loadingClient },
  ] = useLazyQuery<{
    myClient: Client
  }>(MY_CLIENT)

  const [
    loadClientReferrer,
    { data: clientReferrerData, loading: loadingReferrer },
  ] = useLazyQuery<{
    myClientReferrer: AppLinkReferrer
  }>(MY_CLIENT_REFERRER)

  const [
    updateMyClientReferrer,
    { loading: loadingUpdateReferrer },
  ] = useMutation(UPDATE_MY_CLIENT_REFERRER)

  useEffect(() => {
    if (keycloak.authenticated) {
      loadClient()
    } else {
      history.push('/login')
    }
  }, [keycloak.authenticated, loadClient, history])

  useEffect(() => {
    if (clientData) {
      setClient(clientData.myClient)
      loadClientReferrer()
      setEditReferrer(false)
    }
  }, [clientData, setClient, loadClientReferrer])

  useEffect(() => {
    setReferrerErrorMsg('')
    if (clientReferrerData) {
      setReferrer(clientReferrerData.myClientReferrer)
      setStateReferrer('UPDATE_REFERRER')
    } else {
      setReferrer(null)
      setStateReferrer('INITIAL')
    }
  }, [clientReferrerData, setReferrer])

  const updateReferrerLink = (values: FormikValues) => {
    setReferrerErrorMsg('')

    updateMyClientReferrer({
      variables: {
        slug: values.slug,
      },
    }).then(
      r => {
        loadClientReferrer()
        setEditReferrer(false)
      },
      error => {
        setReferrerErrorMsg(
          'Tente outro link! Este parece já estar sendo utilizado.',
        )
      },
    )
  }

  const share = () => {
    if (!referrer) {
      return
    }

    shareOrCoppy(
      referrer.link,
      'Convite PassFree',
      'Se cadastre na PassFree usando meu convite.',
    )
  }

  const openFormReferrer = () => {
    setEditReferrer(true)
    if (referrer) {
      setStateReferrer('UPDATE_REFERRER')
    } else {
      setStateReferrer('CREATE_REFERRER')
    }
  }

  const cancelEdition = () => {
    setEditReferrer(false)
    setStateReferrer('INITIAL')
  }

  if (loadingUpdateReferrer || loadingClient || loadingReferrer) {
    return <Loading />
  }

  return (
    <>
      <Card className={classes.root}>
        <CardHeader
          avatar={
            <ConfirmationNumber style={{ position: 'absolute', top: 109 }} />
          }
          title={
            <Typography
              variant="h5"
              style={{ fontWeight: 'bold', textAlign: 'center' }}
            >
              Ganhe pontos
            </Typography>
          }
        />

        <Divider />

        <CardContent className={classes.cardContent}>
          {!clientData && <IncompleteClient />}

          {clientData && (
            <div className={classes.invite}>
              <div
                className={classes.inviteHeader}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  color: '#3ca653',
                  padding: 10,
                  textAlign: 'center',
                }}
              >
                <h2 style={{ margin: 0 }}>Convidar</h2>

                <p>
                  Convide seus amigos e ganhe pontos por amigo que criar a conta
                  na PassFree usando seu link.
                </p>
              </div>

              <Card className={classes.cardInvite}>
                <CardContent
                  className={classes.cardContent}
                  style={{ paddingBottom: 20 }}
                >
                  <Formik
                    initialValues={{
                      slug: referrer?.slug || slugify(clientData.myClient.name),
                    }}
                    enableReinitialize
                    validateOnChange={false}
                    validateOnBlur={false}
                    onSubmit={updateReferrerLink}
                  >
                    {({ values, handleChange, handleSubmit: onSubmit }) => (
                      <>
                        {!referrer && (
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'center',
                              justifyContent: 'center',
                              gap: '8px',
                            }}
                          >
                            <span>Você ainda não tem um link de convite.</span>

                            {!editReferrer && (
                              <Button
                                variant="outlined"
                                size="medium"
                                color="primary"
                                startIcon={<Share />}
                                onClick={openFormReferrer}
                              >
                                Criar meu link agora
                              </Button>
                            )}
                          </div>
                        )}

                        {!editReferrer && referrer && (
                          <div className={classes.hasReferrer}>
                            <div className={classes.linkBox}>
                              <span>{referrer?.link}</span>

                              <IconButton
                                color="primary"
                                size="small"
                                className={classes.buttonEdit}
                                onClick={openFormReferrer}
                                style={{ margin: 0, color: '#312827c4' }}
                              >
                                <EditOutlined style={{ fontSize: '0.9em' }} />
                              </IconButton>
                            </div>

                            <div>
                              <Button
                                onClick={share}
                                variant="contained"
                                size="medium"
                                color="primary"
                                startIcon={<Share />}
                              >
                                Compartilhar link
                              </Button>
                            </div>
                          </div>
                        )}

                        {(editReferrer ||
                          stateReferrer === 'CREATE_REFERRER') && (
                          <>
                            <h3>Personalize seu link</h3>
                            <div style={{ marginBottom: '20px' }}>
                              <TextField
                                label="Personalize o seu link"
                                placeholder=""
                                variant="outlined"
                                className={classes.textFieldSlug}
                                onChange={handleChange}
                                value={values.slug}
                                name="slug"
                                helperText="Use apenas letras, números e '-'."
                                style={{ width: '100%', fontSize: '12px' }}
                              />
                            </div>

                            <div>
                              <span>Veja como está ficando seu link:</span>

                              <div className={classes.linkBox}>
                                <span>
                                  app.passfree.com.br/ref/{values.slug}
                                </span>
                              </div>
                            </div>

                            <Message
                              message={referrerErrorMsg}
                              type={MessageTypeEnum.ERROR}
                            />

                            <div className={classes.actions}>
                              <Button
                                color="secondary"
                                variant="outlined"
                                onClick={cancelEdition}
                                startIcon={<BlockRounded />}
                                className={classes.cancelBtn}
                              >
                                Cancelar
                              </Button>
                              <Button
                                color="secondary"
                                variant="contained"
                                onClick={() => onSubmit()}
                                startIcon={<Save />}
                                className={classes.saveBtn}
                              >
                                {referrer ? 'Salvar' : 'Criar'}
                              </Button>
                            </div>
                          </>
                        )}
                      </>
                    )}
                  </Formik>
                </CardContent>
              </Card>

              {!editReferrer && referrer && (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                  }}
                >
                  <br />

                  <Divider />

                  <br />

                  <Typography
                    variant="subtitle2"
                    style={{ textAlign: 'center' }}
                  >
                    Acompanhe os seus pontos no seu extrato.
                  </Typography>

                  <Button
                    variant="outlined"
                    style={{
                      color: '#064326',
                      borderColor: '#064326',
                      marginTop: 10,
                    }}
                    onClick={() => history.push('/extrato')}
                  >
                    Ver meu extrato
                  </Button>
                </div>
              )}
            </div>
          )}
        </CardContent>
      </Card>
    </>
  )
}

export default EarnPoints
