/* eslint-disable react/require-default-props */
import React from 'react'
import {
  makeStyles,
  Card,
  CardContent,
  Typography,
  CardActions,
  Button,
} from '@material-ui/core'

import ShareIcon from '@material-ui/icons/Share'
import { shareOrCoppy } from '../../utils/helpers'

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: 5,
    marginBottom: 5,
    borderRadius: 0,
    borderBottomRightRadius: 10,
    borderBottomLeftRadius: 10,
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      marginLeft: 5,
      marginRight: 5,
    },
  },
  cardContentVoucher: {
    display: 'flex',
    flexDirection: 'column',
    padding: 10,
    margin: 0,
  },
  cardActionVoucher: {
    display: 'flex',
    justifyContent: 'center',
  },
}))

type Props = {
  title: string
  text: any
}
const ShareCard: React.FC<Props> = ({ title, text }) => {
  const classes = useStyles()

  const share = () => {
    shareOrCoppy(window.location.href, title, text)
  }

  return (
    <Card className={classes.root}>
      <CardContent className={classes.cardContentVoucher}>
        <Typography variant="subtitle2" style={{ margin: 0 }}>
          Compartilhe esta promoção com seus amigos.
        </Typography>
      </CardContent>

      <CardActions className={classes.cardActionVoucher}>
        <Button
          variant="outlined"
          endIcon={<ShareIcon />}
          onClick={share}
          style={{ color: '#064326', borderColor: '#064326' }}
        >
          Compartilhar
        </Button>
      </CardActions>
    </Card>
  )
}
export default ShareCard
