import React from 'react'

import {
  makeStyles,
  CardHeader,
  Typography,
  CardContent,
  Card,
} from '@material-ui/core'

import {
  ReceiptOutlined,
  LocalMallOutlined,
  LocalActivityOutlined,
} from '@material-ui/icons'
import { PromotionTypeEnum } from '../../generated/graphql'

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: 5,
    marginBottom: 5,
    borderRadius: 0,
    borderBottomRightRadius: 10,
    borderBottomLeftRadius: 10,
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      marginLeft: 5,
      marginRight: 5,
    },
  },
  rulesContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    // padding: 10,
    textAlign: 'center',
    gap: '10px',
  },
  rules: {
    display: 'flex',
    flexDirection: 'column',
    justifyItems: 'flex-end',
    alignItems: 'center',
    fontSize: 13,
    fontWeight: 'bold',
  },
  rulesIcon: {
    fontSize: 60,
    paddingBottom: 10,
  },
}))

type Props = {
  type: PromotionTypeEnum
}

const HowItWorks: React.FC<Props> = ({ type }) => {
  const classes = useStyles()

  return (
    <div>
      <Card className={classes.root}>
        <CardHeader
          title={
            <Typography
              variant="h5"
              style={{ fontWeight: 'bold', color: '#064326' }}
            >
              Como Funciona?
            </Typography>
          }
        />

        <CardContent className={classes.rulesContainer}>
          <Typography className={classes.rules}>
            <ReceiptOutlined className={classes.rulesIcon} />
            Confira as Regras
          </Typography>

          {type === PromotionTypeEnum.Voucher && (
            <>
              <Typography className={classes.rules}>
                <LocalMallOutlined className={classes.rulesIcon} />
                Gere o voucher
              </Typography>

              <Typography className={classes.rules}>
                <LocalActivityOutlined className={classes.rulesIcon} />
                Resgate no local
              </Typography>
            </>
          )}

          {type === PromotionTypeEnum.Coupon && (
            <>
              <Typography className={classes.rules}>
                <LocalMallOutlined className={classes.rulesIcon} />
                Gere o cupom
              </Typography>

              <Typography className={classes.rules}>
                <LocalActivityOutlined className={classes.rulesIcon} />
                Resgate e pague no local
              </Typography>
            </>
          )}
        </CardContent>
      </Card>
    </div>
  )
}
export default HowItWorks
