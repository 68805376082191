import React from 'react'
import { Card, makeStyles, Typography } from '@material-ui/core'
import { WarningType } from '../types'

const useStyles = makeStyles(() => ({
  warnings: {
    padding: '0 12px 12px 12px',
  },
  boldText: {
    fontWeight: 'bold',
  },
}))

type Props = {
  title?: string
  warningList: WarningType[]
}
const WarningBox: React.FC<Props> = ({ warningList, title = 'Avisos' }) => {
  const classes = useStyles()

  return (
    <Card
      style={{
        backgroundColor: 'rgb(255, 244, 229)',
        marginBottom: 10,
        marginTop: 10,
      }}
    >
      <Typography
        style={{ paddingLeft: 15, paddingTop: 10, fontSize: '0.8rem' }}
        variant="h6"
      >
        {title}
      </Typography>
      <div className={classes.warnings}>
        {warningList.map((war, i) => (
          <li key={i.toString()} style={{ fontSize: '0.7rem' }}>
            <span className={classes.boldText}>{war.title}</span>
            {war.description}
          </li>
        ))}
      </div>
    </Card>
  )
}
export default WarningBox
