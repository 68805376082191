import React, { useContext, useEffect } from 'react'
import { useKeycloak } from '@react-keycloak/web'
import { useHistory } from 'react-router-dom'

import { useLazyQuery, useQuery } from '@apollo/client'

import {
  Button,
  Card,
  Divider,
  makeStyles,
  Typography,
} from '@material-ui/core'

import { LocalActivityOutlined } from '@material-ui/icons'
import {
  AppPromotionResponse,
  PromotionSearchInput,
  ReferrerTableTypeEnum,
  SortOrderEnum,
  UserKeycloak,
} from '../../generated/graphql'

import { APP_PROMOTIONS, GET_USER_ME } from '../../gql'

import { Loading, PromotionCard } from '../../components'
import { ClientContext } from '../../providers/ClientProvider'
import IncompleteClient from '../../components/Promotion/IncompleteClient'
import IncompletePhone from '../../components/Promotion/IncompletePhone'
import NotAccountCard from '../../components/NotAccountCard'
import Banner from '../../assets/img/banner.jpg'
import HelpBanner from '../../components/HelpBanner'
import CategoryList from '../../components/Category/CategoryList'
import { getRef, setRef, setRefHome } from '../../utils/reference'

const useStyles = makeStyles({
  welcomeCard: {
    backgroundColor: '#064326',
    color: 'white',
    textAlign: 'initial',
    padding: 12,
    borderRadius: 0,
    backgroundImage: `url(${Banner})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    marginBottom: 10,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  cardHeader: {
    width: '100%',
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  textInfo: {
    fontSize: 14,
    paddingLeft: 10,
    fontWeight: 'bold',
  },
  voucher: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 0,
    margin: 15,
    border: 'dashed',
    borderRadius: 8,
    borderWidth: 2,
    borderColor: '#f0932b',
    backgroundColor: 'rgba(255, 190, 118, 0.3)',
  },
  myVouchers: {
    marginTop: 10,
    padding: 10,
    textAlign: 'center',
    color: '#064326',
    fontWeight: 'bold',
    background: '#f1f2f6',
  },
  featurePromotion: {
    marginTop: 10,
    padding: 10,
    textAlign: 'center',
    color: '#064326',
    fontWeight: 'bold',
    background: '#f1f2f6',
  },
  morePromotion: {
    display: 'flex',
    justifyContent: 'center',
    margin: 15,
  },
})

// interface RootState {
//   environment: any
// }

const Home: React.FC = () => {
  const history = useHistory()
  const classes = useStyles()
  const { keycloak } = useKeycloak()

  // const {
  //   environment: { voucher },
  // } = useSelector((state: RootState) => state)
  // const [state, setState] = useState(false)
  const { client } = useContext(ClientContext)

  // const debounced = useDebouncedCallback(() => {
  //   setState(true)
  // }, 2000)

  const [loadUser, { data: userData, loading: loadingUser }] = useLazyQuery<{
    userMe: UserKeycloak
  }>(GET_USER_ME)

  const params: PromotionSearchInput = {
    perPage: 1000,
    sort: {
      updatedAt: SortOrderEnum.Desc,
    },
    and: { highlighted: true },
  }

  const { data: dataPromotions, loading: loadingData } = useQuery<{
    appPromotions: AppPromotionResponse
  }>(APP_PROMOTIONS, {
    variables: {
      params,
    },
  })

  useEffect(() => {
    if (keycloak.authenticated) {
      loadUser()
    }
  }, [keycloak.authenticated, loadUser])

  // useEffect(() => {
  //   debounced.callback()
  //   return () => {}
  // })

  const getPromotion = () => {
    if (!dataPromotions || !dataPromotions.appPromotions.edges) {
      return []
    }
    return dataPromotions.appPromotions.edges
  }

  const needPhoneNumber = () => {
    return userData && client && !client.phone
  }

  const needRegisterAction = () => {
    return userData && !client
  }

  const ref = getRef()

  if (!ref) {
    setRefHome()
  }

  if (loadingUser) {
    return <Loading />
  }

  return (
    <>
      <div style={{ padding: '0 10px' }}>
        {userData && (
          <Card className={classes.welcomeCard}>
            <Typography variant="h6">Olá, {userData.userMe.name}!</Typography>
            <Typography variant="subtitle2" gutterBottom>
              Que bom te ver por aqui! Não esqueça de usar as suas promoções.
            </Typography>

            <Button
              variant="outlined"
              size="small"
              startIcon={<LocalActivityOutlined />}
              onClick={() => history.push('/minhas-promocoes')}
              style={{ borderColor: '#f4b400', color: '#fff' }}
            >
              Minhas promoções
            </Button>
          </Card>
        )}

        {!keycloak.authenticated && <NotAccountCard />}

        <HelpBanner />

        {needPhoneNumber() && <IncompletePhone />}

        {needRegisterAction() && <IncompleteClient />}

        <Divider style={{ margin: 10 }} />

        <CategoryList />

        <Typography variant="h5" className={classes.featurePromotion}>
          Promoções destaque
        </Typography>

        {loadingData && <Loading />}

        {getPromotion().map(promotion => (
          <PromotionCard
            key={promotion._id}
            promotion={promotion}
            onClick={() => history.push(`/promocoes/${promotion.slug}`)}
          />
        ))}
      </div>

      <div>
        <div className={classes.morePromotion}>
          <Button
            color="secondary"
            variant="outlined"
            style={{ width: '60%' }}
            onClick={() => history.push(`/promocoes`)}
          >
            Mais promoções
          </Button>
        </div>
      </div>
    </>
  )
}

export default Home
