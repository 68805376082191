import React from 'react'
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  ApolloLink,
  DefaultOptions,
  HttpLink,
} from '@apollo/client'
import { asyncMap } from '@apollo/client/utilities'
import { useKeycloak } from '@react-keycloak/web'
// eslint-disable-next-line import/no-extraneous-dependencies
import { onError } from '@apollo/client/link/error'
import { toast, ToastOptions } from 'react-toastify'
import { GraphQLError } from 'graphql'
import { setContext } from '@apollo/client/link/context'
import { Redirect } from 'react-router-dom'
import { Config } from './config'
import 'react-toastify/dist/ReactToastify.min.css'

const handleTypeError = (httpCode: number): ToastOptions['type'] => {
  switch (httpCode) {
    case 400:
    case 429:
      return 'warning'
    case 404:
    case 500:
      return 'error'
    default:
      return undefined
  }
}

const handlerErrorGraphql = ({ message, extensions }: GraphQLError) => {
  // const history = useHistory()
  // eslint-disable-next-line no-console
  console.error('APOLO ERROR', message, extensions)

  if (extensions && extensions.code === 'E_UNAUTHORIZED') {
    // eslint-disable-next-line consistent-return
    // REDIRECTIONA PRA LOGIN
    // history.push('/login')
    toast.error(message)

    return <Redirect to={{ pathname: '/login' }} />
  }

  if (extensions && extensions.http_code) {
    toast(message, {
      type: handleTypeError(extensions.http_code),
    })
    const httpEMsg = extensions.exception.message

    if (extensions && extensions.validation) {
      // const valMessage = extensions.validation
      //   .map(
      //     (validation: {
      //       field: string
      //       message: string
      //       validation: string
      //     }) => validation.message,
      //   )
      //   .join(',')

      return toast(
        <>
          <b>{httpEMsg}</b>
          <ul>
            {extensions.validation.map(
              (validation: {
                field: string
                message: string
                validation: string
              }) => (
                <li>{validation.message}</li>
              ),
            )}
          </ul>
        </>,
        {
          type: handleTypeError(extensions.http_code),
        },
      )
    }
  } else {
    return toast.error(message)
  }

  // eslint-disable-next-line no-console
  return console.error(message)
}

const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
}

const ApolloConnectProvider: React.FC = ({ children }) => {
  const { keycloak } = useKeycloak()

  const httpLink = new HttpLink({
    uri: Config.graphqlUri,
    headers: {
      'Time-Zone': Intl.DateTimeFormat().resolvedOptions().timeZone,
    },
  })

  const authLink = setContext((_, { headers }) => {
    const authToken = keycloak?.token

    return {
      headers: {
        ...headers,
        Authorization: authToken ? `Bearer ${authToken}` : '',
      },
    }
  })

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.map(handlerErrorGraphql)
    }

    if (networkError) {
      // eslint-disable-next-line no-console
      console.error(`[Network error]: ${networkError}`)
    }
  })

  const interceptResponseLink = new ApolloLink((operation, forward) =>
    asyncMap(forward(operation), async response => {
      Object.keys(response.data || {}).forEach(k => {
        const value = (response.data as any)[k]
        if (value.message) {
          toast.success(value.message)
        }
      })

      return response
    }),
  )

  const client = new ApolloClient({
    cache: new InMemoryCache(),
    link: ApolloLink.from([
      errorLink,
      interceptResponseLink.concat(authLink.concat(httpLink)),
    ]),
    defaultOptions,
  })

  return <ApolloProvider client={client}>{children}</ApolloProvider>
}

export default ApolloConnectProvider
