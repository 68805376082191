import React from 'react'
import { useHistory } from 'react-router-dom'

import {
  makeStyles,
  CardContent,
  Typography,
  CardActions,
  Button,
  Card,
} from '@material-ui/core'

import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined'

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: 5,
    marginBottom: 5,
    borderRadius: 0,
    borderBottomRightRadius: 10,
    borderBottomLeftRadius: 10,
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      marginLeft: 5,
      marginRight: 5,
    },
  },
  cardContentVoucher: {
    display: 'flex',
    flexDirection: 'column',
    padding: 10,
    margin: 0,
  },
  contentTitle: {
    fontWeight: 'bold',
    margin: 0,
  },
  cardActionVoucher: {
    display: 'flex',
    justifyContent: 'center',
  },
}))

const RequireLogin: React.FC = () => {
  const classes = useStyles()
  const history = useHistory()

  return (
    <Card className={classes.root}>
      <CardContent className={classes.cardContentVoucher}>
        <Typography variant="h6" className={classes.contentTitle}>
          Acesse sua conta!
        </Typography>

        <Typography variant="subtitle2" style={{ margin: 0 }}>
          Você precisa <b style={{ color: '#064326' }}>estar logado</b> para
          poder resgatar essa promoção.
        </Typography>
      </CardContent>

      <CardActions className={classes.cardActionVoucher}>
        <Button
          variant="outlined"
          endIcon={<ExitToAppOutlinedIcon />}
          onClick={() =>
            history.push(`/login?redirect=${window.location.pathname}`)
          }
        >
          Fazer login
        </Button>
      </CardActions>
    </Card>
  )
}
export default RequireLogin
