import React from 'react'
import { Player } from '@lottiefiles/react-lottie-player'
import { makeStyles } from '@material-ui/core/styles'
import lottiJson from './lf20_sE2Em1.json'

const useStyles = makeStyles(() => ({
  loading: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
}))

const Loading: React.FC = () => {
  const classes = useStyles()

  return (
    <div
      className={`flex flex-col w-full items-center justify-center text-sm ${classes.loading}`}
    >
      <Player
        autoplay
        loop
        src={lottiJson}
        style={{ height: '150px', width: '150px' }}
      />

      <h3 className="text-center">Aguarde, estamos trabalhando.</h3>
    </div>
  )
}

export default Loading
