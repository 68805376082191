import React, { useContext, useEffect } from 'react'
import { useLazyQuery } from '@apollo/client'

import {
  Box,
  Button,
  Card,
  CardHeader,
  makeStyles,
  Tab,
  Tabs,
  Typography,
} from '@material-ui/core'

import {
  AssignmentOutlined,
  HelpOutline,
  NewReleasesOutlined,
} from '@material-ui/icons'

import { useHistory } from 'react-router-dom'
import { ClientContext } from '../../providers/ClientProvider'
import { Loading } from '../../components'
import {
  AppPoint,
  AppPointTransaction,
  Wallet,
  WalletTransaction,
} from '../../generated/graphql'
import TransactionCard from '../../components/Extracts/TransactionCard'
import EmptyClient from '../../components/Promotion/EmptyClient'
import { APP_MY_POINTS, APP_WALLET } from '../../gql'
import PointTransactionCard from '../../components/Extracts/PointTransactionCard'

const useStyles = makeStyles(() => ({
  root: {
    margin: 5,
    padding: 5,
    marginBottom: 100,
  },
  clean: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 10,
    marginBottom: 10,
  },
  icon: {
    width: 80,
    height: 80,
  },
  warningText: {
    fontWeight: 'bold',
    paddingTop: 10,
    textAlign: 'center',
  },
  avatar: {
    width: 85,
    height: 85,
    background: '#f1f2f6',
    borderWidth: 0.1,
    borderRadius: 10,
  },
  history: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 5,
    height: 120,
    marginTop: 5,
  },
  infoHistory: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 10,
  },
  titleHistory: {
    fontSize: 18,
    fontWeight: 'bold',
  },
  count: {
    fontSize: '14px',
    textAlign: 'end',
  },
  rootCardHeader: {
    flexDirection: 'column',
  },
  cardHeaderTitle: {
    fontSize: '1rem',
    lineHeight: '1rem',
    paddingBottom: '15px',
    textAlign: 'center',
  },
}))

interface TabPanelProps {
  children: React.ReactNode
  index: any
  value: any
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

const Extracts: React.FC = () => {
  const classes = useStyles()
  const history = useHistory()
  const { client } = useContext(ClientContext)
  const [value, setValue] = React.useState(0)

  const handleChange = (event: any, newValue: number) => {
    setValue(newValue)
  }

  const [loadWallet, { data, loading }] = useLazyQuery<{
    appWallet: Wallet
  }>(APP_WALLET)

  const [
    loadPoints,
    { data: dataPoints, loading: loadingPoints },
  ] = useLazyQuery<{
    appMyPoints: AppPoint
  }>(APP_MY_POINTS)

  useEffect(() => {
    if (client) {
      loadWallet()
      loadPoints()
    }
  }, [client, loadWallet, loadPoints])

  const getTransactions = (): WalletTransaction[] => {
    if (data?.appWallet.transactions) {
      return data?.appWallet.transactions
    }
    return []
  }
  const getPointTransactions = (): AppPointTransaction[] => {
    if (dataPoints?.appMyPoints.transactions) {
      return dataPoints.appMyPoints.transactions
    }
    return []
  }

  if (!client) {
    return <EmptyClient />
  }

  if (loading || loadingPoints) {
    return <Loading />
  }

  return (
    <div className={classes.root}>
      <CardHeader
        avatar={
          <AssignmentOutlined style={{ position: 'absolute', top: 109 }} />
        }
        title={
          <Typography
            variant="h5"
            style={{ fontWeight: 'bold', textAlign: 'center' }}
          >
            Extrato
          </Typography>
        }
      />
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="simple tabs example"
      >
        <Tab label="PassCoins" {...a11yProps(0)} />
        <Tab label="Pontos" {...a11yProps(1)} />
      </Tabs>
      <TabPanel value={value} index={0}>
        {getTransactions().length === 0 && (
          <Card style={{ marginBottom: 10 }}>
            <CardHeader
              title={
                <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                  Você ainda não computou PassCoins!
                </Typography>
              }
            />
            <Box className={classes.clean}>
              <NewReleasesOutlined className={classes.icon} />

              <Typography variant="subtitle2" className={classes.warningText}>
                Para ganhar PassCoins, e usufruir das vantagens PassFree
              </Typography>

              <Button
                variant="outlined"
                style={{
                  color: '#064326',
                  borderColor: '#064326',
                  marginTop: 10,
                }}
              >
                Veja locais perto de Você
              </Button>
            </Box>
          </Card>
        )}

        <CardHeader
          action={
            <Typography
              className={classes.count}
              color="textSecondary"
              gutterBottom
            >
              Você possui{' '}
              <b style={{ color: '#064326' }}>
                {data?.appWallet.balance || 0} PassCoins
              </b>
            </Typography>
          }
        />

        {getTransactions().map(item => (
          <div key={item._id.toString()}>
            <TransactionCard transaction={item} />
          </div>
        ))}
      </TabPanel>

      <TabPanel value={value} index={1}>
        {getPointTransactions().length === 0 && (
          <Card style={{ marginBottom: 10 }}>
            <CardHeader
              title={
                <Typography
                  variant="h6"
                  style={{ fontWeight: 'bold', textAlign: 'center' }}
                >
                  Você ainda não ganhou nenhum ponto.
                </Typography>
              }
            />
            <Box className={classes.clean}>
              <NewReleasesOutlined className={classes.icon} />

              <Typography variant="subtitle2" className={classes.warningText}>
                Para ganhar pontos use seu link personalizado para convidar
                amigos.
              </Typography>

              <Button
                variant="outlined"
                style={{
                  color: '#064326',
                  borderColor: '#064326',
                  marginTop: 10,
                }}
                onClick={() => history.push('/ganhe-pontos')}
              >
                Convidar amigos
              </Button>
            </Box>
          </Card>
        )}

        <Card style={{ marginBottom: 10, background: 'white', padding: '0' }}>
          <CardHeader
            title={`Você possui ${dataPoints?.appMyPoints.balance || 0} pontos`}
            action={
              <Typography
                className={classes.count}
                color="textSecondary"
                gutterBottom
              >
                {dataPoints?.appMyPoints.PTSxPSC && (
                  <>
                    Cada{' '}
                    <b style={{ color: '#064326' }}>
                      {dataPoints?.appMyPoints.PTSxPSC} pontos
                    </b>{' '}
                    equivalem a <b style={{ color: '#064326' }}>1 PassCoin</b>.
                    <br />
                  </>
                )}
                Seus pontos equivalem á{' '}
                <b style={{ color: '#064326' }}>
                  {dataPoints?.appMyPoints.inPSC || 0} PassCoins.
                </b>
                {dataPoints?.appMyPoints.minPSC && (
                  <>
                    {' '}
                    <br />
                    <small>
                      Você precisa ao menos {dataPoints?.appMyPoints.minPSC}{' '}
                      PassCoins para realizar uma troca.
                    </small>
                  </>
                )}
              </Typography>
            }
            classes={{
              root: classes.rootCardHeader,
              title: classes.cardHeaderTitle,
            }}
          />
        </Card>

        {getPointTransactions().map(item => (
          <div key={item._id.toString()}>
            <PointTransactionCard transaction={item} />
          </div>
        ))}
      </TabPanel>
    </div>
  )
}

export default Extracts
