import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Card, Link, Typography } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import RemoveIcon from '@material-ui/icons/Remove'

import {
  TransactionOperationEnum,
  WalletTransaction,
} from '../../generated/graphql'
import defaulBg from '../../assets/img/default-bg.jpg'
import TransactionTypeIcon from './TransactionTypeIcon'

const useStyles = makeStyles(() => ({
  root: {
    margin: 5,
    padding: 5,
  },
  clean: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 10,
    marginBottom: 10,
  },
  icon: {
    width: 80,
    height: 80,
  },
  warningText: {
    fontWeight: 'bold',
    paddingTop: 10,
    textAlign: 'center',
  },
  avatar: {
    width: 65,
    height: 65,
    background: '#f1f2f6',
    borderWidth: 0.1,
    borderRadius: 10,
  },
  history: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 5,
    height: 120,
    marginTop: 5,
  },
  infoHistory: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 10,
  },
  titleHistory: {
    fontSize: 18,
    fontWeight: 'bold',
  },
  operation: {
    background: 'rgba(76, 175, 80, 0.23)',
    padding: 2,
    borderRadius: 3,
    color: '#4caf50',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 80,

    '&.deposit': {
      background: 'rgba(76, 175, 80, 0.23)',
      color: '#4caf50',
    },
    '&.withdraw': {
      background: 'rgba(244, 67, 54, 0.08)',
      color: '#f44336',
    },
  },
  date: {
    fontSize: 14,
  },
  local: {
    fontSize: 12,
  },
  title: {
    fontSize: 13,
  },
}))

type Props = {
  transaction: WalletTransaction
}
const TransactionCard: React.FC<Props> = ({ transaction }) => {
  const classes = useStyles()

  const labelByOperation = () => {
    if (transaction.operation === TransactionOperationEnum.Deposit) {
      return (
        <Typography variant="subtitle1">
          <b className={`${classes.operation} deposit`}>
            <AddIcon fontSize="inherit" />
            {transaction.deposit}
          </b>
        </Typography>
      )
    }
    return (
      <Typography variant="subtitle1">
        <b className={`${classes.operation} withdraw`}>
          <RemoveIcon fontSize="inherit" />
          {transaction.withdraw}
        </b>
      </Typography>
    )
  }

  return (
    <Card className={classes.history}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Link href={`/locais/${transaction.local?.slug}`}>
          <img
            alt={transaction.local?.name}
            // onClick={() => history.push(`/locais/${transaction.local?.slug}`)}
            src={transaction.local?.thumb || defaulBg}
            className={classes.avatar}
          />
          <div style={{ marginTop: '-42px', marginLeft: '5px' }}>
            <TransactionTypeIcon type={transaction.type} />
          </div>
        </Link>

        <div className={classes.infoHistory}>
          <Typography className={classes.title} variant="subtitle2">
            {transaction.title}
          </Typography>
          <Typography
            className={classes.local}
            variant="body1"
            color="textSecondary"
          >
            {transaction.local?.name}
          </Typography>
          <Typography variant="subtitle2">
            {transaction.promotionCode}
          </Typography>
        </div>
      </div>

      <div>
        {labelByOperation()}
        <Typography className={classes.date} color="textSecondary">
          {transaction.createdAt.format}
        </Typography>
      </div>
    </Card>
  )
}
export default TransactionCard
