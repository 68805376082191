import { ReferrerTableTypeEnum } from '../generated/graphql'

const PASSFREE_REF = 'passfree_ref'
const PASSFREE_REF_HOME = 'passfree_ref_home'

export type ReferrerType = {
  tableType: ReferrerTableTypeEnum
  tableId: string
  linkId?: string
  slug?: string
  startedAt: Date
}

export const getRef = (): ReferrerType | null => {
  const ref = window.localStorage.getItem(PASSFREE_REF)

  if (!ref) {
    return null
  }

  const data = JSON.parse(ref)

  if (!data.tableType || !data.tableId) {
    return null
  }

  return {
    tableType: data.tableType,
    tableId: data.tableId,
    startedAt: data.startedAt,
    linkId: data.linkId || null,
    slug: data.slug || null,
  }
}

export const setRefHome = () => {
  window.localStorage.setItem(PASSFREE_REF_HOME, '1')
}
export const getRefHome = () => {
  return window.localStorage.getItem(PASSFREE_REF_HOME)
}

export const setRef = async (
  type: ReferrerTableTypeEnum,
  id: string,
  linkId?: string,
  slug?: string,
  force = false,
) => {
  const localRef = getRef()

  console.log('LOCAL REF', localRef)

  if (localRef && !force) {
    console.log('REF RETURN')
    return
  }

  const data: ReferrerType = {
    tableType: type,
    tableId: id,
    startedAt: new Date(),
    linkId,
    slug,
  }
  console.log('ref DATA', JSON.stringify(data))

  window.localStorage.setItem(PASSFREE_REF, JSON.stringify(data))
}

export const setRefPage = async (type: ReferrerTableTypeEnum, id: string) => {
  const refHome = getRefHome()

  if (!refHome) {
    setRef(type, id)
  }
}

export const clearRef = () => {
  window.localStorage.removeItem(PASSFREE_REF)
}

export const clearRefHome = () => {
  window.localStorage.removeItem(PASSFREE_REF_HOME)
}
