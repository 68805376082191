import React from 'react'

import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  makeStyles,
  Typography,
} from '@material-ui/core'

import { HelpOutline, Mail, Phone, WhatsApp } from '@material-ui/icons'
import { CONTACTS } from '../constants'

const useStyles = makeStyles(theme => ({
  root: {
    padding: 10,
    margin: 10,
  },

  logo: {
    width: '100%',
    maxWidth: '300px',
  },
  featuresBox: {
    flexDirection: 'column',
    display: 'flex',

    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      justifyContent: 'space-around',
    },
  },
  feature: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    background: '#a3bdd6',
    padding: '12px',

    [theme.breakpoints.up('md')]: {
      width: '48%',
    },
  },
  featureTitle: {
    margin: 0,
  },
  contactItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    whiteSpace: 'normal',
    wordBreak: 'break-all',
    textDecoration: 'none',
    color: '#3ca653',

    [theme.breakpoints.up('md')]: {
      width: '31%',
    },
  },
}))

const Help: React.FC = () => {
  const classes = useStyles()

  const openWhats = () => {
    const text = encodeURIComponent('Preciso de ajuda com o App PassFree')
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent,
      )
    ) {
      window.open(
        `https://api.whatsapp.com/send?phone=${CONTACTS.whatsapp}&amp;text=${text}`,
        '_blank',
      )
    } else {
      window.open(
        `https://web.whatsapp.com/send?phone=${CONTACTS.whatsapp}&amp;text=${text}`,
        '_blank',
      )
    }
  }

  return (
    <Card className={classes.root}>
      <CardHeader
        avatar={<HelpOutline style={{ position: 'absolute', top: 109 }} />}
        title={
          <Typography
            variant="h5"
            style={{ fontWeight: 'bold', textAlign: 'center' }}
          >
            Precisa de ajuda?
          </Typography>
        }
      />

      <Divider />

      <CardContent>
        <div>
          <p>
            Está tendo dificuldades para usar nosso aplciativo ou tem alguma
            dúvida? Você pode falar diretamente com o pessoal do nosso
            atendimento.
          </p>
          {CONTACTS.whatsapp && (
            <Button
              variant="outlined"
              onClick={openWhats}
              style={{
                color: '#064326',
                borderColor: '#064326',
                marginTop: 10,
              }}
              startIcon={<WhatsApp />}
            >
              Fale com nosso atendimento
            </Button>
          )}

          <br />
          <br />

          <h3>Ou então entre em contato:</h3>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
          >
            {CONTACTS.phone && (
              <a
                href={`tel:${CONTACTS.phone}`}
                target="_blank"
                rel="noreferrer"
                className={classes.contactItem}
              >
                <div>
                  <Phone />
                </div>
                <p>{CONTACTS.phone}</p>
              </a>
            )}
            {CONTACTS.email && (
              <a
                href={`mailto:${CONTACTS.email}`}
                target="_blank"
                rel="noreferrer"
                className={classes.contactItem}
              >
                <div>
                  <Mail />
                </div>
                <p>{CONTACTS.email}</p>
              </a>
            )}
          </div>
        </div>
      </CardContent>
    </Card>
  )
}
export default Help
