import React from 'react'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Typography,
} from '@material-ui/core'
import { LocalActivityOutlined } from '@material-ui/icons'

import { AppPromoCode } from '../../generated/graphql'
import LocalSticker from './LocalSticker'
import PromotionCodeDialog from '../Promotion/PromotionCodeDialog'

const useStyles = makeStyles(() => ({
  sub: {
    fontSize: '1em',
    lineHeight: '1em',
  },
  codeSection: {
    backgroundColor: '#4caf50',
    padding: 4,
  },
  dashedCode: {
    border: '1px dashed #fff',
    padding: 0,
  },
  code: {
    margin: 0,
    textAlign: 'center',
    fontSize: '1.4em',
    lineHeight: '1.2em',
    color: '#edededf0',
  },
  cardHeader: {
    padding: 8,
  },
}))

type Props = {
  promoCode: AppPromoCode
}
const MyPromotionCard: React.FC<Props> = ({ promoCode }) => {
  const classes = useStyles()
  const history = useHistory()
  const [open, setOpen] = React.useState(false)

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <>
      <Card>
        <CardHeader
          avatar={
            promoCode.local ? (
              <LocalSticker
                local={promoCode.local}
                promoType={promoCode.promotionType}
              />
            ) : (
              <span>Sem local</span>
            )
          }
          title={promoCode.description}
          subheader={
            <Typography variant="caption" style={{ lineHeight: '0.8em' }}>
              {promoCode.local?.name} <br />
              <span style={{ textAlign: 'center' }}>
                {' '}
                Use até: <strong>{promoCode.expireIn.format}</strong>
              </span>
            </Typography>
          }
          classes={{ content: classes.sub, root: classes.cardHeader }}
        />
        <CardContent className={classes.codeSection}>
          <div className={classes.dashedCode}>
            <h2 className={classes.code}>{promoCode.code}</h2>
          </div>
        </CardContent>
        <CardActions
          disableSpacing
          style={{ display: 'grid', gridTemplateColumns: 'auto auto', gap: 8 }}
        >
          <Button
            variant="outlined"
            size="small"
            startIcon={<LocalActivityOutlined />}
            onClick={() => setOpen(!open)}
            style={{ borderColor: '#4caf50', color: '#4caf50' }}
          >
            Meu código
          </Button>
          {promoCode.promotion && (
            <Button
              variant="outlined"
              size="small"
              startIcon={<LocalActivityOutlined />}
              onClick={() =>
                history.push(`/promocoes/${promoCode.promotion?.slug}`)
              }
              style={{ borderColor: '#4caf50', color: '#4caf50' }}
            >
              Ver promoção
            </Button>
          )}
        </CardActions>
      </Card>

      <PromotionCodeDialog
        open={open}
        promoType={promoCode.promotionType}
        expirateAtString={promoCode.expireIn.format}
        code={promoCode.code}
        handleClose={handleClose}
      />
    </>
  )
}
export default MyPromotionCard
