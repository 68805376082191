/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'

import { Alert } from '@material-ui/lab'

import { Link } from '@material-ui/core'

export type Color = 'success' | 'info' | 'warning' | 'error'
interface AlertCardProps {
  type: Color
  link: string
  onClick?: () => void
}

const AlertCard: React.FC<AlertCardProps> = ({
  link,
  type,
  onClick,
  children,
}) => (
  <Alert severity={type} style={{ marginTop: 10, cursor: 'pointer' }}>
    {children}{' '}
    {onClick && (
      <Link color="inherit" underline="always" onClick={() => onClick()}>
        {link}
      </Link>
    )}
  </Alert>
)

export default AlertCard
