import React from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'

import Container from '@material-ui/core/Container'
import { makeStyles } from '@material-ui/core/styles'
import { useKeycloak } from '@react-keycloak/web'

import HomePage from '../pages/Home/Home'
import PromoDetail from '../pages/PromoDetails/PromoDetails'
import LocationsProfile from '../pages/Locations/LocationsProfile'
import ConfirmPhone from '../pages/ConfirmPhone/ConfirmPhone'
import EditProfile from '../pages/EditProfile/EditProfile'
import Locations from '../pages/Locations/Locations'
import Extracts from '../pages/Extracts/Extracts'
import Promotions from '../pages/Promotions/Promotions'
import Login from '../pages/Login/Login'
import Information from '../pages/Information'
import Rules from '../pages/Rules'
import TermsAndConditions from '../pages/TermsAndConditions'

import Sidebar from '../components/SideBar/SideBar'
import ScrollTop from '../components/ScrollTop/ScrollTop'
import PrivateRoute from './PrivateRoute'
import { ClientProvider } from '../providers/ClientProvider'
import { Loading } from '../components'
import Welcome from '../pages/Welcome/Welcome'
import PageNotFound from '../pages/PageNotFound'
import About from '../pages/About'
import MyPromotions from '../pages/MyPromotions'
import Help from '../pages/Help'
import ScanCode from '../pages/ScanCode'
import RefScanCode from '../pages/RefScanCode/RefScanCode'
import ReferenceLink from '../pages/ReferenceLink'
import EarnPoints from '../pages/EarnPoints'

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: 80,
    [theme.breakpoints.down('sm')]: {
      padding: 0,
    },
  },
}))

const AppRouter: React.FC = () => {
  const classes = useStyles()

  const { initialized } = useKeycloak()

  if (!initialized) {
    return <Loading />
  }

  return (
    <ClientProvider>
      <Router>
        <ScrollTop />

        <Sidebar>
          <Container maxWidth="sm" className={classes.container}>
            <Switch>
              <Route path="/login" component={Login} />
              <Route path="/bem-vindo" component={Welcome} />

              <Route path="/promocoes/:slug" component={PromoDetail} />
              <Route path="/promocoes" component={Promotions} />
              <Route path="/minhas-promocoes" component={MyPromotions} />

              <Route path="/locais/:slug" component={LocationsProfile} />
              <Route path="/locais" component={Locations} />

              <Route path="/quem-somos" component={About} />
              <Route path="/informacoes" component={Information} />
              <Route path="/regras" component={Rules} />
              <Route path="/termos-e-condicoes" component={TermsAndConditions} />
              <Route path="/ajuda" component={Help} />

              <Route path="/cod/:code" component={ScanCode} />
              <Route path="/cod" component={ScanCode} />
              <Route path="/block-cod/:code" component={RefScanCode} />
              <Route path="/block-cod" component={RefScanCode} />

              <Route path="/ref/:code" component={ReferenceLink} />

              <PrivateRoute path="/confirmar-telefone" component={ConfirmPhone} />
              <PrivateRoute path="/extrato" component={Extracts} />
              <PrivateRoute path="/perfil" component={EditProfile} />
              <PrivateRoute path="/ganhe-pontos" component={EarnPoints} />

              <Route exact path="/" component={HomePage} />

              <Route path="*" component={PageNotFound} />
            </Switch>
          </Container>
        </Sidebar>
      </Router>
    </ClientProvider>
  )
}

export default AppRouter
