import React, { ReactNode, useEffect, useState } from 'react'
import { useKeycloak } from '@react-keycloak/web'
import { useLazyQuery } from '@apollo/client'
import { MY_CLIENT } from '../gql'
import { Client } from '../generated/graphql'

export const ClientContext = React.createContext<{
  client: Client | undefined
  setClient?: any
}>({ client: undefined, setClient: () => {} })

export const ClientProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [client, setClient] = useState<Client | undefined>(undefined)
  const { keycloak } = useKeycloak()

  const [loadClient, { data: clientData }] = useLazyQuery<{
    myClient: Client
  }>(MY_CLIENT)

  useEffect(() => {
    if (keycloak.authenticated) {
      loadClient()
    } else {
      setClient(undefined)
    }
  }, [keycloak.authenticated, loadClient])

  useEffect(() => {
    if (clientData) {
      setClient(clientData.myClient)
    } else {
      setClient(undefined)
    }
  }, [clientData])

  return (
    <ClientContext.Provider value={{ client, setClient }}>
      {children}
    </ClientContext.Provider>
  )
}
