import React from 'react'
import AnimateHeight from 'react-animate-height'

import {
  makeStyles,
  Card,
  CardContent,
  Typography,
  IconButton,
} from '@material-ui/core'
import { TextRotationDownOutlined, TextRotateUp } from '@material-ui/icons'

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: 5,
    marginBottom: 5,
    borderRadius: 0,
    borderBottomRightRadius: 10,
    borderBottomLeftRadius: 10,
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      marginLeft: 5,
      marginRight: 5,
    },
  },
}))

type Props = {
  rules?: string | null | undefined
}
const Rules: React.FC<Props> = ({ rules }) => {
  const classes = useStyles()

  const drawerRef = React.useRef(null)
  const [heightAnimation, setHeightAnimation] = React.useState<string | number>(
    60,
  )

  return (
    <Card className={classes.root}>
      <CardContent ref={drawerRef}>
        <Typography
          variant="h5"
          style={{
            fontWeight: 'bold',
            color: '#064326',
            paddingBottom: 5,
          }}
        >
          Regras
        </Typography>

        <AnimateHeight duration={500} height={heightAnimation}>
          <Typography variant="caption">
            <li>{rules || 'Nenhuma regra adicional a ser exibida.'}</li>
          </Typography>
        </AnimateHeight>
      </CardContent>

      {heightAnimation === 60 && (
        <IconButton onClick={() => setHeightAnimation('auto')}>
          <TextRotationDownOutlined color="secondary" />
        </IconButton>
      )}

      {heightAnimation === 'auto' && (
        <IconButton onClick={() => setHeightAnimation(60)}>
          <TextRotateUp color="secondary" />
        </IconButton>
      )}
    </Card>
  )
}
export default Rules
