import React from 'react'

import {
  CardHeader,
  Card,
  CardContent,
  CardActions,
  Button,
  makeStyles,
  Divider,
  Typography,
} from '@material-ui/core'

import MessageOutlinedIcon from '@material-ui/icons/MessageOutlined'
import EditOutlinedIcon from '@material-ui/icons/EditOutlined'
import SendIcon from '@material-ui/icons/Send'

const useStyles = makeStyles({
  root: {
    padding: 10,
    margin: 10,
  },
  cardAction: {
    display: 'flex',
    justifyContent: 'space-around',
    paddingTop: 20,
  },
  button: {
    width: '100%',
    borderColor: '#064326',
  },
})

const ConfirmPhone: React.FC = () => {
  const classes = useStyles()

  return (
    <Card className={classes.root}>
      <CardHeader
        avatar={<MessageOutlinedIcon />}
        title={
          <Typography
            variant="h5"
            style={{ fontWeight: 'bold', textAlign: 'center' }}
          >
            Valide seu telefone
          </Typography>
        }
      />
      <Divider />
      <CardContent>
        <Typography variant="subtitle1">
          Enviaremos um código de confirmação via SMS, após tipo de recebimento
          ele na caixa de texto para confirmar seu telefone.
        </Typography>

        <CardActions className={classes.cardAction}>
          <div>
            <Typography style={{ fontWeight: 'bold' }}>
              Números de telefone:
            </Typography>

            <Typography>
              +45 85025841 <EditOutlinedIcon fontSize="inherit" />
            </Typography>
          </div>

          <div>
            <Button
              startIcon={<SendIcon />}
              className={classes.button}
              size="small"
              variant="outlined"
              onClick={() => {}}
            >
              Enviar código
            </Button>
          </div>
        </CardActions>
      </CardContent>
    </Card>
  )
}

export default ConfirmPhone
