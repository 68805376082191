import React from 'react'
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core'
import { AppQrCodeSimulateResponse } from '../../generated/graphql'

type Props = {
  scanResponse: AppQrCodeSimulateResponse | null
  code: string
  handleClose: any
  rescueCode: any
}
const ChangeRefConfirmDialog: React.FC<Props> = ({
  scanResponse,
  code,
  handleClose,
  rescueCode,
}) => {
  if (!scanResponse) {
    console.log('erro')
    return <span>Erro na resposta</span>
  }

  return (
    <>
      <DialogTitle id="alert-dialog-slide-title">
        Mudar representante do bloco
      </DialogTitle>
      <DialogContent>
        {scanResponse && (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <p>{scanResponse.message}</p>
            <span>
              Local: <b>{scanResponse.local?.name}</b>
            </span>
            <span>
              Promoção: <b>{scanResponse.promotion?._id}</b>
            </span>
            <span>
              Bloco: <b>{code}</b>
            </span>

            <span>
              Referência atual: <b>---</b>
            </span>

            <span>
              Quer ser o representante deste bloco de códigos promocionais?
            </span>
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary" variant="outlined">
          Cancelar
        </Button>
        <Button
          onClick={rescueCode}
          color="primary"
          variant="outlined"
          style={{ color: '#064326', borderColor: '#064326' }}
        >
          Alterar representante
        </Button>
      </DialogActions>
    </>
  )
}
export default ChangeRefConfirmDialog
