import React from 'react'
import { useHistory } from 'react-router-dom'

import {
  makeStyles,
  CardContent,
  Typography,
  CardActions,
  Button,
  Card,
} from '@material-ui/core'

import LocalActivityOutlinedIcon from '@material-ui/icons/LocalActivityOutlined'

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: 5,
    marginBottom: 5,
    borderRadius: 0,
    borderBottomRightRadius: 10,
    borderBottomLeftRadius: 10,
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      marginLeft: 5,
      marginRight: 5,
    },
  },
  cardContentVoucher: {
    display: 'flex',
    flexDirection: 'column',
    padding: 10,
    margin: 0,
  },
  contentTitle: {
    fontWeight: 'bold',
    margin: 0,
  },
  cardActionVoucher: {
    display: 'flex',
    justifyContent: 'center',
  },
}))

const SoldOut: React.FC = () => {
  const classes = useStyles()
  const history = useHistory()

  return (
    <Card className={classes.root}>
      <CardContent className={classes.cardContentVoucher}>
        <Typography variant="h6" className={classes.contentTitle}>
          Promoção ESGOTADA!
        </Typography>

        <Typography variant="subtitle2" style={{ margin: 0 }}>
          Lamento, esta promoção já esgotou.
        </Typography>
      </CardContent>

      <CardActions className={classes.cardActionVoucher}>
        <Button
          variant="outlined"
          endIcon={<LocalActivityOutlinedIcon />}
          onClick={() => history.push('/promocoes')}
        >
          Ver outras promoções
        </Button>
      </CardActions>
    </Card>
  )
}
export default SoldOut
