import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useQuery } from '@apollo/client'
import { Category, CategorySlugEnum } from '../../generated/graphql'
import { APP_CATEGORIES_WITH_PROMO } from '../../gql'
import CategoryItem from './CategoryItem'

const useStyles = makeStyles(() => ({
  root: {
    display: 'grid',
    gridTemplateColumns: 'auto auto auto auto',
    gap: 2,
  },
}))

type Props = {
  selectedCategory?: CategorySlugEnum
}
const CategoryList: React.FC<Props> = ({ selectedCategory }) => {
  const classes = useStyles()

  const { data } = useQuery<{
    appCategoriesWithPromo: Category[]
  }>(APP_CATEGORIES_WITH_PROMO)

  return (
    <div className={classes.root}>
      {data &&
        data.appCategoriesWithPromo.map(cat => (
          <CategoryItem
            selected={cat.slug === selectedCategory}
            category={cat}
            key={cat.slug}
          />
        ))}
    </div>
  )
}
export default CategoryList
