import React from 'react'
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  makeStyles,
  Typography,
} from '@material-ui/core'
import { AssignmentOutlined } from '@material-ui/icons'

const useStyles = makeStyles(theme => ({
  root: {
    padding: 10,
    margin: 10,
  },

  logo: {
    width: '100%',
    maxWidth: '300px',
  },
  featuresBox: {
    flexDirection: 'column',
    display: 'flex',

    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      justifyContent: 'space-around',
    },
  },
  feature: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    background: '#a3bdd6',
    padding: '12px',

    [theme.breakpoints.up('md')]: {
      width: '48%',
    },
  },
  featureTitle: {
    margin: 0,
  },
  contactItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    whiteSpace: 'normal',
    wordBreak: 'break-all',
    textDecoration: 'none',
    color: '#3ca653',

    [theme.breakpoints.up('md')]: {
      width: '31%',
    },
  },
}))

const Rules: React.FC = () => {
  const classes = useStyles()

  return (
    <Card className={classes.root}>
      <CardHeader
        avatar={
          <AssignmentOutlined style={{ position: 'absolute', top: 109 }} />
        }
        title={
          <Typography
            variant="h5"
            style={{ fontWeight: 'bold', textAlign: 'center' }}
          >
            Regras
          </Typography>
        }
      />

      <Divider />

      <CardContent>
        <div>
          <p>
            Já diz o ditado: O combinado não sai caro, por isso nossa equipe
            preparou algumas regras sobre o funcionamento para que tudo ocorra
            sem confusões.
          </p>
          <p>
            Ao adquirir qualquer uma das nossas promoções você está de acordo
            com as nossas regras a baixo descritas.
          </p>

          <h3>Da responsabilidade sobre as promoções</h3>
          <p>
            A responsabilidade de cumprir o prometido tanto nos cupons quanto
            nos vouchers é do estabelecimento que os cadastrou, sendo a PASSFREE
            neste contexto apenas a intermédiaria. Mas não se preocupe nós
            sempre disponibilizamos os contatos dos responsáveis para que você
            possa entrar em contato caso necessário.
          </p>
        </div>
      </CardContent>
    </Card>
  )
}
export default Rules
