import React from 'react'
import { useHistory } from 'react-router-dom'
import { HowToReg } from '@material-ui/icons'
import {
  makeStyles,
  CardContent,
  Typography,
  Button,
  Card,
} from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: 5,
    marginBottom: 5,
    borderRadius: 0,
    borderBottomRightRadius: 10,
    borderBottomLeftRadius: 10,
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      marginLeft: 5,
      marginRight: 5,
    },
  },
  cardContentVoucher: {
    display: 'flex',
    flexDirection: 'column',
    padding: 10,
    margin: 0,
  },
  contentTitle: {
    fontWeight: 'bold',
    margin: 0,
  },
  cardActionVoucher: {
    display: 'flex',
    justifyContent: 'center',
  },
}))

const IncompleteClient: React.FC = () => {
  const classes = useStyles()
  const history = useHistory()

  return (
    <Card className={classes.root}>
      <CardContent className={classes.cardContentVoucher}>
        <Typography variant="h6" className={classes.contentTitle}>
          Atenção!
        </Typography>

        <p>
          Você ainda não completou o seu cadastro. Que tal finalizá-lo agora?
        </p>

        <Button
          variant="contained"
          endIcon={<HowToReg />}
          onClick={() => history.push('/perfil')}
          style={{
            backgroundColor: '#f4b400',
            borderColor: '#064326',
            color: '#000',
          }}
        >
          Completar agora
        </Button>
      </CardContent>
    </Card>
  )
}
export default IncompleteClient
