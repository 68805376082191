import React from 'react'
import { makeStyles } from '@material-ui/core'
import { LocalActivity, Loyalty } from '@material-ui/icons'
import { PromotionTypeEnum } from '../../generated/graphql'
import { COUPON_STYLE, VOUCHER_STYLE } from '../../constants'

const useStyles = makeStyles(() => ({
  typeIcon: {
    display: 'flex',
    justifyContent: 'end',
    color: '#397daf',
    position: 'relative',
    height: '0',
    top: '5px',
    left: '-5px',
  },
}))

type Props = {
  type: PromotionTypeEnum
}
const IconByTypePromotion: React.FC<Props> = ({ type }) => {
  const classes = useStyles()

  if (type === PromotionTypeEnum.Coupon) {
    return (
      <div className={classes.typeIcon} style={{ color: COUPON_STYLE.color }}>
        <LocalActivity />
      </div>
    )
  }
  return (
    <div className={classes.typeIcon} style={{ color: VOUCHER_STYLE.color }}>
      <Loyalty />
    </div>
  )
}
export default IconByTypePromotion
