import React from 'react'

import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  makeStyles,
  Typography,
} from '@material-ui/core'

import { AssignmentOutlined, HelpOutline, Info } from '@material-ui/icons'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles(() => ({
  root: {
    padding: 10,
    margin: 10,
  },
  centeredSection: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px 0',
  },
}))

const Information: React.FC = () => {
  const classes = useStyles()
  const history = useHistory()

  return (
    <Card className={classes.root}>
      <CardHeader
        avatar={<Info style={{ position: 'absolute', top: 109 }} />}
        title={
          <Typography
            variant="h6"
            style={{ fontWeight: 'bold', textAlign: 'center' }}
          >
            Informações
          </Typography>
        }
      />

      <Divider />

      <CardContent>
        <div className={classes.centeredSection}>
          <Typography
            variant="h6"
            style={{ fontWeight: 'bold', textAlign: 'center', color: '#888' }}
          >
            Precisa de ajuda?
          </Typography>

          <Button
            variant="outlined"
            onClick={() => history.push('/ajuda')}
            style={{
              color: '#064326',
              borderColor: '#064326',
              marginTop: 10,
            }}
            startIcon={<HelpOutline />}
          >
            Fale conoco
          </Button>
        </div>

        <Divider />

        <div className={classes.centeredSection}>
          <Typography
            variant="h6"
            style={{ fontWeight: 'bold', textAlign: 'center', color: '#888' }}
          >
            Quer saber mais sobre a PASSFREE?
          </Typography>

          <Button
            variant="outlined"
            onClick={() => history.push('/quem-somos')}
            style={{
              color: '#064326',
              borderColor: '#064326',
              marginTop: 10,
            }}
            startIcon={<Info />}
          >
            Veja mais aqui
          </Button>
        </div>

        <Divider />

        <div className={classes.centeredSection}>
          <Typography
            variant="h6"
            style={{ fontWeight: 'bold', textAlign: 'center', color: '#888' }}
          >
            Já conhece as nossas regras?
          </Typography>

          <Button
            variant="outlined"
            onClick={() => history.push('/regras')}
            style={{
              color: '#064326',
              borderColor: '#064326',
              marginTop: 10,
            }}
            startIcon={<AssignmentOutlined />}
          >
            Conheça elas aqui
          </Button>
        </div>

        <Divider />

        <div className={classes.centeredSection}>
          <Typography
            variant="h6"
            style={{ fontWeight: 'bold', textAlign: 'center', color: '#888' }}
          >
            Está com dúvidas sobre os Termos e condições?
          </Typography>

          <Button
            variant="outlined"
            onClick={() => history.push('/termos-e-condicoes')}
            style={{
              color: '#064326',
              borderColor: '#064326',
              marginTop: 10,
            }}
          >
            Leia eles aqui
          </Button>
        </div>
      </CardContent>
    </Card>
  )
}
export default Information
