import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { InputIcon, OutputIcon, ShieldIcon } from '../../icons'
import { PointTransactionOperationEnum } from '../../generated/graphql'

interface IProps {
  operation: PointTransactionOperationEnum
}

const useStyles = makeStyles(() => ({
  iconBox: {
    backgroundColor: '#cfd8dc',
    width: '32px',
    height: '32px',
    borderRadius: '50%',
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    '&.Deposit': {
      backgroundColor: '#b3e5fc',
    },
    '&.Withdraw': {
      backgroundColor: '#ffcdd2',
    },
  },
  icon: {
    height: '24px',
    width: '24px',
    color: '#000',

    '&.Deposit': {
      color: '#0277bd',
    },
    '&.Withdraw': {
      color: '#c62828',
    },
  },
}))

const PointTransactionTypeIcon: React.FC<IProps> = ({ operation }) => {
  const classes = useStyles()

  switch (operation) {
    case PointTransactionOperationEnum.Deposit:
      return (
        <div
          className={`${classes.iconBox} ${PointTransactionOperationEnum.Deposit}`}
        >
          <InputIcon
            className={`${classes.icon} ${PointTransactionOperationEnum.Deposit}`}
          />
        </div>
      )
    case PointTransactionOperationEnum.Withdraw:
      return (
        <div
          className={`${classes.iconBox} ${PointTransactionOperationEnum.Withdraw}`}
        >
          <OutputIcon
            className={`${classes.icon} ${PointTransactionOperationEnum.Withdraw}`}
          />
        </div>
      )

    default:
      return (
        <div className={`${classes.iconBox} any`}>
          <ShieldIcon className={classes.icon} />
          {operation}
        </div>
      )
  }
}

export default PointTransactionTypeIcon
