import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Card, CardContent, CardHeader, Typography } from '@material-ui/core'

import { AppPromoCode, PromoCodeStatusEnum } from '../../generated/graphql'
import LocalSticker from './LocalSticker'

const useStyles = makeStyles(() => ({
  sub: {
    fontSize: '1em',
    lineHeight: '1em',
  },
  codeSection: {
    backgroundColor: '#f4b400',
    padding: 4,

    '&:last-child': {
      paddingBottom: 4,
    },
  },
  dashedCode: {
    border: '1px dashed #fff',
    padding: 0,
    textAlign: 'center',

    '& h4': {
      margin: 0,
    },
  },
  code: {
    margin: 0,
    textAlign: 'center',
    fontSize: '1.8em',
    lineHeight: '1.2em',
  },
  cardHeader: {
    padding: 8,
  },
}))

type Props = {
  promoCode: AppPromoCode
}
const MyClosedPromotionCard: React.FC<Props> = ({ promoCode }) => {
  const classes = useStyles()

  const contentByStatus = () => {
    switch (promoCode.status) {
      case PromoCodeStatusEnum.Canceled:
        return (
          <CardContent className={classes.codeSection}>
            <div className={classes.dashedCode}>
              <h4>Código cancelado</h4>
            </div>
          </CardContent>
        )
      case PromoCodeStatusEnum.Expired:
        return (
          <CardContent className={classes.codeSection}>
            <div className={classes.dashedCode}>
              <h4>Código expirado</h4>
              <small>
                Este código expirou em <b>{promoCode.expireIn.format}</b>
              </small>
            </div>
          </CardContent>
        )
      case PromoCodeStatusEnum.Used:
        return (
          <CardContent className={classes.codeSection}>
            <div className={classes.dashedCode}>
              <h4>Código usado</h4>
              <small>
                Este código foi usado em <b>{promoCode.usedAt?.format}</b>
              </small>
            </div>
          </CardContent>
        )
      default:
        return (
          <CardContent className={`${classes.codeSection} ${promoCode.status}`}>
            <div className={classes.dashedCode}>
              <h4>{promoCode.status}</h4>
            </div>
          </CardContent>
        )
    }
  }

  return (
    <>
      <Card>
        <CardHeader
          avatar={
            promoCode.local ? (
              <LocalSticker
                local={promoCode.local}
                promoType={promoCode.promotionType}
              />
            ) : (
              <span>Sem local</span>
            )
          }
          title={promoCode.description}
          subheader={
            <Typography variant="caption" style={{ lineHeight: '0.8em' }}>
              {promoCode.local?.name} <br />
            </Typography>
          }
          classes={{ content: classes.sub, root: classes.cardHeader }}
        />
        {contentByStatus()}
      </Card>
    </>
  )
}
export default MyClosedPromotionCard
