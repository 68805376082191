import React from 'react'

import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  makeStyles,
  Typography,
} from '@material-ui/core'

import {
  Facebook,
  Info,
  Instagram,
  LocalActivityOutlined,
  Loop,
  Mail,
  Phone,
  WhatsApp,
} from '@material-ui/icons'
import Logo from '../image/logo.png'
import { CONTACTS } from '../constants'

const useStyles = makeStyles(theme => ({
  root: {
    padding: 10,
    margin: 10,
  },

  logo: {
    width: '100%',
    maxWidth: '300px',
  },
  featuresBox: {
    flexDirection: 'column',
    display: 'flex',

    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      justifyContent: 'space-around',
    },
  },
  feature: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    background: '#a3bdd6',
    padding: '12px',

    [theme.breakpoints.up('md')]: {
      width: '48%',
    },
  },
  featureTitle: {
    margin: 0,
  },
  contactItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    whiteSpace: 'normal',
    wordBreak: 'break-all',
    textDecoration: 'none',
    color: '#3ca653',

    [theme.breakpoints.up('md')]: {
      width: '31%',
    },
  },
}))

const About: React.FC = () => {
  const classes = useStyles()

  return (
    <Card className={classes.root}>
      <CardHeader
        avatar={<Info style={{ position: 'absolute', top: 109 }} />}
        title={
          <Typography
            variant="h5"
            style={{ fontWeight: 'bold', textAlign: 'center' }}
          >
            Sobre a PASSFREE
          </Typography>
        }
      />

      <Divider />

      <CardContent>
        <div>
          <div
            style={{
              // background: '#c8d6e5',
              // width: 100,
              // height: 100,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              // borderRadius: 100,
            }}
          >
            <img className={classes.logo} src={Logo} alt="PassFree" />
          </div>

          <p>
            Nós somos a PassFree, a mais nova forma de você ganhar cashback e
            descontos exclusivos. Cadastre-se e consulte as empresas
            participantes.
          </p>
          <p>
            Acumule nossas PassCoins e troque pela promoção que achar mais
            interessante, seja ela desconto ou cashback.
          </p>

          <div className={classes.featuresBox}>
            <div className={classes.feature}>
              <div>
                <Loop style={{ fontSize: '40px' }} />
              </div>
              <h4 className={classes.featureTitle}>Descontos ou Cashback</h4>
              <p>Troque suas PassCoins por descontos ou cashbacks.</p>
            </div>
            <div className={classes.feature}>
              <div>
                <LocalActivityOutlined style={{ fontSize: '40px' }} />
              </div>
              <h4 className={classes.featureTitle}>Promoções exclusivas</h4>
              <p>Participe de promoções exclusivas.</p>
            </div>
          </div>

          <h3>Fale conosco</h3>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
          >
            {CONTACTS.phone && (
              <a
                href={`tel:${CONTACTS.phone}`}
                target="_blank"
                rel="noreferrer"
                className={classes.contactItem}
              >
                <div>
                  <Phone />
                </div>
                <p>{CONTACTS.phone}</p>
              </a>
            )}
            {CONTACTS.email && (
              <a
                href={`mailto:${CONTACTS.email}`}
                target="_blank"
                rel="noreferrer"
                className={classes.contactItem}
              >
                <div>
                  <Mail />
                </div>
                <p>{CONTACTS.email}</p>
              </a>
            )}
            {CONTACTS.whatsapp && (
              <a
                href={`https://wa.me/${CONTACTS.whatsapp}`}
                target="_blank"
                rel="noreferrer"
                className={classes.contactItem}
              >
                <div>
                  <WhatsApp />
                </div>
                <p>{CONTACTS.whatsappFormated}</p>
              </a>
            )}
          </div>

          <h3>Nos acompanhe nas redes sociais</h3>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-around',
            }}
          >
            {CONTACTS.facebookUrl && (
              <a
                href={CONTACTS.facebookUrl}
                target="_blank"
                rel="noreferrer"
                className={classes.contactItem}
              >
                <div>
                  <Facebook />
                </div>
              </a>
            )}
            {CONTACTS.instagramUrl && (
              <a
                href={CONTACTS.instagramUrl}
                target="_blank"
                rel="noreferrer"
                className={classes.contactItem}
              >
                <div>
                  <Instagram />
                </div>
              </a>
            )}
          </div>
        </div>
      </CardContent>
    </Card>
  )
}
export default About
