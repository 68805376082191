import React, { useEffect, useState } from 'react'
import GoogleMapReact from 'google-map-react'
import MapMarker from './MapMarker'
import { Config } from '../../config/config'
import { AppLocal } from '../../generated/graphql'

type Props = {
  items: AppLocal[]
  height?: string
}

const MapContainer: React.FC<Props> = ({ items, height }) => {
  const defaultProps = {
    center: {
      lat: -24.724033, // toledo
      lng: -53.7473997,
    },
    zoom: 13,
  }
  const [currentLocation] = useState(defaultProps.center)
  // const [currentLocation, setCurrentLocation] = useState(defaultProps.center)
  const [currentCenter, setCurrentCenter] = useState(defaultProps.center)

  // useEffect(() => {
  //   // if (navigator && navigator.geolocation) {
  //   //   navigator.geolocation.getCurrentPosition(pos => {
  //   //     const { coords } = pos
  //   //     const locat = {
  //   //       lat: coords.latitude,
  //   //       lng: coords.longitude,
  //   //     }
  //   //     setCurrentLocation(locat)
  //   //     setCurrentCenter(locat)
  //   //   })
  //   // }
  // }, [])
  useEffect(() => {
    // if (navigator && navigator.geolocation) {
    //   navigator.geolocation.getCurrentPosition(pos => {
    //     const { coords } = pos
    //     const locat = {
    //       lat: coords.latitude,
    //       lng: coords.longitude,
    //     }
    //     setCurrentLocation(locat)
    //     setCurrentCenter(locat)
    //   })
    // }
    if (items.length > 0) {
      const center = {
        lat: items[0].address?.location?.coordinates[0] || currentLocation.lat,
        lng: items[0].address?.location?.coordinates[1] || currentLocation.lng,
      }
      setCurrentCenter(center)
    }
  }, [items, currentLocation])

  return (
    <div style={{ height: height || '35vh', width: '100%' }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: Config.googleMapsApiKey }}
        // defaultCenter={currentLocation}
        defaultZoom={defaultProps.zoom}
        center={currentCenter}
      >
        {/*<MapMarker*/}
        {/*  lat={currentLocation.lat}*/}
        {/*  lng={currentLocation.lng}*/}
        {/*  text="Você está aqui"*/}
        {/*/>*/}

        {items.map((local: AppLocal) => (
          <MapMarker
            key={local.slug}
            lat={local.address?.location?.coordinates[0] || currentLocation.lat}
            lng={local.address?.location?.coordinates[1] || currentLocation.lng}
            text={local.name}
            local={local}
          />
        ))}
      </GoogleMapReact>
    </div>
  )
}
export default MapContainer
