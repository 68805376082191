/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useContext, useEffect, useState } from 'react'
import { useKeycloak } from '@react-keycloak/web'
import { useHistory } from 'react-router-dom'
import * as Yup from 'yup'
import InputMask from 'react-input-mask'

import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  makeStyles,
  TextField,
  Typography,
} from '@material-ui/core'

import { Formik, FormikValues } from 'formik'

import { EditOutlined } from '@material-ui/icons'
import { useLazyQuery, useMutation } from '@apollo/client'
import { Client, UserKeycloak } from '../../generated/graphql'
import { GET_USER_ME, MY_CLIENT, UPDATE_MY_CLIENT } from '../../gql'
import { ClientContext } from '../../providers/ClientProvider'
import { clearRef, getRef } from '../../utils/reference'
import { Loading } from '../../components'

const useStyles = makeStyles(theme => ({
  root: {
    padding: 10,
    margin: 10,
    marginBottom: 20,
  },
  invite: {
    padding: '10px 0px',
    margin: 10,
    marginBottom: 100,
  },
  cardInvite: {
    padding: 0,
    border: '1px dashed #3ca653',
    background: '#3ca6532b',
  },
  inviteHeader: {},
  cardContent: {},
  avatar: {
    width: 100,
    height: 100,
    borderRadius: 80,
    fontSize: 35,
  },
  infoUser: {
    display: 'flex',
    justifyContent: 'center',
    padding: 8,
    backgroundColor: '#f1f2f6',
    borderRadius: 5,
  },
  rowAdapt: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingTop: 25,
    gap: '10px',
  },
  textField: {
    width: '100%',
    marginTop: 10,
    [theme.breakpoints.down('sm')]: {
      marginRight: 0,
    },
    margin: 2,
  },
  textFieldSlug: {},
  textFieldPhone: {
    width: '100%',
    marginTop: 10,
    [theme.breakpoints.down('sm')]: {
      marginRight: 0,
    },
    margin: 2,
    padding: '18.5px 14px',
    borderRadius: '4px',
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: '1rem',
    borderColor: 'rgb(118 118 118 / 38%)',
    borderWidth: 'thin',
  },
  labelPhone: {
    transform: 'translate(5px, 22px) scale(0.85)',
    width: '71px',
    padding: '0 10px',
    background: '#fff',
    color: 'rgba(0, 0, 0, 0.54)',
  },
  buttonEdit: {
    margin: theme.spacing(1),
  },
  hasReferrer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  linkBox: {
    wordBreak: 'break-all',
    padding: '8px 0 8px 8px',
    border: '1px dashed #3ca653',
    fontSize: 12,
    margin: '0 0 10px 0',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#fff',
  },
  cancelBtn: {
    maxWidth: 400,
    color: '#F57C00',
    borderColor: '#F57C00',
  },
  saveBtn: {
    maxWidth: 400,
    backgroundColor: '#3ca653',
  },
}))

const schema = Yup.object().shape({
  name: Yup.string().required('É necessário informar o nome'),
  phone: Yup.string().required('É necessário informar seu Telefone'),
})

const EditProfile: React.FC = () => {
  const classes = useStyles()
  const { keycloak } = useKeycloak()
  const history = useHistory()
  const { setClient } = useContext(ClientContext)

  const [loadUser, { data: userData, loading: loadingUser }] = useLazyQuery<{
    userMe: UserKeycloak
  }>(GET_USER_ME)

  const [
    loadClient,
    { data: clientData, loading: loadingClient },
  ] = useLazyQuery<{
    myClient: Client
  }>(MY_CLIENT)

  const [updateClient, { loading: loadingUpdateClient }] = useMutation(
    UPDATE_MY_CLIENT,
  )

  const getUserName = () => {
    if (clientData && clientData.myClient.name) {
      return clientData.myClient.name
    }

    if (userData && userData.userMe.name) {
      return userData.userMe.name
    }

    return ''
  }

  useEffect(() => {
    if (keycloak.authenticated) {
      loadUser()
      loadClient()
    } else {
      history.push('/login')
    }
  }, [keycloak.authenticated, loadUser, loadClient, history])

  useEffect(() => {
    if (clientData) {
      setClient(clientData.myClient)
    }
  }, [clientData, setClient])

  const updateClientInfos = (values: FormikValues) => {
    const ref = getRef()
    const dataReferrer = ref ? { ...ref, slug: undefined } : null
    const dataUpdate = { ...values, referrer: dataReferrer }

    updateClient({
      variables: {
        input: dataUpdate,
      },
    }).then(r => {
      loadClient()
      clearRef()
    })
  }

  if (loadingClient || loadingUser || loadingUpdateClient) {
    return <Loading />
  }

  return (
    <>
      <Card className={classes.root}>
        <CardHeader
          avatar={<EditOutlined style={{ position: 'absolute', top: 109 }} />}
          title={
            <Typography
              variant="h5"
              style={{ fontWeight: 'bold', textAlign: 'center' }}
            >
              Meu Perfil
            </Typography>
          }
        />

        <Divider />

        <CardContent className={classes.cardContent}>
          <div className={classes.infoUser}>
            <div
              style={{
                background: '#c8d6e5',
                width: 100,
                height: 100,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: 100,
              }}
            >
              <Typography variant="h4">
                {userData ? userData.userMe.name?.charAt(0) : 'U'}
              </Typography>
            </div>
          </div>
          <Formik
            initialValues={{
              name: getUserName(),
              phone: clientData ? clientData.myClient.phone : '',
            }}
            enableReinitialize
            validateOnChange={false}
            validateOnBlur={false}
            onSubmit={updateClientInfos}
            validationSchema={schema}
          >
            {({ values, handleChange, errors, handleSubmit: onSubmit }) => (
              <div>
                <h2>Editar informações do perfil</h2>
                <div className={classes.rowAdapt}>
                  <TextField
                    label="Nome completo"
                    placeholder=""
                    variant="outlined"
                    className={classes.textField}
                    onChange={handleChange}
                    value={values.name}
                    error={!!errors.name}
                    name="name"
                  />
                </div>

                <div className={classes.column}>
                  <span className={classes.labelPhone}>Celular</span>
                  <InputMask
                    mask="99 9 9999 9999"
                    name="phone"
                    type="tel"
                    value={values.phone}
                    onChange={handleChange}
                    id="phone"
                    className={classes.textFieldPhone}
                  />
                </div>

                <div className={classes.actions}>
                  <Button
                    color="primary"
                    variant="outlined"
                    style={{ maxWidth: 400, marginRight: 5 }}
                    onClick={() => history.push('/')}
                    className={classes.cancelBtn}
                  >
                    Cancelar
                  </Button>
                  <Button
                    color="secondary"
                    variant="contained"
                    style={{ maxWidth: 400 }}
                    onClick={() => onSubmit()}
                    className={classes.saveBtn}
                  >
                    Salvar Alterações
                  </Button>
                </div>
              </div>
            )}
          </Formik>
        </CardContent>
      </Card>
    </>
  )
}

export default EditProfile
