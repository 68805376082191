/* eslint-disable no-console */
import React from 'react'
import ReactDOM from 'react-dom'

import { Provider } from 'react-redux'

import { ReactKeycloakProvider } from '@react-keycloak/web'
import { ToastContainer } from 'react-toastify'
import store from './store'

import Proxy from './components/Proxy/Proxy'

import * as serviceWorkerRegistration from './serviceWorkerRegistration'

import './index.css'
import 'react-toastify/dist/ReactToastify.css'
import { keycloak, keycloakProviderInitConfig } from './keycloak'
import ApolloConnectProvider from './config/ApolloConnect'
import AppRouter from './routes'

const eventLogger = (event: unknown, error: unknown) => {
  console.info('onKeycloakEvent', event, error)
}

const tokenLogger = (tokens: unknown) => {
  console.info('onKeycloakTokens', tokens)
}

ReactDOM.render(
  <React.StrictMode>
    <ReactKeycloakProvider
      authClient={keycloak}
      onEvent={eventLogger}
      onTokens={tokenLogger}
      initOptions={keycloakProviderInitConfig}
    >
      <ApolloConnectProvider>
        <Provider store={store}>
          <Proxy />

          <AppRouter />
          <ToastContainer autoClose={1500} />
        </Provider>
      </ApolloConnectProvider>
    </ReactKeycloakProvider>
  </React.StrictMode>,
  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register()
