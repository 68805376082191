export const VOUCHER_STYLE = {
  color: '#0277BD',
  colorLight: '#90CAF9',
  classBg: 'bg-blue-300',
  classText: 'text-blue-700',
}

export const COUPON_STYLE = {
  color: '#FBC02D',
  colorLight: '#FFF59D',
  classBg: 'bg-yellow-200',
  classText: 'text-yellow-600',
}

export const CONTACTS = {
  phone: '45 99924-0043',
  email: 'suporte@passfree.com.br',
  whatsapp: '45999240043',
  whatsappFormated: '(45) 99924 0043',
  facebookUrl: 'https://www.facebook.com/PassFreeBrasil',
  instagramUrl: 'https://www.instagram.com/passfree.com.br',
}
