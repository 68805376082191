import React, { useContext } from 'react'
import { useQuery } from '@apollo/client'
import { makeStyles } from '@material-ui/core/styles'
import { Box, CardHeader, Typography } from '@material-ui/core'
import { LocalActivityOutlined, MoodBadOutlined } from '@material-ui/icons'
import { Loading } from '../components'
import { ClientContext } from '../providers/ClientProvider'
import { AppMyPromoCodesResponse, AppPromoCode } from '../generated/graphql'
import EmptyClient from '../components/Promotion/EmptyClient'
import MyPromotionCard from '../components/OffersCard/MyPromotionCard'
import { APP_MY_PROMO_CODES } from '../gql'
import MyClosedPromotionCard from '../components/OffersCard/MyClosedPromotionCard'

const useStyles = makeStyles(() => ({
  root: {
    margin: 5,
    padding: 5,
  },
  clean: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 10,
    marginBottom: 10,
  },
  icon: {
    width: 80,
    height: 80,
  },
  warningText: {
    fontWeight: 'bold',
    paddingTop: 10,
    textAlign: 'center',
  },
  avatar: {
    width: 85,
    height: 85,
    background: '#f1f2f6',
    borderWidth: 0.1,
    borderRadius: 10,
  },
  history: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 5,
    height: 120,
    marginTop: 5,
  },
  infoHistory: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 10,
  },
  titleHistory: {
    fontSize: 18,
    fontWeight: 'bold',
  },
  count: {
    fontSize: '14px',
  },
  cardContent: {
    lineHeight: '0.6em!important',
  },
}))

const MyPromotions: React.FC = () => {
  const classes = useStyles()
  const { client } = useContext(ClientContext)

  const { data, loading } = useQuery<{
    appMyPromoCodes: AppMyPromoCodesResponse
  }>(APP_MY_PROMO_CODES)

  const getOpenedCodes = (): AppPromoCode[] => {
    if (data?.appMyPromoCodes.openedCodes) {
      return data?.appMyPromoCodes.openedCodes
    }
    return []
  }
  const getClosedCodes = (): AppPromoCode[] => {
    if (data?.appMyPromoCodes.closedCodes) {
      return data?.appMyPromoCodes.closedCodes
    }
    return []
  }

  if (!client) {
    return <EmptyClient />
  }

  if (loading) {
    return <Loading />
  }

  return (
    <div className={classes.root}>
      <Box style={{ marginBottom: 10, background: 'white' }} boxShadow={0}>
        <CardHeader
          avatar={<LocalActivityOutlined />}
          title={
            <Typography
              variant="caption"
              style={{ fontWeight: 'bold', textAlign: 'center' }}
            >
              Minhas promoções
            </Typography>
          }
        />
      </Box>

      {getOpenedCodes().map(item => (
        <div key={item.code}>
          <MyPromotionCard promoCode={item} />
        </div>
      ))}

      {getOpenedCodes().length === 0 && (
        <Box
          style={{
            marginBottom: 30,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            padding: 8,
          }}
          boxShadow={0}
        >
          <MoodBadOutlined />

          <Typography
            variant="caption"
            style={{ fontWeight: 'bold', textAlign: 'center' }}
          >
            Sem promoções.
          </Typography>

          <Typography
            variant="caption"
            style={{ lineHeight: '0.8em', color: '#878787' }}
          >
            <br />
            <small>Você não tem nenhuma promoção válida no momento. </small>
          </Typography>
        </Box>
      )}

      <Box
        style={{ marginBottom: 10, background: 'white', marginTop: 45 }}
        boxShadow={0}
      >
        <CardHeader
          style={{ padding: 8 }}
          avatar={<LocalActivityOutlined />}
          title={
            <Typography
              variant="caption"
              style={{ fontWeight: 'bold', textAlign: 'center' }}
            >
              Promoções concluídas
            </Typography>
          }
          subheader={
            <Typography
              variant="caption"
              style={{ lineHeight: '0.8em', color: '#878787' }}
            >
              <br />
              <small>
                Promoções concluídas são todas as suas promoções usadas,
                expiradas ou canceladas.
              </small>
            </Typography>
          }
          classes={{ content: classes.cardContent }}
        />
      </Box>

      {getClosedCodes().map(item => (
        <div key={item.code}>
          <MyClosedPromotionCard promoCode={item} />
        </div>
      ))}

      {getClosedCodes().length === 0 && (
        <Box
          style={{
            marginBottom: 30,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            padding: 8,
          }}
          boxShadow={0}
        >
          <Typography
            variant="caption"
            style={{ fontWeight: 'bold', textAlign: 'center' }}
          >
            Sem promoções concluídas.
          </Typography>

          <Typography
            variant="caption"
            style={{ lineHeight: '0.8em', color: '#878787' }}
          >
            <br />
            <small>Você não tem nenhuma promoção concluída no momento. </small>
          </Typography>
        </Box>
      )}
    </div>
  )
}
export default MyPromotions
