/* eslint-disable react/require-default-props */
import React from 'react'

import {
  Button,
  Card,
  CardActions,
  CardContent,
  makeStyles,
  Typography,
} from '@material-ui/core'
import ArrowForwardOutlinedIcon from '@material-ui/icons/ArrowForwardOutlined'

import Expiration from './Expiration'
import useCountdown from './UseCountdown'
import { PromotionTypeEnum } from '../../generated/graphql'
import PromotionCodeDialog from './PromotionCodeDialog'

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: 5,
    marginBottom: 5,
    borderRadius: 0,
    borderBottomRightRadius: 10,
    borderBottomLeftRadius: 10,
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      marginLeft: 5,
      marginRight: 5,
    },
  },
  cardContentVoucherPadding: {
    display: 'flex',
    flexDirection: 'column',
    padding: 0,
  },
  buttonPrimary: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    borderColor: '#f39c12',
    color: '#f39c12',
    borderWidth: 2,
    textTransform: 'none',
  },
  iconArrow: {
    position: 'absolute',
    right: 10,
    display: 'flex',
    alignItems: 'center',
  },
}))

type Props = {
  promoType: PromotionTypeEnum
  voucherCode: any
  expirateAt: any
  expirateAtString: string
}
const PromoCodeCard: React.FC<Props> = ({
  voucherCode,
  expirateAt,
  expirateAtString,
  promoType,
}) => {
  const classes = useStyles()
  const [days, hours, minutes, seconds] = useCountdown(expirateAt)

  const [open, setOpen] = React.useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const isExpired = () => {
    return days + hours + minutes + seconds <= 0
  }

  return (
    <Card className={classes.root}>
      <CardContent className={classes.cardContentVoucherPadding}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            margin: 0,
            width: '100%',
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              color: 'white',
              background: 'rgba(6, 67, 38, 0.9)',
              marginLeft: 0,
            }}
          >
            <Typography
              variant="h6"
              style={{
                fontWeight: 'bold',
                textAlign: 'center',
                paddingLeft: 10,
              }}
            >
              {promoType === PromotionTypeEnum.Voucher ? 'Voucher' : 'Cupom'}{' '}
              gerado
            </Typography>
            <div
              style={{
                display: 'flex',
                alignContent: 'center',
                alignItems: 'center',
                justifyContent: 'center',
                padding: 15,
              }}
            >
              <Typography
                variant="h6"
                style={{
                  position: 'absolute',
                  textAlign: 'center',
                  fontWeight: 'bold',
                }}
              >
                {voucherCode}
              </Typography>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="187.352"
                height="94.821"
              >
                <rect width="100%" height="100%" fill="none" />
                <g fill="#ffffff47">
                  <path
                    xmlns="http://www.w3.org/2000/svg"
                    d="M178.716 45.747c1.504 0 2.556-.892 2.706-1.896V19.529c0-1.115-1.202-1.896-2.706-2.008h-7.217L168.34 1.567c-.15-1.115-1.503-1.785-3.007-1.673l-40.747 4.462h-.15L5.351 17.633s-2.256.334-2.256 1.896v24.21c0 1.116 1.203 1.897 2.556 2.008 6.616 0 11.879 3.905 11.879 8.814s-5.263 8.814-11.879 8.814c-1.503 0-2.556.893-2.706 1.897v24.21c0 1.115 1.203 1.896 2.706 2.008h172.914c1.503 0 2.556-.893 2.706-2.009V65.383c0-1.115-1.203-1.896-2.556-2.008-6.616 0-11.878-3.905-11.878-8.814s5.262-8.814 11.878-8.814zM122.632 8.596l1.052 5.244c.15 1.004 1.354 1.673 2.557 1.673h.45c1.504-.112 2.406-1.116 2.256-2.231l-1.053-5.244 35.636-3.905 2.706 13.5H41.287zm53.528 58.572v20.528h-38.943v-5.355c0-1.115-1.203-2.008-2.707-2.008s-2.706.893-2.706 2.008v5.355H8.509V67.168c9.322-1.115 15.788-7.586 14.284-14.503-1.203-5.467-6.916-9.818-14.284-10.6V21.539h123.445v5.243c0 1.116 1.203 2.008 2.707 2.008s2.706-.892 2.706-2.008v-5.355h38.943v20.528c-9.322 1.116-15.788 7.587-14.284 14.504.902 5.578 6.616 9.818 14.134 10.71z"
                    data-original="#000000"
                  />
                  <path
                    xmlns="http://www.w3.org/2000/svg"
                    d="M134.51 57.574c-1.503 0-2.556.892-2.706 1.896v12.942c0 1.116 1.203 2.008 2.706 2.008s2.707-.892 2.707-2.008v-12.83c0-1.116-1.203-2.008-2.707-2.008zM134.51 34.814c-1.503 0-2.556.893-2.706 1.897v12.941c0 1.116 1.203 2.009 2.706 2.009s2.707-.893 2.707-2.009V36.711c0-1.004-1.203-1.897-2.707-1.897z"
                    data-original="#000000"
                  />
                </g>
              </svg>
            </div>
          </div>
        </div>
      </CardContent>

      <Expiration
        days={days}
        hours={hours}
        minutes={minutes}
        seconds={seconds}
      />

      <CardActions
        style={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Button
          className={classes.buttonPrimary}
          onClick={handleClickOpen}
          variant="outlined"
          disabled={isExpired()}
          style={{
            width: '100%',
          }}
        >
          Ver meu{' '}
          {promoType === PromotionTypeEnum.Voucher ? 'voucher' : 'cupom'}
          <ArrowForwardOutlinedIcon
            fontSize="small"
            className={classes.iconArrow}
          />
        </Button>
      </CardActions>

      <PromotionCodeDialog
        open={open}
        promoType={promoType}
        expirateAtString={expirateAtString}
        code={voucherCode}
        handleClose={handleClose}
      />
    </Card>
  )
}
export default PromoCodeCard
