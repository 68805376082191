import React from 'react'
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  makeStyles,
  Typography,
} from '@material-ui/core'
import { Info } from '@material-ui/icons'

const useStyles = makeStyles(theme => ({
  root: {
    padding: 10,
    margin: 10,
  },

  logo: {
    width: '100%',
    maxWidth: '300px',
  },
  featuresBox: {
    flexDirection: 'column',
    display: 'flex',

    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      justifyContent: 'space-around',
    },
  },
  feature: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    background: '#a3bdd6',
    padding: '12px',

    [theme.breakpoints.up('md')]: {
      width: '48%',
    },
  },
  featureTitle: {
    margin: 0,
  },
  contactItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    whiteSpace: 'normal',
    wordBreak: 'break-all',
    textDecoration: 'none',
    color: '#3ca653',

    [theme.breakpoints.up('md')]: {
      width: '31%',
    },
  },
}))

const TermsAndConditions: React.FC = () => {
  const classes = useStyles()

  return (
    <Card className={classes.root}>
      <CardHeader
        avatar={<Info style={{ position: 'absolute', top: 109 }} />}
        title={
          <Typography
            variant="h5"
            style={{ fontWeight: 'bold', textAlign: 'center' }}
          >
            Termos de Uso e Condições Gerais
          </Typography>
        }
      />

      <Divider />

      <CardContent>
        <div>
          <p>
            Nós da <b>PASSFREE</b> pensando na agilidade e conforto de nossos
            usuários trabalhamos para garantir total sigilo e segurança nos
            dados aqui armazenados, sempre preservando a ética e a transparência
            nos serviços prestados. Nosso compromisso é com você!
          </p>
          <p>
            Os Termos de Uso a seguir regem todos os serviços disponíveis
            através do site
            <a target="_blank" href="https://passfree.com.br/" rel="noreferrer">
              www.passfree.com.br
            </a>
            , e de todas as suas páginas e sub domínios, incluindo redes
            sociais, Cartão de Fidelidade e Cashback.
          </p>

          <p>
            Pelo presente instrumento aqui doravante designado USUÁRIO, celebra
            contrato com a EMPRESA PARCEIRA e Usuária dos serviços CARTÃO
            FIDELIDADE E CASHBACK e a D.K.Junior Eventos ME, pessoa jurídica de
            direito privado, inscrita no CNPJ de número 02.439.209/0001-81 com
            sede na Rua Aracajú nº 2359, Toledo/Pr, doravante designada{' '}
            <b>PASSFREE</b> ; considerando esta, ter poderes legítimos para
            disponibilizar conveniência de compras on-line no que se refere aos
            anúncios divulgados pela EMPRESA PARCEIRA no site e APP{' '}
            <a target="_blank" rel="noreferrer" href="https://passfree.com.br/">
              www.passfree.com.br
            </a>{' '}
            , devidamente contratada pelos respectivos representantes legais e
            administradores da EMPRESA PARCEIRA, têm as partes justo e
            contratado, o que a seguir se convenciona por meio desta plataforma
            (&apos;&apos;Ofertante&apos;&apos;) fornece acesso ao Programa de
            Fidelidade e Cashback da Ofertante(EMPRESA PARCEIRA), que permite ao
            Usuário (&apos;&apos;Usuário&apos;&apos;) por meios proposto
            mediante adoção das cláusulas e condições dispostas, as quais
            mutuamente aceitam e outorgam;
          </p>

          <p>
            Acumular pontos em compras, a fim de trocá-los por recompensas
            (&apos;&apos;Produtos e serviços&apos;&apos;) e ou Cashback
            oferecidas pela Ofertante;
          </p>

          <p>
            Acesso a descontos e ofertas (&apos;&apos;Promoções&apos;&apos;)
            criadas pela Ofertante, de forma personalizada, que não
            necessariamente estarão vinculadas ao atingimento do número de
            pontuação em todas as compras;
          </p>

          <p>
            Pode o &apos;&apos;USUÁRIO&apos;&apos; trocar e doar seus pontos e
            Cashback com outros &apos;&apos;USUÁRIOS&apos;&apos; se assim o
            quiser;
          </p>

          <p>
            Compras de produtos nas lojas físicas e on-line parceiras do
            PASSFREE;
          </p>

          <p>
            Comprar, ganhar e trocar as suas moedas virtuais
            &apos;&apos;PASSFREE&apos;&apos;
          </p>

          <h4>1- CONTA DIGITAL</h4>

          <p>
            O <b>USUÁRIO</b> pode criar sua conta digital sendo maior de 18 anos
            completos no momento do cadastro no caso de pessoa física com CPF,
            no caso de Pessoa Jurídica somente será aceita CNPJ valido e ativo
            no momento do cadastro.
          </p>

          <p>
            Ao aderir ao Programa de Fidelidade e Cashback, o Usuário deve criar
            uma conta digital no APP da PASSFREE por meio desta plataforma,
            podendo fazê-lo mediante as contas de suas redes sociais, E-mail e
            ou Telefone onde o Usuário autoriza que suas informações cadastrais
            sejam coletadas pela Ofertante, ou preenchendo manualmente os campos
            requeridos para aderir ao Programa de Fidelidade e ou Cashback, cuja
            efetivação do cadastramento dependerá de confirmação do Usuário, via
            e-mail que lhe será enviado no momento do cadastramento; ou por meio
            de solicitação de envio SMS (short message service) feita pelo
            Usuário ao Estabelecimento Participante, que será encaminhada ao
            Usuário e conterá instruções e adequadas afim de finalizar o
            cadastro tudo regido conforme a Lei nº 13.709/18 (
            <b>Lei de Proteção de Dados</b>– LGPD) que regulamenta a política de
            proteção de dados pessoais e privacidade a PASSFREE zela pela
            privacidade e a segurança das informações de usuários e clientes
            adotando segurança e sites confiáveis a seus usuários.
          </p>

          <p>
            Será permitido apenas uma conta por <b>USUÁRIO</b> com e-mail e
            01(um) número de telefone pode ser registrado para o uso de 1 (uma)
            conta junto ao Programa de Fidelidade e ou Cashback. Se 1 (um)
            número de telefone estiver vinculado a mais de 1 (uma) conta no
            Programa de Fidelidade, este fato será considerado pela Ofertante
            como uma atividade suspeita e será investigada para a aplicação das
            medidas e ou penalidades cabíveis.
          </p>

          <p>
            A PASSFREE reserva-se ao direito de suspender ou encerrar seu
            cadastro, ou seu acesso ao Programa de Fidelidade Cashback, no caso
            de violação a qualquer termo deste Contrato. Você declara que é o
            único responsável por seu próprio cadastro e pela utilização do
            Programa de Fidelidade, sendo a sua autenticação de usuário
            conferida por senha pessoal e intransferível. Você não deve
            compartilhar sua senha ou utilizar o Programa de Fidelidade usando o
            cadastro de terceiros
          </p>

          <p>
            O usuário assume estar ciente dos serviços prestados pelo APP do
            PASSFREE que são de inteira intermediação com o OFERTANTE (EMPRESA
            PARCEIRA), a PASSFREE tem como objetivo facilitar a gestão de
            pagamentos, recebimentos e transferência do cartão fidelidade e
            Cashback com outras empresas parceiras e instituições financeiras.
          </p>

          <p>
            A PASSFREE não se responsabiliza por falhas, erros, atrasos,
            anúncios publicado e ofertados por seus parceiros, não garantindo a
            prestação de serviços de fidelidade ofertado no anuncio em razão da
            PASSFREE ser apenas o canal de divulgação da ferramenta de
            fidelidade e Cashback, cobrando apenas uma taxa de conveniência da
            EMPRESA PARCEIRA, mas garantimos o total sigilo de sua conta bem
            como o saldo anterior. O serviço Fidelidade e Cashback não é
            ofertado de forma ininterrupta, a PASSFREE entende que pode haver
            situações imprevisíveis e passível de erros no envio de dados e
            atualizações por se tratar de serviços de internet e dos dados
            enviados pela EMPRESA PARCEIRA.
          </p>

          <p>
            A conta é pessoal e intransferível, da mesma forma numero do
            telefone, e-mail e CPF e ou CNPJ validados durante o cadastro do
            USUÁRIO não sendo aceito outros cadastros com as mesmas informações
            descritas.
          </p>

          <h4>2- REGRA DE FIDELIDADE E PONTUAÇÃO NO PASSFREE</h4>
          <ul>
            <li>
              <p>
                Quando o USUÁRIO realizar compras pelo site e ou Estabelecimento
                Participante do Programa de Fidelidade, o OFERTANTE deve
                fornecer através do APP PASSFREE o valor dos pontos e o cashback
                que o cliente tem direito pela compra e ou uso do serviço
                ofertado pelo estabelecimento Participante, no momento da
                efetivação da compra pelo número de telefone constante do seu
                cadastro no Programa de Fidelidade.
              </p>
            </li>

            <li>
              <p>
                Podem haver recompensas vinculadas ao atingimento de uma
                determinada pontuação e ou meta realizada isto independe da
                vontade dos Usuários e ou Ofertantes cabe exclusivamente em
                ofertas relâmpagos de empresas parceiras, ofertado tal bônus e
                ou oferta de desconto com ganhos de Cashback e possuem caráter
                individual e personalíssimo, destinando-se aos Usuários que
                comprovarem compras relacionadas ao seu próprio consumo e em
                locais e horários pré definidos na promoção.
              </p>
            </li>

            <li>
              <p>
                Serão computados pontos no cartão fidelidade e Cashback de
                acordo com as regras da Empresa Ofertante que podem ser nas
                compras, serviços, anúncios e promoções ofertados, o valor dos
                PONTOS DO CARTÃO FIDELIDADE E O CASHBACK(Moeda PASSFREE) tem o
                seu valor diferenciado e ou ajustado pelo OFERTANTE e podem não
                gerar o Cashback desejado pelo USUÁRIO por isso o USUÁRIO deve
                ficar atento ao informativo do Ofertante se ele disponibiliza
                somente os pontos do cartão para serem gastos no seu
                estabelecimento o Cashback(PASSFREE) pode ser gasto em todas as
                empresas participante do programa de fidelidade PASSFREE cujo
                &apos;&apos;valor global da compra&apos;&apos; (doravante
                denominado somente &apos;&apos;VGC&apos;&apos;) represente
                quantia igual ou maior do que o valor em compras que dá direito
                à aquisição de 01 (um) ponto junto ao Programa de Fidelidade
                (&apos;&apos;valor para um ponto&apos;&apos;, doravante
                denominado somente &apos;&apos;VPP&apos;&apos;), para fins de
                recebimento de Recompensas que estejam vinculadas ao atingimento
                de um número de pontos
              </p>
            </li>
          </ul>
        </div>
      </CardContent>
    </Card>
  )
}
export default TermsAndConditions
