import React from 'react'
import { Error } from '@material-ui/icons'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(() => ({
  message: {
    display: 'flex',
    flexDirection: 'row',
    gap: '4px',
    alignItems: 'center',
    color: '#607D8B',
  },
}))

export enum MessageTypeEnum {
  ERROR = 'ERROR',
  SUCCESS = 'SUCCESS',
  ALERT = 'ALERT',
  INFO = 'INFO',
}

type Props = {
  type: MessageTypeEnum
  message: string
}
const Message: React.FC<Props> = ({ type, message }) => {
  const classes = useStyles()

  if (!message) {
    return <span />
  }

  switch (type) {
    case MessageTypeEnum.ALERT:
      return (
        <div className={classes.message} style={{ color: '#F57C00' }}>
          <Error />
          <span> {message} </span>
        </div>
      )
    case MessageTypeEnum.ERROR:
      return (
        <div className={classes.message} style={{ color: '#F44336' }}>
          <Error />
          <span> {message} </span>
        </div>
      )
    case MessageTypeEnum.INFO:
      return (
        <div className={classes.message} style={{ color: '#2196F3' }}>
          <Error />
          <span> {message} </span>
        </div>
      )
    case MessageTypeEnum.SUCCESS:
      return (
        <div className={classes.message} style={{ color: '#4CAF50' }}>
          <Error />
          <span> {message} </span>
        </div>
      )
    default:
      return (
        <div className={classes.message}>
          <Error />
          <span> {message} </span>
        </div>
      )
  }
}
export default Message
