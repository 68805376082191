/* eslint-disable no-new */
import React from 'react'

import Card from '@material-ui/core/Card'
import { useGoogleMaps } from 'react-hook-google-maps'

interface MapProps {
  lat: number
  lng: number
  width?: number | string
  height?: number | string
  margin?: number | string
}

const Map: React.FC<MapProps> = ({
  lat,
  lng,
  width = '100%',
  height = 200,
  margin = 0,
}) => {
  const { ref, map, google } = useGoogleMaps(
    'AIzaSyB6k2zHh9leLPZbfy7yZUONpQvVwZFdr-w',
    {
      center: { lat: lat || -25.509552, lng: lng || -54.60835 },
      zoom: 16,
    },
  )

  if (map) {
    new google.maps.Marker({ position: { lat, lng }, map })
  }

  return <Card ref={ref} style={{ width, height, margin }} />
}

export default Map
