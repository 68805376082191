import React from 'react'
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import { Category } from '../../generated/graphql'

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyItems: 'baseline',
    textDecoration: 'none',
    fontWeight: 'bold',
  },
  selectedRoot: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyItems: 'baseline',
    textDecoration: 'none',
    fontWeight: 'bold',

    '& span': {
      color: '#3da654',
    },
  },
  clearedLink: {
    textDecoration: 'none',
    color: '#888',
  },
}))

type Props = {
  category: Category
  selected?: boolean
}

const CategoryItem: React.FC<Props> = ({ category, selected }) => {
  const classes = useStyles()

  return (
    <Link
      to={`/promocoes?category=${category.slug}`}
      className={classes.clearedLink}
    >
      <div className={selected ? classes.selectedRoot : classes.root}>
        <img
          style={{ width: '50px', height: '50px' }}
          src={category.icon}
          alt={category.name}
        />
        <span className="label">{category.name}</span>
      </div>
    </Link>
  )
}
export default CategoryItem
