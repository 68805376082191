/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

const INITIAL_STATE = {
  drawer: false,
  voucher: '1',
  welcome: true,
}

export default function environment(state = INITIAL_STATE, action: any) {
  if (action.type === '@environment/SET_STATE_DRAWER') {
    return { ...state, drawer: action.payload.state }
  }

  if (action.type === '@environment/SET_STATE_VOUCHER') {
    return { ...state, voucher: action.payload.state }
  }

  if (action.type === '@environment/SET_STATE_WELCOME') {
    return { ...state, welcome: action.payload.state }
  }

  return state
}
