import logo from '../assets/img/logo.png'
import packageJson from '../../package.json'

export interface ConfigInterface {
  name: string
  env: 'development' | 'production' | 'preview' | 'localhost' | 'docker'
  logo: any
  logoMenu: any
  email: string
  phone: string
  address: string
  frontHostname: string
  frontEndUrl: string
  graphqlUri: string
  graphqlWs: string
  keycloak: {
    realm: string
    url: string
    clientId: string
  }
  version: string
  urlManager: string
  googleMapsApiKey: string
}

const configDefault: ConfigInterface = {
  name: 'PassFree',
  env: 'production',
  logo,
  logoMenu: logo,
  email: '',
  phone: '',
  address: '',
  frontHostname: 'app.passfree.com.br',
  frontEndUrl: 'https://app.passfree.com.br',
  graphqlUri: 'https://api.passfree.com.br/graphql',
  graphqlWs: 'wss://api.passfree.com.br/graphql',
  keycloak: {
    realm: 'passfree',
    url: 'https://conta.passfree.com.br',
    clientId: 'app-front',
  },
  version: packageJson.version,
  urlManager: 'http://host.docker.internal:8085/admin',
  googleMapsApiKey: 'AIzaSyCZmKQhVCcS3aM0wA2Az0YtUhVkwHUek4I',
}

const configProd: ConfigInterface = {
  ...configDefault,
  env: 'production',
  frontHostname: 'app.passfree.com.br',
  frontEndUrl: 'https://app.passfree.com.br',
  graphqlUri: 'https://api.passfree.com.br/graphql',
  graphqlWs: 'wss://api.passfree.com.br/graphql',
}

const configDev: ConfigInterface = {
  ...configDefault,
  env: 'development',
  frontHostname: 'app.passfree.site',
  frontEndUrl: 'https://app.passfree.site',
  graphqlUri: 'https://api.passfree.site/graphql',
  graphqlWs: 'wss://api.passfree.site/graphql',
  keycloak: {
    realm: 'passfree-dev',
    url: 'https://conta.passfree.site',
    clientId: 'app-front',
  },
}

const configLocal: ConfigInterface = {
  ...configDev,
  name: 'PassFree - [LOCAL]',
  env: 'localhost',
  frontHostname: 'localhost:3000',
  frontEndUrl: 'http://localhost:3000',
}

const getEnv = (): ConfigInterface => {
  const { host } = window.location

  const envs = [configProd, configDev, configLocal]
  const config = envs.find(env => env.frontHostname === host)

  if (!config) {
    // eslint-disable-next-line no-console
    console.warn('Invalid env config, using default => development')
    return configDev
  }

  return config
}

function printBannerDev() {
  // eslint-disable-next-line no-console
  console.log('PRINT BANNER DEV')

  const newDiv = document.createElement('div')
  const newContent = document.createTextNode('AMBIENTE DE TESTE')

  newDiv.style.display = 'block'
  newDiv.style.background = 'rgb(255 0 0 / 40%)'
  newDiv.style.width = '100%'
  newDiv.style.height = '18px'
  newDiv.style.textAlign = 'center'
  newDiv.style.position = 'absolute'
  newDiv.style.zIndex = '1000'

  newDiv.appendChild(newContent)
  document.body.prepend(newDiv)

  document.title = `[DEV] ${document.title}`
}

export const Config: ConfigInterface = getEnv()

if (Config.env !== 'production') {
  printBannerDev()
}
