import { gql } from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
  Demo: any;
  JSON: any;
  /** MongoDB ObjectId scalar type, sent as 24 byte Hex String */
  ObjectId: any;
  Upload: any;
};



export type AclFromWorkspaceResponse = {
  __typename?: 'AclFromWorkspaceResponse';
  workspace: WorkspaceMin;
  roles: Array<Maybe<RoleAcl>>;
  permissions: Array<Maybe<PermissionAcl>>;
  plan?: Maybe<Scalars['String']>;
  quotes: Array<Maybe<QuoteAcl>>;
};

export type AclResponse = {
  __typename?: 'AclResponse';
  roles: Array<Maybe<RoleAcl>>;
  permissions: Array<Maybe<PermissionAcl>>;
};

export enum ActionsEnum {
  Created = 'CREATED',
  Updated = 'UPDATED',
  Deleted = 'DELETED'
}

export type Address = {
  __typename?: 'Address';
  city: Scalars['String'];
  stateCode: Scalars['String'];
  street: Scalars['String'];
  number: Scalars['String'];
  district?: Maybe<Scalars['String']>;
  complement?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
  location?: Maybe<Location>;
};

export type AddressInput = {
  city: Scalars['String'];
  stateCode: Scalars['String'];
  street: Scalars['String'];
  number: Scalars['String'];
  district?: Maybe<Scalars['String']>;
  complement?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
  location?: Maybe<LocationInput>;
};

export type AppBuyPromotionResponse = {
  __typename?: 'AppBuyPromotionResponse';
  message: Scalars['String'];
  data: AppPromoCode;
};

export type AppLinkReferrer = {
  __typename?: 'AppLinkReferrer';
  slug: Scalars['String'];
  link: Scalars['String'];
  redirectUrl: Scalars['String'];
  tableId: Scalars['ID'];
  tableType: ReferrerTableTypeEnum;
  linkId: Scalars['ID'];
};

export type AppLinkReferrerResponse = {
  __typename?: 'AppLinkReferrerResponse';
  message: Scalars['String'];
  data: AppLinkReferrer;
};

export type AppLocal = {
  __typename?: 'AppLocal';
  _id: Scalars['ID'];
  name: Scalars['String'];
  slug: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  thumb?: Maybe<Scalars['String']>;
  cover?: Maybe<Scalars['String']>;
  contacts?: Maybe<LocalContacts>;
  address?: Maybe<Address>;
  company?: Maybe<LocalCompany>;
  promotions: Array<AppPromotion>;
};

export type AppLocalResponse = {
  __typename?: 'AppLocalResponse';
  message?: Maybe<Scalars['String']>;
  edges: Array<AppLocal>;
  pagination?: Maybe<Pagination>;
};

export type AppMyPromoCodesResponse = {
  __typename?: 'AppMyPromoCodesResponse';
  openedCodes: Array<AppPromoCode>;
  closedCodes: Array<AppPromoCode>;
};

export type AppPoint = {
  __typename?: 'AppPoint';
  /** Conversao de pontos para PSC, mesmo que ainda não tenha dado o saldo */
  inPSC: Scalars['Int'];
  minPSC: Scalars['Int'];
  PTSxPSC: Scalars['Int'];
  balance: Scalars['Int'];
  transactions: Array<AppPointTransaction>;
};

export type AppPointTransaction = {
  __typename?: 'AppPointTransaction';
  _id: Scalars['ID'];
  type: PointTransactionTypeEnum;
  operation: PointTransactionOperationEnum;
  title: Scalars['String'];
  deposit?: Maybe<Scalars['Int']>;
  withdraw?: Maybe<Scalars['Int']>;
  balance: Scalars['Int'];
  description?: Maybe<Scalars['String']>;
  ref?: Maybe<Scalars['String']>;
  createdAt: CustomDate;
};

export type AppPromoCode = {
  __typename?: 'AppPromoCode';
  code: Scalars['String'];
  expireIn: CustomDate;
  createdAt: CustomDate;
  description: Scalars['String'];
  amountLabel: Scalars['String'];
  promotion?: Maybe<AppPromotion>;
  local?: Maybe<AppLocal>;
  status: PromoCodeStatusEnum;
  promotionType: PromotionTypeEnum;
  usedAt?: Maybe<CustomDate>;
};

export type AppPromotion = {
  __typename?: 'AppPromotion';
  _id: Scalars['ID'];
  title: Scalars['String'];
  slug: Scalars['String'];
  resume?: Maybe<Scalars['String']>;
  thumb?: Maybe<Scalars['String']>;
  type: PromotionTypeEnum;
  config: AppPromotionConfig;
  rules?: Maybe<Scalars['String']>;
  myInfo: AppPromotionMyInfo;
  highlighted?: Maybe<Scalars['Boolean']>;
  startDate: CustomDate;
  endDate: CustomDate;
  local: AppLocal;
  categories: Array<Category>;
};

export type AppPromotionConfig = {
  __typename?: 'AppPromotionConfig';
  brl?: Maybe<Scalars['Float']>;
  passCoin?: Maybe<Scalars['Int']>;
  cashbackInPassCoin?: Maybe<Scalars['Int']>;
};

export type AppPromotionMyInfo = {
  __typename?: 'AppPromotionMyInfo';
  status: AppPromotionMyStatusEnum;
  coinMissing: Scalars['Int'];
  promoCode?: Maybe<AppPromoCode>;
};

export enum AppPromotionMyStatusEnum {
  /** Nao logado */
  Guest = 'Guest',
  /** Usuario logado, porém cadastro incompleto */
  ClientIncomplete = 'ClientIncomplete',
  /** Ja possui um ticket em aberto */
  HasTicket = 'HasTicket',
  /** Falta saldo */
  InsufficientCoin = 'InsufficientCoin',
  /** Disponivel pra compra, e com saldo suficiente */
  Available = 'Available',
  /** Codigos esgotado */
  SoldOut = 'SoldOut'
}

export type AppPromotionResponse = {
  __typename?: 'AppPromotionResponse';
  edges: Array<AppPromotion>;
  pagination?: Maybe<Pagination>;
};

export type AppQrCodeSimulateResponse = {
  __typename?: 'AppQrCodeSimulateResponse';
  message: Scalars['String'];
  passCoin: Scalars['Int'];
  local: AppLocal;
  promotion: AppPromotion;
};

export type AppWallet = {
  __typename?: 'AppWallet';
  balance: Scalars['Int'];
  transactions: Array<AppWalletTransaction>;
};

export type AppWalletTransaction = {
  __typename?: 'AppWalletTransaction';
  _id: Scalars['ID'];
  type: TransactionTypeEnum;
  operation: TransactionOperationEnum;
  title: Scalars['String'];
  balance: Scalars['Int'];
  deposit?: Maybe<Scalars['Int']>;
  withdraw?: Maybe<Scalars['Int']>;
  local?: Maybe<AppLocal>;
  promotion?: Maybe<Scalars['ID']>;
  promotionCode?: Maybe<Scalars['String']>;
  createdAt: CustomDate;
};

export type AuthResponse = {
  __typename?: 'AuthResponse';
  accessToken: Scalars['String'];
  Authorization: Scalars['String'];
};

export type BetweenNumberInput = {
  start?: Maybe<Scalars['Float']>;
  end?: Maybe<Scalars['Float']>;
};

export type Bonus = {
  __typename?: 'Bonus';
  _id: Scalars['ID'];
  title: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  type: BonusTypeEnum;
  passCoin: Scalars['Int'];
  saleAmount?: Maybe<Scalars['Float']>;
  isMaxBonus?: Maybe<Scalars['Boolean']>;
  client: ClientBonus;
  userOperator: UserOperator;
  createdAt: CustomDate;
};

export type BonusResponse = {
  __typename?: 'BonusResponse';
  edges: Array<Bonus>;
  totalSaleAmount: Scalars['Float'];
  totalAll: Scalars['Int'];
  quantityAll: Scalars['Int'];
  totalGift: Scalars['Int'];
  quantityGift: Scalars['Int'];
  totalCashback: Scalars['Int'];
  quantityCashback: Scalars['Int'];
};

export enum BonusTypeEnum {
  Cashback = 'Cashback',
  Gift = 'Gift'
}

export type Category = {
  __typename?: 'Category';
  slug: CategorySlugEnum;
  name: Scalars['String'];
  icon: Scalars['String'];
};

export enum CategorySlugEnum {
  Restaurante = 'restaurante',
  Lanches = 'lanches',
  Confeccao = 'confeccao',
  Automotivo = 'automotivo',
  Beleza = 'beleza',
  Ingressos = 'ingressos'
}

export type Client = {
  __typename?: 'Client';
  _id: Scalars['ID'];
  name: Scalars['String'];
  phone: Scalars['String'];
  balance: Scalars['Int'];
  temporary?: Maybe<Scalars['Boolean']>;
  keycloak?: Maybe<Scalars['String']>;
  createdAt: CustomDate;
  updatedAt: CustomDate;
  deletedAt?: Maybe<CustomDate>;
};

export type ClientBonus = {
  __typename?: 'ClientBonus';
  _id: Scalars['ID'];
  name: Scalars['String'];
  phone: Scalars['String'];
};

export type ClientConditionAndInput = {
  name?: Maybe<Scalars['String']>;
};

export type ClientEdgesResponse = {
  __typename?: 'ClientEdgesResponse';
  edges: Array<Client>;
  pagination?: Maybe<Pagination>;
};

export type ClientPublic = {
  __typename?: 'ClientPublic';
  _id: Scalars['ID'];
  name: Scalars['String'];
  phone: Scalars['String'];
  balance: Scalars['Int'];
  temporary?: Maybe<Scalars['Boolean']>;
};

export type ClientReferrerInput = {
  tableId: Scalars['ID'];
  tableType: ReferrerTableTypeEnum;
  startedAt: Scalars['Date'];
  linkId?: Maybe<Scalars['ID']>;
};

export type ClientResponse = {
  __typename?: 'ClientResponse';
  message: Scalars['String'];
  data: Client;
};

export type ClientSearchInput = {
  q?: Maybe<Scalars['String']>;
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
  sort?: Maybe<SortClientInput>;
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  and?: Maybe<ClientConditionAndInput>;
};

export type ClientUpdateInput = {
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  referrer?: Maybe<ClientReferrerInput>;
};

export type CouponConfig = {
  __typename?: 'CouponConfig';
  amountBrl: Scalars['Float'];
  cashbackInPassCoin: Scalars['Int'];
};

export type CouponConfigInput = {
  amountBrl: Scalars['Float'];
  cashbackInPassCoin: Scalars['Int'];
};

export type CouponInfo = {
  __typename?: 'CouponInfo';
  qtGenerated: Scalars['Int'];
  qtUsed: Scalars['Int'];
  qtCanceled: Scalars['Int'];
  qtAvailable: Scalars['Int'];
};

export type CustomDate = {
  __typename?: 'CustomDate';
  /** Integer value representing the number of milliseconds since the Unix Epoch (Jan 1 1970 12AM UTC). */
  timestamp?: Maybe<Scalars['Float']>;
  /** Unix timestamp (seconds since the Unix Epoch) */
  unix?: Maybe<Scalars['Float']>;
  /** UTC time instead of local time */
  utc?: Maybe<Scalars['String']>;
  /** Formats a string to the ISO8601 standard. */
  iso?: Maybe<Scalars['String']>;
  /** String matches the RFC 2822 Date time format */
  string?: Maybe<Scalars['String']>;
  /** This function returns the real offset from UTC, not the reverse offset */
  timezone?: Maybe<Scalars['String']>;
  /**
   * If you know the format of an input string, you can use that to parse a date.
   * Readmore: [momentjs format date](https://momentjs.com/docs/#/parsing/string-format)
   */
  format?: Maybe<Scalars['String']>;
};


export type CustomDateTimezoneArgs = {
  offset: Scalars['String'];
};


export type CustomDateFormatArgs = {
  format: Scalars['String'];
};

export type CustomNumber = {
  __typename?: 'CustomNumber';
  string?: Maybe<Scalars['String']>;
  int?: Maybe<Scalars['Int']>;
  float?: Maybe<Scalars['Float']>;
  fixed?: Maybe<Scalars['String']>;
  format?: Maybe<Scalars['String']>;
};


export type CustomNumberFixedArgs = {
  digits: Scalars['Int'];
};


export type CustomNumberFormatArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  digits?: Maybe<Scalars['Int']>;
};



export type ErrorResponse = {
  __typename?: 'ErrorResponse';
  message: Scalars['String'];
  error?: Maybe<HttpError>;
  validation?: Maybe<Array<Maybe<Validation>>>;
};

export type File = {
  __typename?: 'File';
  id: Scalars['ID'];
  path: Scalars['String'];
  filename: Scalars['String'];
  mimetype: Scalars['String'];
  encoding: Scalars['String'];
};

export type HttpError = {
  __typename?: 'HttpError';
  httpCode?: Maybe<Scalars['Int']>;
  code?: Maybe<Scalars['String']>;
};

export type ImageThumb = {
  __typename?: 'ImageThumb';
  thumb: Scalars['String'];
  small: Scalars['String'];
  large: Scalars['String'];
};

export type InfoApi = {
  __typename?: 'InfoApi';
  app_name: Scalars['String'];
  env: Scalars['String'];
  name: Scalars['String'];
  date?: Maybe<CustomDate>;
  version: Scalars['String'];
  commit: Scalars['String'];
  short_commit: Scalars['String'];
};


export type LinkReferrer = {
  __typename?: 'LinkReferrer';
  _id: Scalars['ID'];
  name: Scalars['String'];
  tags: Array<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  slug: Scalars['String'];
  link: Scalars['String'];
  redirectUrl: Scalars['String'];
  tableId?: Maybe<Scalars['ID']>;
  tableType: ReferrerTableTypeEnum;
  visits: Scalars['Int'];
  convertions: Scalars['Int'];
  disabledAt?: Maybe<CustomDate>;
  updatedAt: CustomDate;
  createdAt: CustomDate;
};

export type Local = {
  __typename?: 'Local';
  _id: Scalars['ID'];
  name: Scalars['String'];
  slug: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  thumb?: Maybe<Scalars['String']>;
  cover?: Maybe<Scalars['String']>;
  contacts?: Maybe<LocalContacts>;
  address?: Maybe<Address>;
  company?: Maybe<LocalCompany>;
  configs: LocalConfigs;
  status: LocalStatusEnum;
  createdAt: CustomDate;
  updatedAt: CustomDate;
  workspace?: Maybe<Workspace>;
  wallet?: Maybe<Wallet>;
};

export type LocalCompany = {
  __typename?: 'LocalCompany';
  cnpj: Scalars['String'];
  razaoSocial: Scalars['String'];
  owner: Scalars['String'];
  phone: Scalars['String'];
  mail: Scalars['String'];
};

export type LocalCompanyInput = {
  cnpj: Scalars['String'];
  razaoSocial: Scalars['String'];
  owner: Scalars['String'];
  phone: Scalars['String'];
  mail: Scalars['String'];
};

export type LocalConditionAndInput = {
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  status?: Maybe<LocalStatusEnum>;
};

export type LocalConfigs = {
  __typename?: 'LocalConfigs';
  bonusEnabled: Scalars['Boolean'];
  minBuy: Scalars['Float'];
  maxBonus: Scalars['Float'];
  bonusQuote: Scalars['Float'];
};

export type LocalConfigsInput = {
  bonusEnabled: Scalars['Boolean'];
  minBuy: Scalars['Float'];
  maxBonus: Scalars['Float'];
  bonusQuote: Scalars['Float'];
};

export type LocalContacts = {
  __typename?: 'LocalContacts';
  whatsApp?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  mail?: Maybe<Scalars['String']>;
  instagram?: Maybe<Scalars['String']>;
  facebook?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
};

export type LocalContactsInput = {
  whatsApp?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  mail?: Maybe<Scalars['String']>;
  instagram?: Maybe<Scalars['String']>;
  facebook?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
};

export type LocalCreateInput = {
  name: Scalars['String'];
  slug: Scalars['String'];
  status: LocalStatusEnum;
  description?: Maybe<Scalars['String']>;
  thumb?: Maybe<Scalars['Upload']>;
  cover?: Maybe<Scalars['Upload']>;
  contacts?: Maybe<LocalContactsInput>;
  address?: Maybe<AddressInput>;
  company?: Maybe<LocalCompanyInput>;
  configs?: Maybe<LocalConfigsInput>;
};

export type LocalFilterInput = {
  ids: Array<Maybe<Scalars['ID']>>;
  forceAll?: Maybe<Scalars['Boolean']>;
};

export type LocalResponse = {
  __typename?: 'LocalResponse';
  message?: Maybe<Scalars['String']>;
  edges: Array<Local>;
  pagination?: Maybe<Pagination>;
};

export type LocalSearchInput = {
  q?: Maybe<Scalars['String']>;
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
  sort?: Maybe<SortLocalInput>;
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  and?: Maybe<LocalConditionAndInput>;
};

export enum LocalStatusEnum {
  Pendent = 'Pendent',
  Published = 'Published',
  Deactivate = 'Deactivate'
}

export type LocalUpdateDataInput = {
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  thumb?: Maybe<Scalars['Upload']>;
  cover?: Maybe<Scalars['Upload']>;
  contacts?: Maybe<LocalContactsInput>;
  address?: Maybe<AddressInput>;
  company?: Maybe<LocalCompanyInput>;
  status?: Maybe<LocalStatusEnum>;
  configs?: Maybe<LocalConfigsInput>;
};

export type Location = {
  __typename?: 'Location';
  type: LocationTypeEnum;
  coordinates: Array<Scalars['Float']>;
};

export type LocationInput = {
  type: LocationTypeEnum;
  coordinates: Array<Scalars['Float']>;
};

export enum LocationTypeEnum {
  Point = 'Point'
}

export type Mutation = {
  __typename?: 'Mutation';
  createPlans: PlanResponse;
  updatePlans: PlanResponse;
  removePlans: PlanResponse;
  createRoles: RoleResponse;
  updateRoles: RoleResponse;
  removeRoles: RoleResponse;
  createUsers: UserResponse;
  addRoleToUser: UserDetailedResponse;
  delRoleToUser: UserDetailedResponse;
  updateUsers: UserResponse;
  removeUsers: UserResponse;
  setPlanToWorkspace: SimpleResponse;
  addUserToWorkspace: SimpleResponse;
  delUserToWorkspace: SimpleResponse;
  createWorkspaces: WorkspaceResponse;
  updateWorkspaces: WorkspaceResponse;
  removeWorkspaces: WorkspaceResponse;
  appBuyPromotion: AppBuyPromotionResponse;
  login: AuthResponse;
  registerGift: RegisterGiftResponse;
  registerCashback: RegisterCashbackResponse;
  updateMyClient: ClientResponse;
  updateMyClientReferrer: AppLinkReferrerResponse;
  createLocals: LocalResponse;
  updateLocals: LocalResponse;
  removeLocals: LocalResponse;
  createNotes: NoteResponse;
  updateNotes: NoteResponse;
  removeNotes: NoteResponse;
  confirmPromoCode: SimpleResponse;
  generateCoupons: SimpleResponse;
  removePromotion: PromotionMutationResponse;
  changeStatusPromotion: PromotionMutationResponse;
  createPromotionsByWorkspace: PromotionMutationResponse;
  updatePromotionsByWorkspace: PromotionMutationResponse;
  changeStatusPromotionByWorkspace: PromotionMutationResponse;
  appQrCodeSimulate: AppQrCodeSimulateResponse;
  appQrCodeConfirm: SimpleResponse;
  upsertLinkReferrer: UpsertLinkReferrerResponse;
  walletOperationLocal: RegisterLocalWalletResponse;
  walletTransfer: RegisterLocalWalletResponse;
};


export type MutationCreatePlansArgs = {
  rows: Array<Maybe<PlanCreateInput>>;
};


export type MutationUpdatePlansArgs = {
  filter: PlanFilterInput;
  input: PlanUpdateDataInput;
};


export type MutationRemovePlansArgs = {
  filter: PlanFilterInput;
};


export type MutationCreateRolesArgs = {
  rows: Array<Maybe<RoleCreateInput>>;
};


export type MutationUpdateRolesArgs = {
  filter: RoleFilterInput;
  input: RoleUpdateDataInput;
};


export type MutationRemoveRolesArgs = {
  filter: RoleFilterInput;
};


export type MutationCreateUsersArgs = {
  rows: Array<Maybe<UserCreateInput>>;
};


export type MutationAddRoleToUserArgs = {
  userId: Scalars['String'];
  roles: Array<Maybe<Scalars['String']>>;
};


export type MutationDelRoleToUserArgs = {
  userId: Scalars['String'];
  roles: Array<Maybe<Scalars['String']>>;
};


export type MutationUpdateUsersArgs = {
  filter: UserFilterInput;
  input: UserUpdateDataInput;
};


export type MutationRemoveUsersArgs = {
  filter: UserFilterInput;
};


export type MutationSetPlanToWorkspaceArgs = {
  workspace: Scalars['ID'];
  plan: Scalars['ID'];
};


export type MutationAddUserToWorkspaceArgs = {
  workspace: Scalars['ID'];
  user: Scalars['String'];
  role: Scalars['String'];
};


export type MutationDelUserToWorkspaceArgs = {
  workspace: Scalars['ID'];
  user: Scalars['String'];
};


export type MutationCreateWorkspacesArgs = {
  rows: Array<Maybe<WorkspaceCreateInput>>;
};


export type MutationUpdateWorkspacesArgs = {
  filter: WorkspaceFilterInput;
  input: WorkspaceUpdateDataInput;
};


export type MutationRemoveWorkspacesArgs = {
  filter: WorkspaceFilterInput;
};


export type MutationAppBuyPromotionArgs = {
  promotion: Scalars['ID'];
};


export type MutationLoginArgs = {
  username: Scalars['String'];
  password: Scalars['String'];
  secret: Scalars['String'];
};


export type MutationRegisterGiftArgs = {
  input: RegisterGiftInput;
};


export type MutationRegisterCashbackArgs = {
  input: RegisterCashbackInput;
};


export type MutationUpdateMyClientArgs = {
  input: ClientUpdateInput;
};


export type MutationUpdateMyClientReferrerArgs = {
  slug: Scalars['String'];
};


export type MutationCreateLocalsArgs = {
  rows: Array<Maybe<LocalCreateInput>>;
};


export type MutationUpdateLocalsArgs = {
  filter: LocalFilterInput;
  input: LocalUpdateDataInput;
};


export type MutationRemoveLocalsArgs = {
  filter: LocalFilterInput;
};


export type MutationCreateNotesArgs = {
  rows: Array<Maybe<NoteCreateInput>>;
};


export type MutationUpdateNotesArgs = {
  filter: NoteFilterInput;
  input: NoteUpdateDataInput;
};


export type MutationRemoveNotesArgs = {
  filter: NoteFilterInput;
};


export type MutationConfirmPromoCodeArgs = {
  code: Scalars['String'];
};


export type MutationGenerateCouponsArgs = {
  promotion: Scalars['ID'];
  quantity: Scalars['Int'];
};


export type MutationRemovePromotionArgs = {
  id: Scalars['ID'];
};


export type MutationChangeStatusPromotionArgs = {
  id: Scalars['ID'];
  status: PromotionStatusEnum;
  highlighted?: Maybe<Scalars['Boolean']>;
};


export type MutationCreatePromotionsByWorkspaceArgs = {
  input: PromotionCreateInput;
};


export type MutationUpdatePromotionsByWorkspaceArgs = {
  id: Scalars['ID'];
  input: PromotionUpdateDataInput;
};


export type MutationChangeStatusPromotionByWorkspaceArgs = {
  id: Scalars['ID'];
  status: PromotionStatusEnum;
};


export type MutationAppQrCodeSimulateArgs = {
  code: Scalars['String'];
};


export type MutationAppQrCodeConfirmArgs = {
  code: Scalars['String'];
};


export type MutationUpsertLinkReferrerArgs = {
  input: UpsertLinkReferrerInput;
};


export type MutationWalletOperationLocalArgs = {
  input: WalletOperationLocalInput;
};


export type MutationWalletTransferArgs = {
  input: WalletTransferInput;
};

export type Note = {
  __typename?: 'Note';
  _id: Scalars['ID'];
  /** Conteudo da mensamge */
  content?: Maybe<Scalars['String']>;
  /**
   * Campo comum de texto (max=45)
   * Serve para indentificar o tipo da mensamge.
   * Ex: Pagamentos, Reclamação, Ligação, Pontos com cliente, Contrato ...
   */
  type?: Maybe<Scalars['String']>;
  /**
   * Dados do usuário que registrou a mensagem.
   *
   * Obs: tableType e tableId nao podem ser alterados
   */
  user: UserNote;
  /**
   * Como o note é genérico, vc pode usar ele dentro de varios domains.
   * Digamos que vc queira criar "notes" para um workspace, basta vc passar:
   * tableType=workpsace.nama
   * tableId=workpsace.id
   *
   * Obs: tableType e tableId nao podem ser alterados
   */
  tableType: Scalars['String'];
  tableId: Scalars['String'];
  /** Apenas uma concatenação "{tableType}:{tableId} */
  key: Scalars['String'];
  createdAt: CustomDate;
  updatedAt: CustomDate;
};

export type NoteConditionAndInput = {
  type?: Maybe<Scalars['String']>;
  user?: Maybe<Scalars['String']>;
  tableType?: Maybe<Scalars['String']>;
  tableId?: Maybe<Scalars['String']>;
};

export type NoteCreateInput = {
  content: Scalars['String'];
  type: Scalars['String'];
  tableType: Scalars['String'];
  tableId: Scalars['String'];
};

export type NoteFilterInput = {
  ids: Array<Scalars['ID']>;
  forceAll?: Maybe<Scalars['Boolean']>;
};

export type NoteResponse = {
  __typename?: 'NoteResponse';
  message?: Maybe<Scalars['String']>;
  edges: Array<Maybe<Note>>;
  pagination?: Maybe<Pagination>;
};

export type NoteSearchInput = {
  q?: Maybe<Scalars['String']>;
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
  sort?: Maybe<SortNoteInput>;
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  and?: Maybe<NoteConditionAndInput>;
};

export type NoteUpdateDataInput = {
  content?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};


export type Pagination = {
  __typename?: 'Pagination';
  total: Scalars['Int'];
  page: Scalars['Int'];
  perPage: Scalars['Int'];
  lastPage: Scalars['Int'];
};

export type PassFreeResumeFinance = {
  __typename?: 'PassFreeResumeFinance';
  /** Quanto já foi cunhado? */
  maxSupply: Scalars['Int'];
  /** Quanto já foi queimado (tirado de circulação)? */
  burn: Scalars['Int'];
  /** Quanto tem de moeda na economia? */
  totalSupply: Scalars['Int'];
  /** Saldo total das carteiras do tipo Local */
  balanceLocals: Scalars['Int'];
  /** Saldo total das carteiras do tipo Client (usuarios) */
  balanceClients: Scalars['Int'];
  /** Saldo da carteira do fundo */
  balanceFund: Scalars['Int'];
  /** Saldo da carteira Admin */
  balanceAdmin: Scalars['Int'];
  /** Quanto a PassFre ja recebeu? */
  totalAdminWithdraw: Scalars['Int'];
  /** Quanto a PassFre ja pagou? */
  totalAdminDeposit: Scalars['Int'];
  /** Quanto a PassFre tem a pagar? */
  payable: Scalars['Int'];
};

export type Permission = {
  __typename?: 'Permission';
  name: Scalars['String'];
  group: Scalars['String'];
  description?: Maybe<Scalars['String']>;
};

export type PermissionAcl = {
  __typename?: 'PermissionACL';
  name: Scalars['String'];
  group: Scalars['String'];
  description?: Maybe<Scalars['String']>;
};

export type PermissionGroup = {
  __typename?: 'PermissionGroup';
  group: Scalars['String'];
  children: Array<Maybe<Permission>>;
};

export type PermissionSearchInput = {
  q?: Maybe<Scalars['String']>;
  group?: Maybe<Scalars['String']>;
};

export type Plan = {
  __typename?: 'Plan';
  _id: Scalars['ID'];
  slug: Scalars['String'];
  name: Scalars['String'];
  enable: Scalars['Boolean'];
  private: Scalars['Boolean'];
  monthPrice: CustomNumber;
  description?: Maybe<Scalars['String']>;
  quotes: Array<Maybe<QuoteItem>>;
  createdAt: CustomDate;
  updatedAt: CustomDate;
};

export type PlanConditionAndInput = {
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  enable?: Maybe<Scalars['Boolean']>;
  private?: Maybe<Scalars['Boolean']>;
  quotes?: Maybe<Array<Maybe<Scalars['String']>>>;
  monthPrice?: Maybe<BetweenNumberInput>;
};

export type PlanCreateInput = {
  slug: Scalars['String'];
  name: Scalars['String'];
  enable: Scalars['Boolean'];
  private: Scalars['Boolean'];
  monthPrice: Scalars['Float'];
  description?: Maybe<Scalars['String']>;
  quotes: Array<Maybe<QuoteItemInput>>;
};

export type PlanFilterInput = {
  ids: Array<Scalars['ID']>;
  forceAll?: Maybe<Scalars['Boolean']>;
};

export type PlanResponse = {
  __typename?: 'PlanResponse';
  message?: Maybe<Scalars['String']>;
  edges: Array<Maybe<Plan>>;
  pagination?: Maybe<Pagination>;
};

export type PlanSearchInput = {
  q?: Maybe<Scalars['String']>;
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
  sort?: Maybe<SortPlanInput>;
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  and?: Maybe<PlanConditionAndInput>;
};

export type PlanUpdateDataInput = {
  slug?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  enable?: Maybe<Scalars['Boolean']>;
  private?: Maybe<Scalars['Boolean']>;
  monthPrice?: Maybe<Scalars['Float']>;
  description?: Maybe<Scalars['String']>;
  quotes?: Maybe<Array<Maybe<QuoteItemInput>>>;
};

export enum PointTableTypeEnum {
  Local = 'Local',
  Client = 'Client'
}

export type PointTransaction = {
  __typename?: 'PointTransaction';
  _id: Scalars['ID'];
  tableId: Scalars['ID'];
  tableType: PointTableTypeEnum;
  type: PointTransactionTypeEnum;
  operation: PointTransactionOperationEnum;
  title: Scalars['String'];
  deposit?: Maybe<Scalars['Int']>;
  withdraw?: Maybe<Scalars['Int']>;
  balance: Scalars['Int'];
  description?: Maybe<Scalars['String']>;
  internalDescription?: Maybe<Scalars['String']>;
  ref?: Maybe<Scalars['String']>;
  createdAt: CustomDate;
};

export enum PointTransactionOperationEnum {
  Deposit = 'Deposit',
  Withdraw = 'Withdraw'
}

export enum PointTransactionTypeEnum {
  Referral = 'Referral',
  QrCodeDistribution = 'QRCodeDistribution'
}

export type PromoCode = {
  __typename?: 'PromoCode';
  code: Scalars['String'];
  cashbackAmount?: Maybe<Scalars['Int']>;
  status: PromoCodeStatusEnum;
  promotionType: PromotionTypeEnum;
  client?: Maybe<ClientBonus>;
  usedAt?: Maybe<CustomDate>;
  expireIn?: Maybe<CustomDate>;
  createdAt: CustomDate;
};

export enum PromoCodeStatusEnum {
  Available = 'Available',
  Active = 'Active',
  Used = 'Used',
  Canceled = 'Canceled',
  Expired = 'Expired'
}

export type Promotion = {
  __typename?: 'Promotion';
  _id: Scalars['ID'];
  title: Scalars['String'];
  slug: Scalars['String'];
  resume?: Maybe<Scalars['String']>;
  thumb?: Maybe<Scalars['String']>;
  type: PromotionTypeEnum;
  status: PromotionStatusEnum;
  rules?: Maybe<Scalars['String']>;
  voucherInfo?: Maybe<VoucherInfo>;
  voucherConfig?: Maybe<VoucherConfig>;
  couponInfo?: Maybe<CouponInfo>;
  couponConfig?: Maybe<CouponConfig>;
  promoCodes: Array<PromoCode>;
  categories: Array<Category>;
  highlighted?: Maybe<Scalars['Boolean']>;
  local: Local;
  startDate: CustomDate;
  endDate: CustomDate;
  createdAt: CustomDate;
  updatedAt: CustomDate;
};

export type PromotionConditionAndInput = {
  type?: Maybe<PromotionTypeEnum>;
  title?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  status?: Maybe<PromotionStatusEnum>;
  passCoin?: Maybe<Scalars['Int']>;
  local?: Maybe<Scalars['ID']>;
  highlighted?: Maybe<Scalars['Boolean']>;
  categories?: Maybe<Array<CategorySlugEnum>>;
};

export type PromotionCreateInput = {
  type: PromotionTypeEnum;
  title: Scalars['String'];
  slug?: Maybe<Scalars['String']>;
  resume?: Maybe<Scalars['String']>;
  thumb?: Maybe<Scalars['Upload']>;
  rules?: Maybe<Scalars['String']>;
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  voucherConfig?: Maybe<VoucherConfigInput>;
  couponConfig?: Maybe<CouponConfigInput>;
  categories?: Maybe<Array<CategorySlugEnum>>;
};

export type PromotionMutationResponse = {
  __typename?: 'PromotionMutationResponse';
  message?: Maybe<Scalars['String']>;
  data: Promotion;
};

export type PromotionResponse = {
  __typename?: 'PromotionResponse';
  edges: Array<Promotion>;
  pagination?: Maybe<Pagination>;
};

export type PromotionSearchInput = {
  q?: Maybe<Scalars['String']>;
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
  sort?: Maybe<SortPromotionInput>;
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  and?: Maybe<PromotionConditionAndInput>;
};

export enum PromotionStatusEnum {
  Draft = 'Draft',
  Review = 'Review',
  Refused = 'Refused',
  Published = 'Published',
  SoldOut = 'SoldOut',
  CanceledByLocal = 'CanceledByLocal',
  CanceledByAdmin = 'CanceledByAdmin'
}

export enum PromotionTypeEnum {
  Voucher = 'Voucher',
  Coupon = 'Coupon',
  QrCode = 'QrCode'
}

export type PromotionUpdateDataInput = {
  type: PromotionTypeEnum;
  title?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  resume?: Maybe<Scalars['String']>;
  thumb?: Maybe<Scalars['Upload']>;
  rules?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
  voucherConfig?: Maybe<VoucherConfigInput>;
  couponConfig?: Maybe<CouponConfigInput>;
  categories?: Maybe<Array<CategorySlugEnum>>;
};

export type Query = {
  __typename?: 'Query';
  myAcl: AclResponse;
  myAclFromWorkspace: AclFromWorkspaceResponse;
  permissions: Array<Maybe<PermissionGroup>>;
  plan?: Maybe<Plan>;
  plans: PlanResponse;
  quotes: Array<Maybe<QuoteGroup>>;
  role?: Maybe<Role>;
  roles: RoleResponse;
  user?: Maybe<UserDetailed>;
  users: UserResponse;
  myUser: UserKeycloak;
  userMe: UserKeycloak;
  workspace?: Maybe<Workspace>;
  /**
   * Lista os workspaces que o usuário possui acesso,
   * em outras palavras ele precisa estar relacionado a lista de usuarios do workspace.
   *
   * ### forceAll
   * para uar o paramentro como TRUE, requer permissão "workspaces.access-all"
   */
  myWorkspaces: Array<Maybe<WorkspaceMin>>;
  workspaces: WorkspaceResponse;
  appPromotion?: Maybe<AppPromotion>;
  appPromotions: AppPromotionResponse;
  myLocalBonus: BonusResponse;
  simulateGift: SimulateGiftResponse;
  simulateCashback: SimulateCashbackResponse;
  categories: Array<Category>;
  appCategoriesWithPromo: Array<Category>;
  client?: Maybe<Client>;
  myClient?: Maybe<Client>;
  myClientReferrer?: Maybe<AppLinkReferrer>;
  clients: ClientEdgesResponse;
  clientByPhone: ClientPublic;
  myLocal?: Maybe<Local>;
  local?: Maybe<Local>;
  locals: LocalResponse;
  appLocal?: Maybe<AppLocal>;
  appLocals: AppLocalResponse;
  note?: Maybe<Note>;
  notes: NoteResponse;
  appMyPoints: AppPoint;
  simulatePromoCode: SimulatePromoCodeResponse;
  appMyPromoCodes?: Maybe<AppMyPromoCodesResponse>;
  promotion?: Maybe<Promotion>;
  promotions: PromotionResponse;
  promotionByWorkspace?: Maybe<Promotion>;
  promotionsByWorkspace: PromotionResponse;
  appLinkReferrer: AppLinkReferrer;
  linkReferrer: LinkReferrer;
  linkReferrers: Array<LinkReferrer>;
  appWallet?: Maybe<AppWallet>;
  myLocalWallet: Wallet;
  wallet?: Maybe<Wallet>;
  walletById?: Maybe<Wallet>;
  wallets: Array<WalletSimple>;
  passFreeResumeFinance: PassFreeResumeFinance;
  infoApi?: Maybe<InfoApi>;
  ping?: Maybe<SimpleResponse>;
};


export type QueryPermissionsArgs = {
  params?: Maybe<PermissionSearchInput>;
};


export type QueryPlanArgs = {
  id: Scalars['ID'];
};


export type QueryPlansArgs = {
  params?: Maybe<PlanSearchInput>;
};


export type QueryQuotesArgs = {
  params?: Maybe<QuoteSearchInput>;
};


export type QueryRoleArgs = {
  id: Scalars['String'];
};


export type QueryRolesArgs = {
  params?: Maybe<RoleSearchInput>;
};


export type QueryUserArgs = {
  id: Scalars['String'];
};


export type QueryUsersArgs = {
  params?: Maybe<UserSearchInput>;
};


export type QueryWorkspaceArgs = {
  id: Scalars['ID'];
};


export type QueryMyWorkspacesArgs = {
  forceAll?: Maybe<Scalars['Boolean']>;
};


export type QueryWorkspacesArgs = {
  params?: Maybe<WorkspaceSearchInput>;
};


export type QueryAppPromotionArgs = {
  slug: Scalars['String'];
};


export type QueryAppPromotionsArgs = {
  params?: Maybe<PromotionSearchInput>;
};


export type QuerySimulateGiftArgs = {
  input: RegisterGiftInput;
};


export type QuerySimulateCashbackArgs = {
  input: RegisterCashbackInput;
};


export type QueryClientArgs = {
  id: Scalars['ID'];
};


export type QueryClientsArgs = {
  params?: Maybe<ClientSearchInput>;
};


export type QueryClientByPhoneArgs = {
  phone: Scalars['String'];
};


export type QueryLocalArgs = {
  id: Scalars['ID'];
};


export type QueryLocalsArgs = {
  params?: Maybe<LocalSearchInput>;
};


export type QueryAppLocalArgs = {
  slug: Scalars['String'];
};


export type QueryAppLocalsArgs = {
  params?: Maybe<LocalSearchInput>;
};


export type QueryNoteArgs = {
  id: Scalars['ID'];
};


export type QueryNotesArgs = {
  params?: Maybe<NoteSearchInput>;
};


export type QuerySimulatePromoCodeArgs = {
  code: Scalars['String'];
};


export type QueryPromotionArgs = {
  id: Scalars['ID'];
};


export type QueryPromotionsArgs = {
  params?: Maybe<PromotionSearchInput>;
};


export type QueryPromotionByWorkspaceArgs = {
  id: Scalars['ID'];
};


export type QueryPromotionsByWorkspaceArgs = {
  params?: Maybe<PromotionSearchInput>;
};


export type QueryAppLinkReferrerArgs = {
  slug: Scalars['String'];
};


export type QueryLinkReferrerArgs = {
  id: Scalars['ID'];
};


export type QueryWalletArgs = {
  tableType: WalletTableTypeEnum;
  tableId?: Maybe<Scalars['ID']>;
};


export type QueryWalletByIdArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QueryWalletsArgs = {
  input?: Maybe<WalletSearchInput>;
};


export type QueryPingArgs = {
  txt: Scalars['String'];
};

export type Quote = {
  __typename?: 'Quote';
  name: Scalars['String'];
  group: Scalars['String'];
  description?: Maybe<Scalars['String']>;
};

export type QuoteAcl = {
  __typename?: 'QuoteACL';
  name: Scalars['String'];
  limit: Scalars['Float'];
};

export type QuoteGroup = {
  __typename?: 'QuoteGroup';
  group: Scalars['String'];
  children: Array<Maybe<Quote>>;
};

export type QuoteItem = {
  __typename?: 'QuoteItem';
  name: Scalars['String'];
  group: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  limit: Scalars['Float'];
};

export type QuoteItemInput = {
  name: Scalars['String'];
  limit: Scalars['Float'];
};

export type QuoteSearchInput = {
  q?: Maybe<Scalars['String']>;
  group?: Maybe<Scalars['String']>;
  names?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export enum ReferrerTableTypeEnum {
  Link = 'Link',
  Local = 'Local',
  Client = 'Client',
  Promotion = 'Promotion'
}

export type RegisterCashbackInput = {
  clientPhone: Scalars['String'];
  saleAmount: Scalars['Float'];
  description?: Maybe<Scalars['String']>;
};

export type RegisterCashbackResponse = {
  __typename?: 'RegisterCashbackResponse';
  message: Scalars['String'];
  /** Bonus ID */
  receipt: Scalars['ID'];
  data: SimulateCashbackResponse;
};

export type RegisterGiftInput = {
  clientPhone: Scalars['String'];
  passCoin: Scalars['Float'];
  description?: Maybe<Scalars['String']>;
};

export type RegisterGiftResponse = {
  __typename?: 'RegisterGiftResponse';
  message: Scalars['String'];
  /** Bonus ID */
  receipt: Scalars['ID'];
  data: SimulateGiftResponse;
};

export type RegisterLocalWalletResponse = {
  __typename?: 'RegisterLocalWalletResponse';
  message: Scalars['String'];
  balance: Scalars['Int'];
  walletId: Scalars['ID'];
  transactionId: Scalars['ID'];
};

export type ResponseToken = {
  __typename?: 'ResponseToken';
  token: Scalars['String'];
  exp: Array<Maybe<PermissionAcl>>;
};

export type Role = {
  __typename?: 'Role';
  name: Scalars['String'];
  type: RoleTypeEnum;
  description?: Maybe<Scalars['String']>;
  permissions: Array<Maybe<Scalars['String']>>;
  createdAt: CustomDate;
  updatedAt: CustomDate;
};

export type RoleAcl = {
  __typename?: 'RoleACL';
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  permissions: Array<Maybe<Scalars['String']>>;
};

export type RoleConditionAndInput = {
  name?: Maybe<Scalars['String']>;
  type?: Maybe<RoleTypeEnum>;
  permissions?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type RoleCreateInput = {
  name: Scalars['String'];
  type: RoleTypeEnum;
  description?: Maybe<Scalars['String']>;
  permissions: Array<Maybe<Scalars['String']>>;
};

export type RoleFilterInput = {
  ids: Array<Scalars['String']>;
  forceAll?: Maybe<Scalars['Boolean']>;
};

export type RoleResponse = {
  __typename?: 'RoleResponse';
  message?: Maybe<Scalars['String']>;
  edges: Array<Maybe<Role>>;
  pagination?: Maybe<Pagination>;
};

export type RoleSearchInput = {
  q?: Maybe<Scalars['String']>;
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
  sort?: Maybe<SortRoleInput>;
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  and?: Maybe<RoleConditionAndInput>;
};

export enum RoleTypeEnum {
  User = 'USER',
  Workspace = 'WORKSPACE'
}

export type RoleUpdateDataInput = {
  name?: Maybe<Scalars['String']>;
  type?: Maybe<RoleTypeEnum>;
  description?: Maybe<Scalars['String']>;
  permissions?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type SimpleResponse = {
  __typename?: 'SimpleResponse';
  message: Scalars['String'];
};

export type SimulateCashbackResponse = {
  __typename?: 'SimulateCashbackResponse';
  passCoin: Scalars['Int'];
  saleAmount: Scalars['Float'];
  isMaxBonus: Scalars['Boolean'];
  client?: Maybe<ClientPublic>;
};

export type SimulateGiftResponse = {
  __typename?: 'SimulateGiftResponse';
  passCoin: Scalars['Int'];
  isMaxBonus: Scalars['Boolean'];
  client?: Maybe<ClientPublic>;
};

export type SimulatePromoCodeResponse = {
  __typename?: 'SimulatePromoCodeResponse';
  promotion: Promotion;
  promoCode: PromoCode;
};

export type SortClientInput = {
  name?: Maybe<SortOrderEnum>;
  createdAt?: Maybe<SortOrderEnum>;
  updatedAt?: Maybe<SortOrderEnum>;
  deletedAt?: Maybe<SortOrderEnum>;
};

export type SortLocalInput = {
  name?: Maybe<SortOrderEnum>;
  status?: Maybe<SortOrderEnum>;
  createdAt?: Maybe<SortOrderEnum>;
  updatedAt?: Maybe<SortOrderEnum>;
};

export type SortNoteInput = {
  content?: Maybe<SortOrderEnum>;
  type?: Maybe<SortOrderEnum>;
  user?: Maybe<SortOrderEnum>;
  tableType?: Maybe<SortOrderEnum>;
  createdAt?: Maybe<SortOrderEnum>;
  updatedAt?: Maybe<SortOrderEnum>;
};

export enum SortOrderEnum {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type SortPlanInput = {
  name?: Maybe<SortOrderEnum>;
  enable?: Maybe<SortOrderEnum>;
  private?: Maybe<SortOrderEnum>;
  monthPrice?: Maybe<SortOrderEnum>;
  createdAt?: Maybe<SortOrderEnum>;
  updatedAt?: Maybe<SortOrderEnum>;
};

export type SortPromotionInput = {
  type?: Maybe<SortOrderEnum>;
  title?: Maybe<SortOrderEnum>;
  slug?: Maybe<SortOrderEnum>;
  status?: Maybe<SortOrderEnum>;
  passCoin?: Maybe<SortOrderEnum>;
  startDate?: Maybe<SortOrderEnum>;
  endDate?: Maybe<SortOrderEnum>;
  createdAt?: Maybe<SortOrderEnum>;
  updatedAt?: Maybe<SortOrderEnum>;
};

export type SortRoleInput = {
  name?: Maybe<SortOrderEnum>;
  type?: Maybe<SortOrderEnum>;
  createdAt?: Maybe<SortOrderEnum>;
  updatedAt?: Maybe<SortOrderEnum>;
};

export type SortWorkspaceInput = {
  name?: Maybe<SortOrderEnum>;
  enable?: Maybe<SortOrderEnum>;
  plan?: Maybe<SortOrderEnum>;
  origin?: Maybe<SortOrderEnum>;
  createdAt?: Maybe<SortOrderEnum>;
  updatedAt?: Maybe<SortOrderEnum>;
};

export type Subscription = {
  __typename?: 'Subscription';
  pong: Scalars['String'];
};

export type TransObject = {
  __typename?: 'TransObject';
  en?: Maybe<Scalars['String']>;
  es?: Maybe<Scalars['String']>;
  pt_br?: Maybe<Scalars['String']>;
};

export type TransObjectInput = {
  en?: Maybe<Scalars['String']>;
  es?: Maybe<Scalars['String']>;
  pt_br?: Maybe<Scalars['String']>;
};

export enum TransactionOperationEnum {
  Deposit = 'Deposit',
  Withdraw = 'Withdraw'
}

export enum TransactionTypeEnum {
  Deposit = 'Deposit',
  Withdraw = 'Withdraw',
  Transfer = 'Transfer',
  Gift = 'Gift',
  Cashback = 'Cashback',
  VoucherGerCode = 'VoucherGerCode',
  VoucherConfirm = 'VoucherConfirm',
  VoucherExpire = 'VoucherExpire',
  CouponGerCode = 'CouponGerCode',
  CouponConfirm = 'CouponConfirm',
  CouponCancel = 'CouponCancel',
  CouponExpire = 'CouponExpire'
}


export type UpsertLinkReferrerInput = {
  name: Scalars['String'];
  tags?: Maybe<Array<Scalars['String']>>;
  description?: Maybe<Scalars['String']>;
  slug: Scalars['String'];
  redirectUrl: Scalars['String'];
  active?: Maybe<Scalars['Boolean']>;
  tableType: ReferrerTableTypeEnum;
  tableId?: Maybe<Scalars['ID']>;
};

export type UpsertLinkReferrerResponse = {
  __typename?: 'UpsertLinkReferrerResponse';
  message: Scalars['String'];
  data: LinkReferrer;
};

export type User = {
  __typename?: 'User';
  _id: Scalars['ID'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  username: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  groups: Array<Maybe<Scalars['String']>>;
  enabled?: Maybe<Scalars['Boolean']>;
  emailVerified?: Maybe<Scalars['Boolean']>;
  createdAt: CustomDate;
};

export type UserCreateInput = {
  firstName: Scalars['String'];
  lastName?: Maybe<Scalars['String']>;
  username: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  emailVerified?: Maybe<Scalars['Boolean']>;
  password?: Maybe<Scalars['String']>;
};

export type UserDetailed = {
  __typename?: 'UserDetailed';
  _id: Scalars['ID'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  username: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  groups: Array<Maybe<Scalars['String']>>;
  enabled?: Maybe<Scalars['Boolean']>;
  emailVerified?: Maybe<Scalars['Boolean']>;
  createdAt: CustomDate;
  roles: Array<Maybe<Scalars['String']>>;
};

export type UserDetailedResponse = {
  __typename?: 'UserDetailedResponse';
  message: Scalars['String'];
  data?: Maybe<UserDetailed>;
};

export type UserFilterInput = {
  ids: Array<Scalars['String']>;
  forceAll?: Maybe<Scalars['Boolean']>;
};

export type UserKeycloak = {
  __typename?: 'UserKeycloak';
  name?: Maybe<Scalars['String']>;
  sub: Scalars['String'];
  email_verified?: Maybe<Scalars['Boolean']>;
  username: Scalars['String'];
  email?: Maybe<Scalars['String']>;
};

export type UserNote = {
  __typename?: 'UserNote';
  id: Scalars['String'];
  name: Scalars['String'];
  username: Scalars['String'];
};

export type UserOperator = {
  __typename?: 'UserOperator';
  keycloak: Scalars['String'];
  username: Scalars['String'];
};

export type UserResponse = {
  __typename?: 'UserResponse';
  message?: Maybe<Scalars['String']>;
  edges: Array<Maybe<User>>;
};

export type UserSearchInput = {
  search?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  /** Quantidade de registros */
  max?: Maybe<Scalars['Int']>;
};

export type UserUpdateDataInput = {
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emailVerified?: Maybe<Scalars['Boolean']>;
  password?: Maybe<Scalars['String']>;
};

export type UsersWorkspace = {
  __typename?: 'UsersWorkspace';
  user: User;
  role?: Maybe<Role>;
  createdAt: CustomDate;
  updatedAt: CustomDate;
};

export type Validation = {
  __typename?: 'Validation';
  message: Scalars['String'];
  field: Scalars['String'];
  validation: Scalars['String'];
};

export type VoucherConfig = {
  __typename?: 'VoucherConfig';
  quantity: Scalars['Int'];
  passCoin: Scalars['Int'];
};

export type VoucherConfigInput = {
  quantity: Scalars['Int'];
  passCoin: Scalars['Int'];
};

export type VoucherInfo = {
  __typename?: 'VoucherInfo';
  qtGenerated: Scalars['Int'];
  qtUsed: Scalars['Int'];
  qtCanceled: Scalars['Int'];
  qtAvailable: Scalars['Int'];
};

export type Wallet = {
  __typename?: 'Wallet';
  _id: Scalars['ID'];
  tableType: WalletTableTypeEnum;
  tableId: Scalars['ID'];
  balance: Scalars['Int'];
  transactions: Array<WalletTransaction>;
  createdAt: CustomDate;
  updatedAt: CustomDate;
};

export type WalletOperationLocalInput = {
  operation: TransactionOperationEnum;
  description?: Maybe<Scalars['String']>;
  internalDescription?: Maybe<Scalars['String']>;
  local: Scalars['ID'];
  amount: Scalars['Float'];
};

export type WalletSearchInput = {
  tableType?: Maybe<WalletTableTypeEnum>;
  tableId?: Maybe<Scalars['ID']>;
  _id?: Maybe<Scalars['ID']>;
};

export type WalletSimple = {
  __typename?: 'WalletSimple';
  _id: Scalars['ID'];
  tableType: WalletTableTypeEnum;
  tableId: Scalars['ID'];
  balance: Scalars['Int'];
  createdAt: CustomDate;
  updatedAt: CustomDate;
};

export enum WalletTableTypeEnum {
  Admin = 'Admin',
  Local = 'Local',
  Fund = 'Fund',
  Client = 'Client'
}

export type WalletTransaction = {
  __typename?: 'WalletTransaction';
  _id: Scalars['ID'];
  type: TransactionTypeEnum;
  operation: TransactionOperationEnum;
  title: Scalars['String'];
  balance: Scalars['Int'];
  grossAmount: Scalars['Int'];
  deposit?: Maybe<Scalars['Int']>;
  withdraw?: Maybe<Scalars['Int']>;
  fee?: Maybe<Scalars['Int']>;
  fund?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  internalDescription?: Maybe<Scalars['String']>;
  beneficiary?: Maybe<Scalars['String']>;
  userOperator?: Maybe<UserOperator>;
  local?: Maybe<AppLocal>;
  client?: Maybe<ClientPublic>;
  ref?: Maybe<Scalars['String']>;
  promotion?: Maybe<Scalars['ID']>;
  promotionCode?: Maybe<Scalars['String']>;
  createdAt: CustomDate;
};

export type WalletTransferInput = {
  fromWallet: Scalars['ID'];
  toWallet: Scalars['ID'];
  amount: Scalars['Float'];
  description: Scalars['String'];
  internalDescription?: Maybe<Scalars['String']>;
};

export type Workspace = {
  __typename?: 'Workspace';
  _id: Scalars['ID'];
  name: Scalars['String'];
  slug: Scalars['String'];
  enable: Scalars['Boolean'];
  origin: Scalars['String'];
  plan?: Maybe<Plan>;
  users: Array<Maybe<UsersWorkspace>>;
  createdAt: CustomDate;
  updatedAt: CustomDate;
};

export type WorkspaceConditionAndInput = {
  plan?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  enable?: Maybe<Scalars['Boolean']>;
  origin?: Maybe<Scalars['String']>;
};

export type WorkspaceCreateInput = {
  name: Scalars['String'];
  slug: Scalars['String'];
  enable: Scalars['Boolean'];
  origin: Scalars['String'];
};

export type WorkspaceFilterInput = {
  ids: Array<Scalars['ID']>;
  forceAll?: Maybe<Scalars['Boolean']>;
};

/** Exibição minima dos dados */
export type WorkspaceMin = {
  __typename?: 'WorkspaceMin';
  _id: Scalars['ID'];
  name: Scalars['String'];
  slug: Scalars['String'];
  createdAt: CustomDate;
};

export type WorkspaceResponse = {
  __typename?: 'WorkspaceResponse';
  message?: Maybe<Scalars['String']>;
  edges: Array<Maybe<Workspace>>;
  pagination?: Maybe<Pagination>;
};

export type WorkspaceSearchInput = {
  q?: Maybe<Scalars['String']>;
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
  sort?: Maybe<SortWorkspaceInput>;
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  and?: Maybe<WorkspaceConditionAndInput>;
};

export type WorkspaceUpdateDataInput = {
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  enable?: Maybe<Scalars['Boolean']>;
  origin?: Maybe<Scalars['String']>;
};
