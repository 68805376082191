import React, { useEffect } from 'react'
import { gql, useLazyQuery } from '@apollo/client'
import { useKeycloak } from '@react-keycloak/web'
import { useHistory, useParams } from 'react-router-dom'
import { makeStyles } from '@material-ui/core'

import {
  AppPromotion,
  AppPromotionMyStatusEnum,
  PromotionTypeEnum,
  ReferrerTableTypeEnum,
} from '../../generated/graphql'
import {
  AboutVoucher,
  HowItWorks,
  InsuficientPasscoin,
  Loading,
  PromoCodeCard,
  PromotionCard,
  RequireLogin,
  Rules,
  TakePromotion,
} from '../../components'
import ShareCard from '../../components/Promotion/ShareCard'
import SoldOut from '../../components/Promotion/SoldOut'
import IncompleteClient from '../../components/Promotion/IncompleteClient'
import AboutCupon from '../../components/Promotion/AboutCupon'
import { setRef, setRefPage } from '../../utils/reference'

const APP_PROMOTION = gql`
  query AppPromotion($slug: String!) {
    appPromotion(slug: $slug) {
      _id
      title
      slug
      resume
      thumb
      type
      rules
      config {
        brl
        passCoin
        cashbackInPassCoin
      }
      myInfo {
        status
        coinMissing
        promoCode {
          code
          expireIn {
            timestamp
            format(format: "DD/MM/y HH:mm:ss")
          }
        }
      }
      startDate {
        format(format: "DD/MM/y")
      }
      endDate {
        format(format: "DD/MM/y")
      }
      local {
        name
        thumb
        slug
        address {
          city
          stateCode
        }
      }
    }
  }
`

const useStyles = makeStyles(theme => ({
  root: {
    width: '100vw',
    padding: ' 0 10px 10px 10px',
    [theme.breakpoints.up('sm')]: {
      width: '100%',
    },
  },
}))

const PromoDetail: React.FC = () => {
  const classes = useStyles()
  const history = useHistory()
  const { slug } = useParams<{ slug: string }>()
  const { keycloak } = useKeycloak()

  const [loadPromotion, { data, loading }] = useLazyQuery<{
    appPromotion: AppPromotion
  }>(APP_PROMOTION, {
    onCompleted: promo => {
      if (promo) {
        setRefPage(ReferrerTableTypeEnum.Promotion, promo.appPromotion._id)
      }
    },
  })

  const renderByUserPasscoin = () => {
    if (!data) {
      return ''
    }

    if (
      !keycloak.authenticated ||
      data?.appPromotion.myInfo.status === AppPromotionMyStatusEnum.Guest
    ) {
      return <RequireLogin />
    }

    switch (data.appPromotion.myInfo.status) {
      case AppPromotionMyStatusEnum.InsufficientCoin:
        return (
          <InsuficientPasscoin
            needPasscoin={data.appPromotion.myInfo.coinMissing}
          />
        )

      case AppPromotionMyStatusEnum.Available:
        return (
          <TakePromotion
            promotion={data.appPromotion}
            onCloseDialog={() => loadPromotion({ variables: { slug } })}
          />
        )

      case AppPromotionMyStatusEnum.HasTicket:
        return (
          <PromoCodeCard
            voucherCode={data?.appPromotion?.myInfo.promoCode?.code || '0'}
            expirateAt={
              data?.appPromotion?.myInfo.promoCode?.expireIn.timestamp
            }
            expirateAtString={
              data?.appPromotion?.myInfo.promoCode?.expireIn.format || ''
            }
            promoType={data?.appPromotion.type}
          />
        )
      case AppPromotionMyStatusEnum.SoldOut:
        return <SoldOut />
      case AppPromotionMyStatusEnum.ClientIncomplete:
        return <IncompleteClient />

      default:
        return <span>{data.appPromotion.myInfo.status}</span>
    }
  }

  useEffect(() => {
    if (slug) {
      loadPromotion({ variables: { slug } })
    } else {
      history.push('/')
    }
  }, [slug, loadPromotion, history])

  if (loading) {
    return <Loading />
  }

  if (!data) {
    return <h2>Página não encontrada.</h2>
  }

  return (
    <div className={classes.root}>
      <PromotionCard promotion={data.appPromotion} />

      {renderByUserPasscoin()}

      <ShareCard
        text={data.appPromotion.resume}
        title={data.appPromotion.title}
      />

      <HowItWorks type={data.appPromotion.type} />
      {data.appPromotion.type === PromotionTypeEnum.Coupon && <AboutCupon />}
      {data.appPromotion.type === PromotionTypeEnum.Voucher && <AboutVoucher />}

      <Rules rules={data.appPromotion.rules} />
    </div>
  )
}

export default PromoDetail
