import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Link } from '@material-ui/core'
import defaulBg from '../../assets/img/default-bg.jpg'
import IconByTypePromotion from './IconByTypePromotion'
import { AppLocal, PromotionTypeEnum } from '../../generated/graphql'

const useStyles = makeStyles(() => ({
  root: {
    height: 65,
    width: 65,
  },
  avatar: {
    width: 65,
    height: 65,
    background: '#f1f2f6',
    borderWidth: 0.1,
    borderRadius: 10,
  },
}))

type Props = {
  local: AppLocal
  promoType?: PromotionTypeEnum
}
const LocalSticker: React.FC<Props> = ({ local, promoType }) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Link href={`/locais/${local?.slug}`}>
        <img
          alt={local?.name}
          src={local?.thumb || defaulBg}
          className={classes.avatar}
        />
        {promoType && (
          <div style={{ marginTop: '-42px' }}>
            <IconByTypePromotion type={promoType} />
          </div>
        )}
      </Link>
    </div>
  )
}
export default LocalSticker
