import React from 'react'
import { useHistory } from 'react-router-dom'

import { createStyles, makeStyles, Theme, Avatar } from '@material-ui/core'
import { HomeWork, Room } from '@material-ui/icons'
import { red } from '@material-ui/core/colors'

import { AppLocal } from '../../generated/graphql'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: 150,
      width: '100%',
      minWidth: 100,
      padding: 2,
    },
    rootHeader: {
      padding: '2px 2px 0 2px',
    },
    titleHeader: {
      fontSize: '0.6rem',
      fontWeight: 'bold',
      wordBreak: 'break-all',
      lineHeight: '0.65rem',
    },
    media: {
      height: 0,
      paddingTop: '56.25%', // 16:9
    },
    expand: {
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: 'rotate(180deg) ',
    },
    avatar: {
      backgroundColor: red[500],
      width: 16,
      height: 16,
      marginRight: 0,
      position: 'absolute',
      left: '7px',
      marginTop: '4px',
    },
    rootContent: {
      padding: '2px!important ',
    },
    text: {
      fontSize: '0.55rem',
      lineHeight: '0.65rem',
      wordBreak: 'break-all',
    },
  }),
)

type Props = {
  lat: number
  lng: number
  text: string
  local?: AppLocal
}
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const MapMarker: React.FC<Props> = ({ lat, lng, text, local }) => {
  const classes = useStyles()
  const history = useHistory()

  if (!local) {
    return null
  }

  return (
    <div>
      {/*<Card*/}
      {/*  className={classes.root}*/}
      {/*  onClick={() => history.push(`/locais/${local.slug}`)}*/}
      {/*>*/}
      {/*  <CardHeader*/}
      {/*    classes={{*/}
      {/*      root: classes.rootHeader,*/}
      {/*      title: classes.titleHeader,*/}
      {/*    }}*/}
      {/*    title={local.name}*/}
      {/*  />*/}
      {/*  <CardContent className={classes.rootContent}>*/}
      {/*    <Typography*/}
      {/*      className={classes.text}*/}
      {/*      variant="body2"*/}
      {/*      color="textSecondary"*/}
      {/*      component="p"*/}
      {/*    >*/}
      {/*      {local.address?.street},{local.address?.number}*/}
      {/*    </Typography>*/}
      {/*  </CardContent>*/}
      {/*</Card>*/}

      <div>
        <Avatar
          aria-label="recipe"
          className={classes.avatar}
          alt={local.name}
          src={local.thumb || ''}
          style={{ marginRight: '-13px', cursor: 'pointer' }}
          onClick={() => history.push(`/locais/${local.slug}`)}
        >
          {!local.thumb && <HomeWork />}
        </Avatar>
        <Room />
      </div>
    </div>
  )
}
export default MapMarker
