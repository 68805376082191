import React from 'react'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import { Box } from '@material-ui/core'
import AlertCard from '../AlertCard/AlertCard'

const useStyles = makeStyles(() => ({
  root: {
    margin: 5,
    padding: 5,
  },
}))

const EmptyClient: React.FC = () => {
  const classes = useStyles()
  const history = useHistory()

  return (
    <div className={classes.root}>
      <Box style={{ marginBottom: 10, background: 'white' }} boxShadow={0}>
        <AlertCard
          type="info"
          link="Clique aqui para
           finalizar."
          onClick={() => history.push('/perfil')}
        >
          Você ainda não finalizou o seu cadastro. Que tal finalizá-lo agora?
        </AlertCard>
      </Box>
    </div>
  )
}
export default EmptyClient
