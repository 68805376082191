import Keycloak from 'keycloak-js'

// eslint-disable-next-line import/prefer-default-export
export const keycloakLogout = (
  kc: Keycloak.KeycloakInstance,
  pathName = '/',
): void => {
  const redirectUri = `${window.location.origin}${pathName}`

  const urlLogout = kc
    .createLogoutUrl({ redirectUri })
    .replace('redirect_uri', 'post_logout_redirect_uri')

  window.location.href = `${urlLogout}&id_token_hint=${kc.idToken}`
}
