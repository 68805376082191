import React from 'react'
import { useHistory } from 'react-router-dom'

import {
  makeStyles,
  CardContent,
  Typography,
  CardActions,
  Button,
  Card,
} from '@material-ui/core'

import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined'
import { ErrorOutline, ExitToAppOutlined } from '@material-ui/icons'

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: 5,
    marginBottom: 5,
    borderRadius: 0,
    borderBottomRightRadius: 10,
    borderBottomLeftRadius: 10,
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      marginLeft: 5,
      marginRight: 5,
    },
  },
  cardContentVoucher: {
    display: 'flex',
    flexDirection: 'column',
    padding: 10,
    margin: 0,
  },
  contentTitle: {
    fontWeight: 'bold',
    margin: 0,
  },
  cardActionVoucher: {
    display: 'flex',
    justifyContent: 'center',
  },
  cardContentResponse: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
}))
type Props = {
  message: string
}

const RequireLoginCard: React.FC<Props> = ({ message }) => {
  const classes = useStyles()
  const history = useHistory()

  return (
    <>
      <Card className={classes.root}>
        <CardContent className={classes.cardContentResponse}>
          <Typography variant="h6" className={classes.contentTitle}>
            Você não está logado!
          </Typography>

          <ErrorOutline style={{ color: '#e74c3c', fontSize: '4rem' }} />

          <Typography variant="subtitle2" style={{ margin: 0 }}>
            {message}
          </Typography>

          <CardActions className={classes.cardActionVoucher}>
            <Button
              variant="outlined"
              endIcon={<ExitToAppOutlined />}
              onClick={() =>
                history.push(`/login?redirect=${window.location.pathname}`)
              }
            >
              Entrar
            </Button>
          </CardActions>
        </CardContent>
      </Card>
    </>
  )
}
export default RequireLoginCard
