/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

export const stateDrawer = (state: boolean) => ({
  type: '@environment/SET_STATE_DRAWER',
  payload: { state },
})

export const stateVoucher = (state: string) => ({
  type: '@environment/SET_STATE_VOUCHER',
  payload: { state },
})

export const stateWelcome = (state: boolean) => ({
  type: '@environment/SET_STATE_WELCOME',
  payload: { state },
})
