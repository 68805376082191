/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { gql, useQuery } from '@apollo/client'

import {
  Card,
  makeStyles,
  CardHeader,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Divider,
  Button,
  Typography,
  Box,
  FormControl,
  Input,
  InputAdornment,
} from '@material-ui/core'

import { Search, Loop, HomeWork } from '@material-ui/icons'

import { AppLocal, AppLocalResponse } from '../../generated/graphql'

import { WarningBox, EmptyState, MapContainer, Loading } from '../../components'
import { WarningType } from '../../types'

const useStyles = makeStyles(theme => ({
  root: {
    margin: '0 5px',
    padding: '0 5px',
  },
  containerList: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  row: {
    display: 'flex',
    justifyContent: 'center',
    justifyItems: 'center',
    alignContent: 'center',
    alignItems: 'center',
  },
  formControl: {
    margin: theme.spacing(1),
    marginTop: 0,
    width: '100%',
    maxWidth: 300,
  },
  filterTitle: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    fontWeight: 'bold',
    padding: 10,
  },
  searchRoot: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
}))

export const GET_LOCALS = gql`
  query AppLocals($params: LocalSearchInput) {
    appLocals(params: $params) {
      edges {
        name
        slug
        description
        thumb
        cover
        address {
          stateCode
          city
          street
          number
          location {
            coordinates
          }
        }
      }
      pagination {
        lastPage
        page
        perPage
        total
      }
    }
  }
`

const warnings: WarningType[] = [
  {
    title: ' Esta lista pode estar desatualizada. ',
    description: 'Para confirmar  entre em contato com a local.',
  },
]

const Locations: React.FC = () => {
  const history = useHistory()
  const classes = useStyles()
  const [search, setSearch] = useState('')
  const [fullMap, setFullMap] = useState(true)
  const [pageTable, setPageTable] = useState(1)
  const [dataTable, setDataTable] = useState<AppLocal[]>([])

  const { loading, data } = useQuery<{
    appLocals: AppLocalResponse
  }>(GET_LOCALS, {
    variables: {
      params: {
        perPage: 1000,
      },
    },
  })

  const resultsPerPage = 15

  const onPageChangeTable = () => {
    setPageTable(pageTable + 1)
  }

  useEffect(() => {
    if (data && data.appLocals && data.appLocals.edges) {
      setDataTable(data.appLocals.edges.slice(0, pageTable * resultsPerPage))
    }
  }, [pageTable, data])

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY >= 200) {
        setFullMap(false)
      }

      if (window.scrollY === 0) {
        setFullMap(true)
      }
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const changeSearch = (s: string) => {
    setSearch(s)
    if (data && data.appLocals && data.appLocals.edges && s) {
      setDataTable(data.appLocals.edges.filter(loc => loc.name.includes(s)))
    } else {
      setPageTable(1)
    }
  }
  const getMapItens = () => {
    if (data && data.appLocals && data.appLocals.edges) {
      return data.appLocals.edges
    }
    return []
  }
  if (loading) {
    return <Loading />
  }

  return (
    <div className={classes.root}>
      {!fullMap && (
        <>
          <Box style={{ marginBottom: 10, background: 'white' }} boxShadow={0}>
            <CardHeader
              avatar={<HomeWork style={{ position: 'absolute', top: 104 }} />}
              title={
                <Typography
                  variant="h5"
                  style={{ fontWeight: 'bold', textAlign: 'center' }}
                >
                  Locais
                </Typography>
              }
            />
          </Box>
          <WarningBox warningList={warnings} />
        </>
      )}
      <div
        style={{ marginTop: 10, marginBottom: 10 }}
        onClick={() => setFullMap(true)}
        role="button"
        tabIndex={0}
      >
        <MapContainer
          items={getMapItens()}
          height={fullMap ? '75vh' : '35vh'}
        />
      </div>

      <Card
        className={classes.containerList}
        onClick={() => setFullMap(false)}
        role="button"
        tabIndex={0}
      >
        <div className={classes.filterTitle}>
          <Typography variant="h6">Buscar locais</Typography>
        </div>

        <div className={classes.row}>
          <FormControl className={classes.formControl}>
            <Input
              id="input-with-icon-adornment"
              placeholder="Buscar... "
              value={search}
              onChange={event => changeSearch(event.target.value)}
              startAdornment={
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              }
            />
          </FormControl>
        </div>

        <Divider style={{ marginTop: 10 }} />

        <div
          style={{
            margin: 5,
            padding: 10,
            borderRadius: 20,
          }}
        >
          {(!dataTable || !data) && (
            <EmptyState message="Sem locais no momento." />
          )}

          {dataTable.map(item => (
            <div key={item.slug}>
              <List component="nav" aria-label="main mailbox folders">
                <ListItem
                  button
                  onClick={() => history.push(`/locais/${item.slug}`)}
                >
                  <ListItemAvatar>
                    <Avatar alt={item.name} src={item.thumb || ''}>
                      {!item.thumb && <HomeWork />}
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={item.name}
                    secondary={`${item.address?.city} ${item.address?.stateCode}`}
                  />
                </ListItem>
              </List>
              <Divider />
            </div>
          ))}
        </div>

        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            style={{ margin: 15 }}
            variant="outlined"
            color="primary"
            endIcon={<Loop />}
            onClick={onPageChangeTable}
            disabled={dataTable.length === data?.appLocals.edges.length}
          >
            Carregar mais
          </Button>
        </div>
      </Card>
    </div>
  )
}

export default Locations
