import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Typography,
} from '@material-ui/core'
import { HowToReg, VpnKey } from '@material-ui/icons'
import { useKeycloak } from '@react-keycloak/web'

import Banner from '../assets/img/banner.jpg'

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: 5,
    marginBottom: 5,
    borderRadius: 0,
    borderBottomRightRadius: 10,
    borderBottomLeftRadius: 10,
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
      marginRight: 0,
    },
    backgroundImage: `url(${Banner})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  },
  cardContentVoucher: {
    display: 'flex',
    flexDirection: 'column',
    padding: 10,
    margin: 0,
  },
  contentTitle: {
    fontWeight: 'bold',
    margin: 0,
    color: '#f4b400',
  },
  cardActionVoucher: {
    display: 'flex',
    justifyContent: 'center',
  },
}))

const NotAccountCard: React.FC = () => {
  const classes = useStyles()
  const { keycloak } = useKeycloak()

  return (
    <Card className={classes.root}>
      <CardContent className={classes.cardContentVoucher}>
        <Typography variant="h6" className={classes.contentTitle}>
          Acesse sua conta!
        </Typography>

        <Typography variant="subtitle2" style={{ margin: 0, color: '#fff' }}>
          Acesse sua conta ou cadastre-se para poder aproveitar as nossas
          promoções!
        </Typography>
      </CardContent>

      <CardActions className={classes.cardActionVoucher}>
        <Button
          variant="contained"
          size="small"
          endIcon={<HowToReg />}
          onClick={() => keycloak.register()}
          style={{
            backgroundColor: '#f4b400',
            borderColor: '#064326',
            color: '#000',
          }}
        >
          Criar conta
        </Button>
        <Button
          variant="outlined"
          size="small"
          endIcon={<VpnKey />}
          onClick={() => keycloak.login()}
          style={{ borderColor: '#f4b400', color: '#fff' }}
        >
          Entrar
        </Button>
      </CardActions>
    </Card>
  )
}
export default NotAccountCard
