import Keycloak from 'keycloak-js'
import { Config } from './config/config'

// Setup Keycloak instance as needed
// Pass initialization options as required or leave blank to load from 'keycloak.json'

export const keycloak = Keycloak({
  realm: Config.keycloak.realm,
  url: Config.keycloak.url,
  clientId: Config.keycloak.clientId,
})

export const keycloakProviderInitConfig: Keycloak.KeycloakInitOptions = {
  onLoad: 'check-sso',
  silentCheckSsoRedirectUri: `${window.location.origin}/silent-check-sso.html`,
}
