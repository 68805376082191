import { useEffect, useState } from 'react'

const useCountdown = (targetDate: string): any => {
  const countDownDate = new Date(targetDate).getTime()

  const [countDown, setCountDown] = useState<number>(
    countDownDate - new Date().getTime(),
  )

  useEffect(() => {
    const interval = setInterval(() => {
      setCountDown(countDownDate - new Date().getTime())
    }, 1000)

    return () => clearInterval(interval)
  }, [countDownDate])

  const getReturnValues = (countDownNumber: number) => {
    // calculate time left
    const days = Math.floor(countDownNumber / (1000 * 60 * 60 * 24))
    const hours = Math.floor(
      (countDownNumber % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
    )
    const minutes = Math.floor(
      (countDownNumber % (1000 * 60 * 60)) / (1000 * 60),
    )
    const seconds = Math.floor((countDownNumber % (1000 * 60)) / 1000)

    return [days, hours, minutes, seconds]
  }

  return getReturnValues(countDown)
}

export default useCountdown
