import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useKeycloak } from '@react-keycloak/web'
import {
  Button,
  Card,
  CardContent,
  makeStyles,
  Typography,
} from '@material-ui/core'
import { HowToReg, InsertEmoticon } from '@material-ui/icons'

const useStyles = makeStyles({
  root: {
    padding: 10,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: '85vh',
    justifyContent: 'center',
  },
  logo: {
    width: '100%',
    maxWidth: '200px',
  },
  content: {},
})

const Welcome: React.FC = () => {
  const history = useHistory()
  const classes = useStyles()
  const { keycloak } = useKeycloak()

  useEffect(() => {
    if (keycloak.authenticated) {
      history.push('/extrato')
    }
  }, [keycloak.authenticated, history])

  return (
    <Card className={classes.root}>
      <CardContent
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginBottom: '20px',
          }}
        >
          <InsertEmoticon style={{ color: '#064326', fontSize: '5rem' }} />
        </div>
        <Typography
          variant="h5"
          style={{
            fontWeight: 'bold',
            textAlign: 'center',
            marginBottom: '0px',
          }}
        >
          Bem vindo
        </Typography>
        <p style={{ textAlign: 'center' }}>
          {' '}
          Estamos quase lá! <br />
          Primeiro preencha seu cadastro. Logo depois o seu telefone e então as
          suas PassCoins serão creditadas na sua conta.{' '}
        </p>

        <Button
          variant="contained"
          endIcon={<HowToReg />}
          onClick={() => keycloak.register()}
          style={{
            backgroundColor: '#064326',
            borderColor: '#064326',
            color: '#fff',
          }}
        >
          Me cadastrar agora
        </Button>
      </CardContent>
    </Card>
  )
}

export default Welcome
