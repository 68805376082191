import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Card, CardContent, CardHeader, Typography } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: 5,
    marginBottom: 5,
    borderRadius: 0,
    borderBottomRightRadius: 10,
    borderBottomLeftRadius: 10,
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      marginLeft: 5,
      marginRight: 5,
    },
  },
  rulesContainer: {
    display: 'flex',
    flexDirection: 'column',
    // justifyContent: 'space-around',
    // padding: 10,
    textAlign: 'left',
    gap: '10px',
  },
  rules: {
    display: 'flex',
    flexDirection: 'column',
    justifyItems: 'flex-end',
    alignItems: 'start',
    fontSize: 13,
    fontWeight: 'bold',
  },
  rulesIcon: {
    fontSize: 60,
    paddingBottom: 10,
  },
  rulesItem: {
    // display: 'flex',
    // flexDirection: 'row',
  },
  rulesDescription: {
    fontSize: 13,
    lineHeight: 1.1,
  },
}))

const AboutCupon: React.FC = () => {
  const classes = useStyles()

  return (
    <div>
      <Card className={classes.root}>
        <CardHeader
          title={
            <Typography
              variant="h5"
              style={{ fontWeight: 'bold', color: '#064326' }}
            >
              Como funcionam os Cupons?
            </Typography>
          }
        />

        <CardContent className={classes.rulesContainer}>
          <div className={classes.rulesItem}>
            <span>
              {' '}
              Cupons são promoções que não precisam de PassCoins para serem
              usadas.
            </span>
            <span>
              {' '}
              O valor do cupom é pago diretamente ao estabelecimento na hora do
              resgate e na sequência deverá receber o cashback em PassCoins
              conforme prometido em cada cupom.
            </span>
          </div>
        </CardContent>
      </Card>
    </div>
  )
}
export default AboutCupon
