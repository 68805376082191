/* eslint-disable react/destructuring-assignment */
import React, { useContext, useEffect } from 'react'

import { useSelector, useDispatch } from 'react-redux'
import { useKeycloak } from '@react-keycloak/web'
import { useHistory, useLocation } from 'react-router-dom'
import { Player } from '@lottiefiles/react-lottie-player'
import { gql, useLazyQuery } from '@apollo/client'

import {
  Drawer,
  Hidden,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Avatar,
  Box,
  Button,
  AppBar,
  CssBaseline,
} from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import {
  Menu,
  Close,
  Home,
  AssignmentOutlined,
  ExitToAppOutlined,
  LocalActivityOutlined,
  Info,
  HowToReg,
  VpnKey,
  Place,
  ConfirmationNumber,
} from '@material-ui/icons'

import { stateDrawer } from '../../store/actions/environment'
import Logo from '../../image/logo.png'
import { UserKeycloak } from '../../generated/graphql'
import { Config } from '../../config/config'
import { ClientContext } from '../../providers/ClientProvider'
import { keycloakLogout } from '../../utils/keycloak-logout'

const drawerWidth = 240

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
    background: 'white',
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
    position: 'absolute',
    left: 10,
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
  },
  drawerHeader: {
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'center',
  },
  logo: {
    width: 120,
    height: 70,
    padding: 10,
  },
  avatar: {
    width: 40,
    height: 40,
    background:
      'linear-gradient(90deg, rgba(169,68,119,1) 0%, rgba(89,98,151,1) 100%)',
  },
  buttonPoint: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    borderColor: '#064326',
    color: '#064326',
    borderWidth: 2,
    textTransform: 'none',
  },
  buttonClose: {
    display: 'flex',
    position: 'absolute',
    alignItems: 'center',
    top: 20,
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  infoUser: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#f1f2f6',
    paddingLeft: 10,
    cursor: 'pointer',
  },
  detailsUser: {
    paddingLeft: 10,
    fontSize: 15,
    margin: 0,
    whiteSpace: 'normal',
    overflow: 'hidden',
    wordBreak: 'break-all',
  },
  iconList: {
    // color: '#064326',
  },
  points: {
    fontSize: 13,
    justifyContent: 'center',
    textTransform: 'none',
    color: '#064326',
    width: '80px',
  },
}))

interface RootState {
  environment: any
}
const GET_USER_ME = gql`
  query userMe {
    userMe {
      name
      username
      email
      sub
      email_verified
    }
  }
`

// eslint-disable-next-line  @typescript-eslint/explicit-module-boundary-types
function Sidebar(props: any) {
  const { window } = props
  const classes = useStyles()
  const { keycloak } = useKeycloak()
  const theme = useTheme()
  const dispatch = useDispatch()
  const location = useLocation()
  const history = useHistory()
  const { environment } = useSelector((state: RootState) => state)
  const { client } = useContext(ClientContext)

  const navigate = (path?: string) => {
    history.push(`/${path}`)
    dispatch(stateDrawer(false))
  }

  const itemsList = [
    {
      text: 'Início',
      key: '/',
      icon: <Home className={classes.iconList} />,
      onClick: () => navigate(''),
    },
    {
      text: 'Promoções',
      key: '/promocoes',
      icon: <LocalActivityOutlined className={classes.iconList} />,
      onClick: () => navigate('promocoes'),
    },
    {
      text: 'Locais',
      key: '/locais',
      icon: <Place className={classes.iconList} />,
      onClick: () => navigate('locais'),
    },
    {
      text: 'Informações',
      key: '/informacoes',
      icon: <Info className={classes.iconList} />,
      onClick: () => navigate('informacoes'),
    },
  ]

  if (keycloak.authenticated) {
    itemsList.splice(3, 0, {
      text: 'Minhas Promoções',
      key: '/minhas-promocoes',
      icon: <LocalActivityOutlined className={classes.iconList} />,
      onClick: () => navigate('minhas-promocoes'),
    })
    itemsList.splice(4, 0, {
      text: 'Extrato',
      key: '/extrato',
      icon: <AssignmentOutlined className={classes.iconList} />,
      onClick: () => navigate('extrato'),
    })
    itemsList.splice(5, 0, {
      text: 'Ganhe pontos',
      key: '/ganhe-pontos',
      icon: <ConfirmationNumber className={classes.iconList} />,
      onClick: () => navigate('ganhe-pontos'),
    })
    itemsList.push({
      text: 'Sair',
      key: 'Sair',
      icon: <ExitToAppOutlined className={classes.iconList} />,
      onClick: () => keycloakLogout(keycloak),
    })
  } else {
    itemsList.push({
      text: 'Entrar',
      key: 'Entrar',
      icon: <VpnKey className={classes.iconList} />,
      onClick: () => keycloak.login(),
    })
    itemsList.push({
      text: 'Cadastrar',
      key: 'cadastrar',
      icon: <HowToReg className={classes.iconList} />,
      onClick: () => keycloak.register(),
    })
  }

  const [loadUser, { data: userData }] = useLazyQuery<{ userMe: UserKeycloak }>(
    GET_USER_ME,
  )

  useEffect(() => {
    if (keycloak.authenticated) {
      loadUser()
    }
  }, [keycloak.authenticated, loadUser])

  const drawer = (
    <div>
      <Button
        startIcon={<Close />}
        className={classes.buttonClose}
        onClick={() => dispatch(stateDrawer(false))}
      />

      <div className={classes.drawerHeader}>
        <img src={Logo} alt="PassFree" className={classes.logo} />
      </div>

      {userData?.userMe && (
        <Box
          className={classes.infoUser}
          onClick={() => {
            history.push('/perfil')
            dispatch(stateDrawer(false))
          }}
        >
          <Box>
            {userData?.userMe?.name && (
              <Avatar
                alt={userData.userMe.name || 'user image'}
                className={classes.avatar}
              >
                {userData.userMe.name.charAt(0)}
              </Avatar>
            )}
          </Box>

          <div className={classes.detailsUser}>
            <p>
              {userData?.userMe?.name} <br />
              <small>{userData?.userMe?.email}</small>
            </p>
          </div>
        </Box>
      )}

      <div
        style={{
          paddingLeft: 0,
          paddingRight: 0,
        }}
      >
        <List>
          {itemsList.map(item => {
            const { text, icon, onClick, key } = item
            return (
              <div
                key={text + 1}
                style={{
                  color: key === location.pathname ? '#0652DD' : '#064326',
                }}
              >
                <ListItem
                  button
                  key={text}
                  onClick={onClick}
                  style={{ paddingBottom: '0' }}
                >
                  {icon && (
                    <ListItemIcon
                      style={{
                        color:
                          key === location.pathname ? '#0652DD' : '#064326',
                        minWidth: '40px',
                      }}
                    >
                      {icon}
                    </ListItemIcon>
                  )}
                  <ListItemText primary={text} style={{ fontSize: '0.8rem' }} />
                </ListItem>
              </div>
            )
          })}
        </List>

        <div
          style={{
            fontSize: 9,
            bottom: 1,
            position: 'absolute',
          }}
        >
          <p>Version {Config.version}</p>
        </div>
      </div>
    </div>
  )

  const container =
    window !== undefined ? () => window().document.body : undefined

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar
          style={{
            display: 'flex',
            justifyContent: 'center',
            background: '#ffff',
          }}
        >
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="default"
            aria-label="menu"
            onClick={() => dispatch(stateDrawer(true))}
          >
            <Menu />
          </IconButton>
          <img src={Logo} alt="Italian Trulli" className={classes.logo} />

          <div style={{ position: 'absolute', right: 10 }}>
            <Button
              size="small"
              className={classes.points}
              onClick={() => history.push('/extracts')}
            >
              <Player
                autoplay
                src="https://assets1.lottiefiles.com/packages/lf20_c6s0ojkl.json"
                style={{
                  height: '25px',
                  width: '25px',
                  padding: 0,
                  margin: 0,
                }}
              />
              {client ? client.balance : 0} PassCoins
            </Button>
          </div>
        </Toolbar>
      </AppBar>

      <nav className={classes.drawer} aria-label="mailbox folders">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden smUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={environment.drawer}
            onClose={() => dispatch(stateDrawer(false))}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <div className={classes.toolbar} />
      <main className={classes.content}>{props.children}</main>
    </div>
  )
}

export default Sidebar
