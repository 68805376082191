import React from 'react'
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core'
import { AppQrCodeSimulateResponse } from '../../generated/graphql'

type Props = {
  scanResponse: AppQrCodeSimulateResponse | null
  code: string
  handleClose: any
  rescueCode: any
}
const RescueConfirmDialog: React.FC<Props> = ({
  scanResponse,
  code,
  handleClose,
  rescueCode,
}) => {
  if (!scanResponse) {
    return <span>Erro na resposta</span>
  }

  return (
    <>
      <DialogTitle id="alert-dialog-slide-title">
        Resgatar código promocional
      </DialogTitle>
      <DialogContent>
        {scanResponse && (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <p>{scanResponse.message}</p>
            <span>
              Local: <b>{scanResponse.local?.name}</b>
            </span>
            <span>
              Promoção: <b>{scanResponse.promotion?._id}</b>
            </span>
            <div
              style={{
                padding: 12,
                border: '1px dashed #27ae60',
                textAlign: 'center',
                marginTop: 16,
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <strong style={{ color: '#27ae60' }}>{code}</strong>
              <span> Você irá receber</span>
              <h4 style={{ margin: 0 }}>{scanResponse.passCoin} PassCoins</h4>
            </div>
            Clique em confirmar para resgatar.
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary" variant="outlined">
          Cancelar
        </Button>
        <Button
          onClick={rescueCode}
          color="primary"
          variant="outlined"
          style={{ color: '#064326', borderColor: '#064326' }}
        >
          Resgatar
        </Button>
      </DialogActions>
    </>
  )
}
export default RescueConfirmDialog
