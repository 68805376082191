import React from 'react'

import { makeStyles, Typography } from '@material-ui/core'

const useStyles = makeStyles(() => ({
  countDown: {
    display: 'flex',
    flexDirection: 'column',
  },
  countDownContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    textAlign: 'center',
    paddingLeft: 30,
    paddingRight: 30,
    paddingTop: 2,
  },
  countDownCounter: {
    fontWeight: 'bold',
    textAlign: 'center',
  },
  rowCountDown: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: 10,
    paddingRight: 10,
    paddingBottom: 10,
  },
}))

type Props = {
  days: number
  hours: number
  minutes: number
  seconds: number
}

const Expiration: React.FC<Props> = ({ days, hours, minutes, seconds }) => {
  const classes = useStyles()

  return (
    <div className={classes.countDown}>
      <Typography
        variant="subtitle1"
        style={{
          fontWeight: 'bold',
          color: '#064326',
          paddingLeft: 12,
          paddingBottom: 5,
          paddingTop: 5,
        }}
      >
        Seu voucher expira em
      </Typography>

      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <div className={classes.rowCountDown}>
          <Typography variant="h6" className={classes.countDownCounter}>
            {days}
          </Typography>
          <Typography variant="subtitle2">Dias</Typography>
        </div>

        <div className={classes.rowCountDown}>
          <Typography variant="h6" className={classes.countDownCounter}>
            {hours}
          </Typography>
          <Typography variant="subtitle2">Horas</Typography>
        </div>

        <div className={classes.rowCountDown}>
          <Typography variant="h6" className={classes.countDownCounter}>
            {minutes}
          </Typography>
          <Typography variant="subtitle2">Minuto</Typography>
        </div>

        <div className={classes.rowCountDown}>
          <Typography variant="h6" className={classes.countDownCounter}>
            {seconds}
          </Typography>
          <Typography variant="subtitle2">Segundos</Typography>
        </div>
      </div>
    </div>
  )
}
export default Expiration
