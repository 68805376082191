import React, { useEffect, useState } from 'react'

import { Player } from '@lottiefiles/react-lottie-player'
import { useDebouncedCallback } from 'use-debounce'

const Proxy: React.FC = () => {
  const [state, setState] = useState(false)

  const debounced = useDebouncedCallback(() => {
    setState(true)
  }, 2000)

  useEffect(() => {
    debounced.callback()
    return () => {}
  })

  return (
    <>
      {state ? null : (
        <div
          style={{
            display: 'flex',
            background: '#ecf0f1',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'absolute',
            width: '100%',
            height: '100%',
            zIndex: 10,
          }}
        >
          <Player
            autoplay
            loop
            src="https://assets5.lottiefiles.com/packages/lf20_sE2Em1.json"
            style={{ height: '300px', width: '300px' }}
          />
        </div>
      )}
    </>
  )
}

export default Proxy
