/* eslint-disable react/self-closing-comp */
/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import { gql, useLazyQuery } from '@apollo/client'

import Card from '@material-ui/core/Card'
import { makeStyles } from '@material-ui/core/styles'
import {
  Avatar,
  Button,
  CardContent,
  CardHeader,
  CardMedia,
  IconButton,
  Typography,
} from '@material-ui/core'
import {
  Email,
  Facebook,
  HomeOutlined,
  Instagram,
  Language,
  LocationOnOutlined,
  PhoneOutlined,
  Share,
  WhatsApp,
} from '@material-ui/icons'
import { toast } from 'react-toastify'

import { AppLocal, ReferrerTableTypeEnum } from '../../generated/graphql'

import defaulBg from '../../assets/img/default-bg.jpg'
import { Loading, Map, PromotionCard } from '../../components'
import { setRef, setRefPage } from '../../utils/reference'
import { shareOrCoppy } from '../../utils/helpers'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100vw',
    [theme.breakpoints.up('sm')]: {
      width: '100%',
    },
  },
  container: {
    width: '100vw',
    padding: ' 0 10px 10px 10px',
    [theme.breakpoints.up('sm')]: {
      width: '100%',
    },
    marginBottom: '25px',
  },
  card: {
    backgroundImage:
      'url(https://i.pinimg.com/originals/99/5a/b0/995ab01e56123fa638a6a689361e57ce.jpg)',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    borderRadius: 0,
    height: theme.spacing(30),
    // width: '100%',
    color: 'white',
  },

  avatar: {
    width: theme.spacing(8),
    height: theme.spacing(8),
    top: 2,
    background: 'rgba(6, 67, 38, 1.0)',
    marginRight: '8px',
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
    [theme.breakpoints.up('sm')]: {
      paddingTop: '300px', // 16:9
      backgroundSize: 'cotain',
    },
  },
  headerLocal: {
    marginTop: theme.spacing(-6),
    display: 'flex',
    alignItems: 'baseline',
    paddingBottom: 0,
  },
  headerTitle: {
    fontSize: '1.1rem',
  },
  body3: {
    fontSize: '0.8rem',
  },
  cardInfo: {
    display: 'flex',
    flexDirection: 'column',
    background: '#f1f2f6',
    // width: '100%',
    borderRadius: 0,
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
  },
  infoP: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  morePromotion: {
    display: 'flex',
    justifyContent: 'center',
    margin: 10,
  },
  link: {
    color: '#397daf',
    textDecoration: 'none',
  },
}))

export const APP_LOCAL = gql`
  query AppLocal($slug: String!) {
    appLocal(slug: $slug) {
      _id
      name
      slug
      description
      thumb
      cover
      contacts {
        whatsApp
        phone
        mail
        instagram
        facebook
        website
      }
      address {
        city
        stateCode
        street
        number
        district
        complement
        zipCode
        location {
          coordinates
        }
      }
      company {
        cnpj
        razaoSocial
        owner
        phone
        mail
      }
      promotions {
        _id
        title
        slug
        resume
        thumb
        type
        config {
          passCoin
          brl
          cashbackInPassCoin
        }
        myInfo {
          promoCode {
            code
          }
          coinMissing
          status
        }
        startDate {
          format(format: "DD/MM/yy")
        }
        endDate {
          format(format: "DD/MM/yy")
        }
        local {
          name
          thumb
          slug
          address {
            city
            stateCode
          }
        }
      }
    }
  }
`

type AppLocalDetailResponse = {
  appLocal: AppLocal
}

const LocationsProfile: React.FC = () => {
  const classes = useStyles()
  const router = useHistory()
  const { slug } = useParams<{ slug: string }>()

  const [getLocal, { data, loading }] = useLazyQuery<AppLocalDetailResponse>(
    APP_LOCAL,
    {
      onCompleted: local => {
        if (local) {
          setRefPage(ReferrerTableTypeEnum.Local, local.appLocal._id)
        }
      },
    },
  )

  useEffect(() => {
    if (slug) {
      getLocal({ variables: { slug } })
    } else {
      router.push('/locais')
    }
  }, [slug, getLocal, router])

  if (loading) {
    return <Loading />
  }

  if (!data || !data.appLocal) {
    return <h3>Página não encontrada</h3>
  }

  const share = () => {
    shareOrCoppy(
      window.location.href,
      data.appLocal.name,
      `${data.appLocal.description}`,
    )
  }

  function makeWhatsappNumber(whatsApp: string) {
    return `+55${whatsApp.replace(/[^\d+]/g, '').replace('+', '')}`
  }

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        {/*<Card*/}
        {/*    className={classes.card}*/}
        {/*    style={{*/}
        {/*        backgroundImage: `url(${*/}
        {/*            data.appLocal.cover ? data.appLocal.cover : defaulBg*/}
        {/*        })`,*/}
        {/*    }}*/}
        {/*>*/}
        {/*</Card>*/}

        <Card className={classes.cardInfo}>
          <CardMedia
            className={classes.media}
            image={data.appLocal.cover ? data.appLocal.cover : defaulBg}
            title={data.appLocal.name}
          />

          <CardHeader
            avatar={
              data.appLocal.thumb ? (
                <Avatar className={classes.avatar} src={data.appLocal.thumb} />
              ) : (
                <Avatar className={classes.avatar}>
                  {' '}
                  {data.appLocal.name.charAt(0)}
                </Avatar>
              )
            }
            titleTypographyProps={{ variant: 'h6' }}
            title={data.appLocal.name}
            classes={{
              root: classes.headerLocal,
              title: classes.headerTitle,
            }}
          ></CardHeader>
          <CardContent>
            <Typography
              variant="body2"
              color="textSecondary"
              component="p"
              className={classes.body3}
            >
              {data.appLocal.description}
            </Typography>

            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'baseline',
                gap: '4px',
                marginBottom: '25px',
                marginTop: '20px',
              }}
            >
              {data.appLocal.contacts?.phone && (
                <Button
                  href={`tel:${data.appLocal.contacts.phone}`}
                  target="_blank"
                  size="small"
                  rel="noreferrer"
                  color="primary"
                  startIcon={<PhoneOutlined />}
                >
                  <small> {data.appLocal.contacts.phone}</small>
                </Button>
              )}

              {data.appLocal.contacts?.whatsApp && (
                <Button
                  href={`https://wa.me/${makeWhatsappNumber(
                    data.appLocal.contacts?.whatsApp,
                  )}`}
                  target="_blank"
                  size="small"
                  rel="noreferrer"
                  color="primary"
                  startIcon={<WhatsApp />}
                >
                  <small>{data.appLocal.contacts.whatsApp}</small>
                </Button>
              )}

              {data.appLocal.contacts?.mail && (
                <Button
                  href={`mailto:${data.appLocal.contacts.mail}`}
                  target="_blank"
                  size="small"
                  rel="noreferrer"
                  color="primary"
                  startIcon={<Email />}
                  style={{
                    wordBreak: 'break-all',
                    lineHeight: 1,
                    textTransform: 'none',
                  }}
                >
                  <small>{data.appLocal.contacts.mail}</small>
                </Button>
              )}

              {data.appLocal.contacts?.instagram && (
                <Button
                  href={`https://www.instagram.com/${data.appLocal.contacts.instagram}`}
                  target="_blank"
                  size="small"
                  rel="noreferrer"
                  color="primary"
                  startIcon={<Instagram />}
                  style={{
                    wordBreak: 'break-all',
                    lineHeight: 1,
                    textTransform: 'none',
                  }}
                >
                  <small>@{data.appLocal.contacts.instagram}</small>
                </Button>
              )}

              {data.appLocal.contacts?.facebook && (
                <Button
                  href={`https://www.facebook.com/${data.appLocal.contacts.facebook}`}
                  target="_blank"
                  size="small"
                  rel="noreferrer"
                  color="primary"
                  startIcon={<Facebook />}
                  style={{
                    wordBreak: 'break-all',
                    lineHeight: 1,
                    textTransform: 'none',
                  }}
                >
                  <small>@{data.appLocal.contacts.facebook}</small>
                </Button>
              )}

              {data.appLocal.contacts?.website && (
                <Button
                  href={data.appLocal.contacts.website}
                  target="_blank"
                  size="small"
                  rel="noreferrer"
                  color="primary"
                  startIcon={<Language />}
                  style={{
                    wordBreak: 'break-all',
                    lineHeight: 1,
                    textTransform: 'none',
                  }}
                >
                  <small>{data.appLocal.contacts.website}</small>
                </Button>
              )}
            </div>

            {data.appLocal.address?.location && (
              <Map
                lat={data.appLocal.address?.location?.coordinates[0]}
                lng={data.appLocal.address?.location?.coordinates[1]}
                margin={0}
              />
            )}
            <p className={classes.infoP}>
              <LocationOnOutlined
                fontSize="small"
                style={{ marginRight: 10 }}
              />
              <small>
                {' '}
                {data.appLocal.address?.street},{data.appLocal.address?.number}{' '}
                {data.appLocal.address?.district}{' '}
                {data.appLocal.address?.complement} -
                {data.appLocal.address?.city}-{data.appLocal.address?.stateCode}{' '}
                {data.appLocal.address?.zipCode}
              </small>
            </p>
          </CardContent>

          <div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-around',
              }}
            >
              {data.appLocal.contacts?.instagram && (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <IconButton
                    size="small"
                    href={data.appLocal.contacts.instagram}
                    target="_blank"
                  >
                    <Instagram fontSize="small" color="secondary" />
                  </IconButton>
                </div>
              )}
              {data.appLocal.contacts?.facebook && (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <IconButton
                    style={{ margin: 2, padding: 0 }}
                    href={data.appLocal.contacts.facebook}
                    target="_blank"
                  >
                    <Facebook fontSize="small" color="primary" />
                  </IconButton>
                </div>
              )}

              {data.appLocal.contacts?.website && (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <IconButton
                    style={{ margin: 2, padding: 0 }}
                    href={data.appLocal.contacts.website}
                    target="_blank"
                  >
                    <Language fontSize="small" color="primary" />
                  </IconButton>
                </div>
              )}
              <IconButton onClick={share} size="small">
                <Share fontSize="small" />
              </IconButton>
            </div>
          </div>
        </Card>

        <Typography
          variant="h5"
          style={{
            marginTop: 10,
            padding: 10,
            textAlign: 'center',
            color: '#064326',
            fontWeight: 'bold',
          }}
        >
          Promoções do local
        </Typography>

        {data.appLocal?.promotions &&
          data.appLocal?.promotions.map(item => (
            <PromotionCard
              promotion={item}
              onClick={() => router.push(`/promocoes/${item.slug}`)}
              key={item.slug}
            />
          ))}

        <div className={classes.morePromotion}>
          <Button
            startIcon={<HomeOutlined />}
            color="primary"
            variant="outlined"
            onClick={() => router.push(`/`)}
          >
            Voltar para o início
          </Button>
        </div>
      </div>
    </div>
  )
}

export default LocationsProfile
