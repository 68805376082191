import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { DialogContent, DialogTitle, Typography } from '@material-ui/core'
import { ErrorOutline } from '@material-ui/icons'
import { useHistory, useParams } from 'react-router-dom'
import { useLazyQuery } from '@apollo/client'
import { AppLinkReferrer } from '../../generated/graphql'
import { APP_LINK_REFERRER } from '../../gql'
import Loading from '../../components/Loading'
import { clearRefHome, getRef, setRef } from '../../utils/reference'

const useStyles = makeStyles(theme => ({
  root: {},
  cardContentResponse: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
}))

const ReferenceLink: React.FC = () => {
  const classes = useStyles()
  const history = useHistory()
  const { code } = useParams<{ code: string }>()
  const [pageState, setPageState] = useState<
    'START' | 'ERROR' | 'SUCCESS' | 'LOADING'
  >('START')

  const setReferrer = async (referrer: AppLinkReferrer) => {
    await setRef(
      referrer.tableType,
      referrer.tableId,
      referrer.linkId,
      code,
      true,
    )

    window.setTimeout(() => {
      window.location.href = referrer.redirectUrl
    }, 500)

    setPageState('SUCCESS')
  }

  const [consultLink] = useLazyQuery<{
    appLinkReferrer: AppLinkReferrer
  }>(APP_LINK_REFERRER, {
    variables: {
      slug: code,
    },
    onCompleted: data1 => {
      if (data1) {
        setReferrer(data1.appLinkReferrer)
      } else {
        setPageState('ERROR')
        setTimeout(() => {
          history.push('/')
        }, 5000)
      }
    },
  })

  useEffect(() => {
    const ref = getRef()
    setPageState('LOADING')

    if (!code || code === ref?.slug) {
      setPageState('START')
      history.push('/')
    } else {
      consultLink()
    }
  }, [code, consultLink, history])

  switch (pageState) {
    case 'LOADING':
      return (
        <>
          <DialogTitle id="alert-dialog-slide-title">
            Aguarde... Estamos consultando seu link.
          </DialogTitle>
          <DialogContent>
            <Loading />
          </DialogContent>
        </>
      )
    case 'SUCCESS':
      return (
        <>
          <DialogTitle id="alert-dialog-slide-title">
            Aguarde... Você está sendo redirecionado.
          </DialogTitle>
        </>
      )
    case 'ERROR':
      return (
        <>
          <DialogTitle id="alert-dialog-slide-title">ERRO</DialogTitle>
          <DialogContent className={classes.cardContentResponse}>
            <Typography variant="h6">
              Não conseguimos encontrar o seu link.
            </Typography>

            <ErrorOutline style={{ color: '#e74c3c', fontSize: '4rem' }} />

            <p>
              Tente acessar novamente o link ou aguarde que você será
              redirecionado a nossa página inicial.
            </p>
          </DialogContent>
        </>
      )
    default:
      return <span>Aguarde...</span>
  }
}
export default ReferenceLink
