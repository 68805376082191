/* eslint-disable react/require-default-props */
import React, { useContext, useEffect } from 'react'

import { gql, useLazyQuery, useMutation } from '@apollo/client'

import { TransitionProps } from '@material-ui/core/transitions'
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
  Slide,
  Typography,
} from '@material-ui/core'
import { CheckCircleOutline, ErrorOutline, Loyalty } from '@material-ui/icons'
import {
  AppBuyPromotionResponse,
  AppPromotion,
  Client,
  PromotionTypeEnum,
} from '../../generated/graphql'
import Loading from '../Loading'
import { ClientContext } from '../../providers/ClientProvider'
import { MY_CLIENT } from '../../gql'

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: 5,
    marginBottom: 5,
    borderRadius: 0,
    borderBottomRightRadius: 10,
    borderBottomLeftRadius: 10,
    // marginLeft: 80,
    // marginRight: 80,
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      marginLeft: 5,
      marginRight: 5,
    },
  },
  cardContentVoucher: {
    display: 'flex',
    flexDirection: 'column',
    padding: 10,
    margin: 0,
  },
  contentTitle: {
    fontWeight: 'bold',
    margin: 0,
  },
  cardActionVoucher: {
    display: 'flex',
    justifyContent: 'center',
  },
  cardContentResponse: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
}))

const ADD_BUY_PROMOTION = gql`
  mutation AppBuyPromotion($promotion: ID!) {
    appBuyPromotion(promotion: $promotion) {
      message
      data {
        code
        expireIn {
          format(format: "DD/MM/yy HH:mm:ss")
        }
      }
    }
  }
`

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />
})

type Props = {
  promotion: AppPromotion
  onCloseDialog: (action: string) => void
}
const TakePromotion: React.FC<Props> = ({ promotion, onCloseDialog }) => {
  const classes = useStyles()

  const [open, setOpen] = React.useState(false)

  const { setClient } = useContext(ClientContext)
  const [loadClient, { data: clientData }] = useLazyQuery<{
    myClient: Client
  }>(MY_CLIENT)

  useEffect(() => {
    if (clientData) {
      setClient(clientData.myClient)
    }
  }, [clientData, setClient])

  const [rescueStatus, setRescueStatus] = React.useState<
    'FORM' | 'SUCCESS' | 'ERROR'
  >('FORM')

  const [rescueResponse, setRescueResponse] = React.useState<{
    message: string
    code: string
    expireIn?: string
  }>({ message: '', code: '' })
  const [buyPromotion, { loading }] = useMutation<{
    appBuyPromotion: AppBuyPromotionResponse
  }>(ADD_BUY_PROMOTION)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    if (rescueStatus === 'SUCCESS') {
      onCloseDialog('reload')
    }
    setOpen(false)
  }

  const generateVoucherCode = () => {
    buyPromotion({
      variables: {
        promotion: promotion._id,
      },
    }).then(
      res => {
        setRescueResponse({
          code: res.data?.appBuyPromotion?.data.code || '',
          message: res.data?.appBuyPromotion.message || '',
          expireIn: res.data?.appBuyPromotion?.data.expireIn.format || '',
        })
        setRescueStatus('SUCCESS')
        loadClient()
      },
      error => {
        setRescueResponse({
          code: '',
          message: error.message || '',
          expireIn: '',
        })
        setRescueStatus('ERROR')
      },
    )
  }

  const renderByRescueStatus = () => {
    switch (rescueStatus) {
      case 'SUCCESS':
        return (
          <>
            <DialogTitle id="alert-dialog-slide-title">
              CÓDIGO DE{' '}
              {promotion.type === PromotionTypeEnum.Voucher
                ? 'VOUCHER'
                : 'CUPOM'}{' '}
              GERADO
            </DialogTitle>
            <DialogContent className={classes.cardContentResponse}>
              <CheckCircleOutline
                style={{ color: '#27ae60', fontSize: '4rem' }}
              />

              <p>{rescueResponse.message}</p>
              <p>Seu código: {rescueResponse.code}</p>

              {rescueResponse.expireIn && (
                <p>Validade: {rescueResponse.expireIn}</p>
              )}
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleClose}
                color="secondary"
                variant="outlined"
              >
                Fechar
              </Button>
            </DialogActions>
          </>
        )
      case 'ERROR':
        return (
          <>
            <DialogTitle id="alert-dialog-slide-title">ERRO</DialogTitle>
            <DialogContent className={classes.cardContentResponse}>
              <Typography variant="h6">
                Não conseguimos gerar seu código
              </Typography>

              <ErrorOutline style={{ color: '#e74c3c', fontSize: '4rem' }} />

              <p>{rescueResponse.message}</p>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleClose}
                color="secondary"
                variant="outlined"
              >
                Fechar
              </Button>
            </DialogActions>
          </>
        )
      case 'FORM':
      default:
        return (
          <>
            <DialogTitle id="alert-dialog-slide-title">
              Resgatar promoção
            </DialogTitle>
            <DialogContent>
              {promotion.type === PromotionTypeEnum.Voucher && (
                <DialogContentText id="alert-dialog-slide-description">
                  será descontado{' '}
                  <b style={{ color: '#064326' }}>
                    {promotion.config.passCoin} PassCoins
                  </b>{' '}
                  do seu saldo.
                </DialogContentText>
              )}

              {promotion.type === PromotionTypeEnum.Coupon && (
                <DialogContentText id="alert-dialog-slide-description">
                  Para usar esta promoção você pagará{' '}
                  <b style={{ color: '#064326' }}>
                    R${promotion.config.brl?.toFixed(2)}
                  </b>{' '}
                  diretamente ao estabelecimento na hora do resgate.
                  <br />
                  <br />
                  {promotion.config.cashbackInPassCoin && (
                    <span>
                      Ao validar este cupom no local você receberá{' '}
                      <b style={{ color: '#064326' }}>
                        {' '}
                        {promotion.config.cashbackInPassCoin} PassCoins{' '}
                      </b>{' '}
                      diretamente em sua conta PASSFREE.
                    </span>
                  )}
                </DialogContentText>
              )}
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleClose}
                color="secondary"
                variant="outlined"
              >
                Cancelar
              </Button>
              <Button
                onClick={generateVoucherCode}
                color="primary"
                variant="outlined"
                style={{ color: '#064326', borderColor: '#064326' }}
              >
                Resgatar
              </Button>
            </DialogActions>
          </>
        )
    }
  }

  return (
    <>
      <Card className={classes.root}>
        <CardContent className={classes.cardContentVoucher}>
          <Typography variant="h6" className={classes.contentTitle}>
            Esta promoção pode ser sua!
          </Typography>

          <Typography variant="subtitle2" style={{ margin: 0 }}>
            Clique no botão a baixo para gerar seu código promocional.
          </Typography>
        </CardContent>

        <CardActions className={classes.cardActionVoucher}>
          <Button
            variant="contained"
            endIcon={<Loyalty />}
            onClick={handleClickOpen}
            style={{
              background: '#064326',
              borderColor: '#064326',
              color: '#fff',
            }}
          >
            {promotion.type === PromotionTypeEnum.Voucher
              ? 'GERAR VOUCHER'
              : 'GERAR CUPOM'}
          </Button>
        </CardActions>
      </Card>

      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        {loading && (
          <>
            <DialogTitle id="alert-dialog-slide-title">
              Resgatar promoção
            </DialogTitle>
            <DialogContent>
              <Loading />
            </DialogContent>
          </>
        )}

        {!loading && renderByRescueStatus()}
      </Dialog>
    </>
  )
}
export default TakePromotion
