import React from 'react'
import { Avatar } from '@material-ui/core'
import { SentimentDissatisfied } from '@material-ui/icons'

type Props = {
  message?: string
  children?: JSX.Element
}
const EmptyState: React.FC<Props> = ({ message, children }) => (
  <div className="flex flex-col w-full items-center justify-center text-sm ">
    <Avatar>
      <SentimentDissatisfied className="w-10 h-10" />
    </Avatar>

    <h3 className="mb-5">
      {message || ' Você ainda não cadastrou nenhum item.'}
    </h3>

    {children}
  </div>
)
export default EmptyState
